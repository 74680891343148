import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { FC, useCallback, useState } from "react";

interface UserInputProps {
  input: any;
  setUserInputValues?: any;
}

const UserInput: FC<UserInputProps> = props => {
  const { input, setUserInputValues } = props;
  const [userInputs, setUserInput] = useState({});

  const updateTextFieldInputCallback = useCallback(
    value => setUserInputValues((prev: any) => ({ ...prev, [input?.inputDataSource]: value })),
    [input]
  );

  const updateSwitchValueCallback = useCallback(
    value => setUserInputValues((prev: any) => ({ ...prev, [input?.inputDataSource]: value })),
    [input]
  );

  return (
    <Grid item sx={{ pl: "0px !important" }} spacing={2} xs={12} md={6} alignItems='center'>
      {input?.type == "Input" ? (
        <TextField
          size='small'
          fullWidth
          label={input?.label}
          onChange={e => {
            setUserInput(prev => ({ ...prev, [input?.inputDataSource]: e.target.value }));
            updateTextFieldInputCallback(e.target.value);
          }}
        ></TextField>
      ) : input?.type == "Switch" ? (
        <FormControlLabel
          control={
            <Switch
              // @ts-ignore
              checked={!!userInputs?.[input?.inputDataSource]}
              onChange={e => {
                // @ts-ignore
                setUserInput(prev => ({ ...prev, [input?.inputDataSource]: e.target.checked }));
                updateSwitchValueCallback(e.target.checked);
              }}
            />
          }
          label={input?.label}
        />
      ) : null}
    </Grid>
  );
};

export default UserInput;
