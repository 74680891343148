import Button, { ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import _ from "lodash";
import * as React from "react";
import { FC, useEffect } from "react";

type BXConfirmationDialog = {
  buttonProps?: ButtonProps;
  onCancel?: () => void;
  onConfirm?: () => void;
  title: string;
  content?: string;
  iconButton?: boolean;
  open?: boolean;
};

export const BXConfirmationDialog: FC<BXConfirmationDialog> = ({
  buttonProps,
  onConfirm,
  content,
  onCancel,
  title,
  open: _open,
  iconButton = false,
}) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!_.isNil(_open)) {
      setOpen(_open);
    }
  }, [_open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onCancel?.();
  };

  const handleConfirm = async () => {
    await onConfirm?.();
    setOpen(false);
  };

  return (
    <>
      {!_.isNil(_open) ? null : iconButton ? (
        <IconButton {...buttonProps} onClick={handleClickOpen} />
      ) : (
        <Button variant='outlined' {...buttonProps} onClick={handleClickOpen} />
      )}
      <Dialog open={open} onClose={handleClose} fullWidth aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title' style={{ fontSize: "18px" }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} autoFocus variant='contained'>
            Confirm
          </Button>
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
