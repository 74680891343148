import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { IconRefresh } from "@tabler/icons";

import _ from "lodash";
import { FC, useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { ElementBaseProps } from "src/types/UIElement";
import { queryClient, useBXContext, useValue } from "../../../BXEngine/BXContext";
import { replaceItemPlaceholders } from "../DataTable/ActionButton";
import { UserInput } from "../UserInput";

export const BXAPi: FC<ElementBaseProps> = ({ id, dataSource, info, selectedViewId, __data = {} }) => {
  const { selectedTableRows, currentApp } = useBXContext();

  const selectedRowFromOtherTables = selectedTableRows?.find(item => item?.viewId == dataSource?.table?.id || item?.id == selectedViewId);

  const [userInputsValues, setUserInputValues] = useState({});

  const [isRefreshing, setIsRefreshing] = useState(false);
  const { palette } = useTheme();

  const withInfiniteScroll = !info?.paginationMode || info?.paginationMode == "Infinite Scroll";

  const { data, isFetching, refetch } = useValue(
    id,
    dataSource?.limit,
    dataSource?.sourceType,
    dataSource?.sourceType == "USER INPUT" ? userInputsValues : selectedRowFromOtherTables,
    selectedRowFromOtherTables,
    userInputsValues,
    dataSource?.sourceType == "USER INPUT",
    withInfiniteScroll,
    {
      refetchInterval: info?.autoRefresh && (info?.timer || 60) * 1000,
      refetchIntervalInBackground: !!info?.autoRefresh,
    },
    undefined,
    __data,
    dataSource?.dataEntry
  );

  useEffect(() => {
    return () => {
      if (dataSource?.sourceType == "USER INPUT") {
        queryClient.setQueryData([id], () => ({
          pages: [],
        }));
      }
    };
  }, []);

  const filterSelectedChildren = (items: any, _id: any) => {
    items.forEach((item: any) => {
      if (item?.dataSource?.table?.id == _id) {
        items = items.filter((_item: any) => _item?.dataSource?.table?.id != _id);
        items = filterSelectedChildren(items, item?.viewId);
      }
    });
    return items;
  };

  let entries;
  // @ts-ignore
  if (!withInfiniteScroll) {
    entries = (_.isNil(dataSource?.dataEntry) ? data : _.get(data, dataSource?.dataEntry as any))?.filter?.(Boolean);
  } else {
    entries = (
      _.isNil(dataSource?.dataEntry)
        ? _.flatten(data?.pages)
        : _.flatten(data?.pages?.map((p: any) => _.get(p, dataSource?.dataEntry as any)))
    )?.filter?.(Boolean);
  }

  return (
    <Box marginY={1} paddingX={1}>
      <Card variant={"outlined"}>
        <Grid container paddingX={2} mt={2} mb={2}>
          <Grid container xs={12}>
            <Typography flex={1} fontSize={"16px"} lineHeight={2}>
              {replaceItemPlaceholders(
                info?.name,
                undefined,
                selectedRowFromOtherTables,
                undefined,
                undefined,
                undefined,
                __data,
                undefined,
                undefined,
                undefined,
                currentApp?.env
              )}
            </Typography>
            <Tooltip title='Refresh'>
              <Box mr={1} alignSelf='center'>
                <IconButton
                  onClick={() => {
                    setIsRefreshing(true);
                    queryClient.refetchQueries([id]).then(() => {
                      setIsRefreshing(false);
                    });
                  }}
                  disabled={isFetching}
                  style={{ backgroundColor: palette.grey[100] }}
                >
                  {isRefreshing ? <CircularProgress size='16px' /> : <IconRefresh color={palette.text.primary} size={16} />}
                </IconButton>
              </Box>
            </Tooltip>
          </Grid>
          {dataSource?.sourceType == "USER INPUT" && (
            <Card variant={"outlined"} style={{ width: "100%", marginTop: 8, padding: "24px 24px 24px 18px" }}>
              <Grid xs={dataSource?.userInputs?.length != 1 ? 12 : 6} item container spacing={2}>
                {dataSource?.userInputs?.map(input => (
                  <UserInput input={input} setUserInputValues={setUserInputValues} />
                ))}
                {dataSource?.userInputs?.length == 1 && (
                  <Grid item>
                    <Button
                      variant={"contained"}
                      onClick={() => {
                        refetch({ queryKey: [userInputsValues] });
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                )}
              </Grid>
              {dataSource?.userInputs?.length != 1 && (
                <Grid container item mt={3} alignItems='center' justifyContent='center'>
                  <Button
                    variant={"contained"}
                    onClick={() => {
                      refetch({ queryKey: [userInputsValues] });
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              )}
            </Card>
          )}

          <Grid container paddingY={2.5} spacing={"10px"}>
            <ReactJson src={entries as any} theme={"paraiso"} style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
