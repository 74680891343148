import { useTheme } from "@mui/material";
import { useState } from "react";
import { BXEngine } from "src/BXEngine";
import { getLastKeyFromObject } from "src/utils/generalUtils";
import { BXIcon } from "../Icon";
import BXModal from "../Modal";

type Props = {
  action: any;
  onSelectRow?: any;
  item: any;
  actionSourceType: any;
  selectedView: any;
  buttonProps?: any;
  icon?: any;
  views?: any;
  showHeader?: boolean;
  withoutLabel?: boolean;
  modalSize?: string;
  iconColor?: string;
  __data?: any;
  id?: string;
  prevVideoCardId?: string;
  parentIds?: any[];
  buttonComponent?: any;
  index?: any;
};

const ViewerModal = (props: Props) => {
  const {
    id,
    item,
    selectedView,
    action,
    onSelectRow,
    actionSourceType,
    buttonProps,
    icon,
    withoutLabel,
    views,
    modalSize = "lg",
    showHeader = true,
    __data = {},
    iconColor,
    parentIds = [],
    prevVideoCardId,
    buttonComponent,
    index,
  } = props;
  const { palette } = useTheme();
  const [isDirty, setIsDirty] = useState(false);

  return (
    <BXModal
      label={action?.label}
      buttonComponent={buttonComponent}
      icon={icon || <BXIcon icon={action?.icon} width={16} height={16} color={iconColor || palette.text.primary} />}
      buttonProps={{
        variant: "contained",
        startIcon: <BXIcon icon={action.icon} width={20} height={20} color={iconColor || palette.text.primary} />,
        onClick: () => onSelectRow?.(item, true),
        ...buttonProps,
      }}
      title={action.label}
      maxWidth={modalSize}
      showHeader={showHeader}
      withoutLabel={withoutLabel}
      isDirty={isDirty}
    >
      {(handleClose: Function) => {
        return (
          <>
            <BXEngine
              auth={{}}
              setIsDirty={setIsDirty}
              showModalHeader={showHeader}
              page={{ views, layout: selectedView } as any}
              layout={[{ ...selectedView, type: actionSourceType?.type }]}
              isVisible
              selectedViewId={item?.id}
              index={index}
              __data={{
                ...__data,
                [(getLastKeyFromObject(__data) || "") + "#"]: item,
              }}
              closeModal={(data: any) => {
                if (data) {
                  onSelectRow?.(data);
                }
                handleClose();
              }}
              prevVideoCardId={prevVideoCardId}
              parentIds={[...parentIds, id]}
            />
          </>
        );
      }}
    </BXModal>
  );
};

export default ViewerModal;
