import { Typography } from "@mui/material";
import _ from "lodash";
import { FC } from "react";
import { Controller, FieldValues, useFieldArray, useForm } from "react-hook-form";
import "react-image-crop/dist/ReactCrop.css";
import CreateDynamicFields from "src/views/pages/BuildX/AppBuilder/forms/CreateDynamicFields";

const BXKeyValue: FC<any> = props => {
  const { control, inputValidationName, getValues, inputLabel } = props;

  const { control: _control, watch } = useForm<FieldValues>({
    defaultValues: {
      config: { variables: _.map(getValues(inputValidationName) || {}, (value, key) => [{ value, key }]) || [] },
    },
  });
  const { fields, append, remove } = useFieldArray({
    control: _control,
    name: "config.variables",
  });

  return (
    <>
      <Typography mb={2} fontWeight={400}>
        {inputLabel}
      </Typography>
      <Controller
        control={control}
        name={inputValidationName}
        render={({ field: { onChange } }) => (
          <CreateDynamicFields
            fields={fields}
            remove={remove}
            append={append}
            control={_control}
            watch={watch}
            onChange={(values: any = []) => {
              const variables: any = {};
              values?.forEach((variable: any) => {
                if (variable?.key) {
                  variables[variable?.key] = variable?.value;
                }
              });
              onChange(variables);
            }}
          />
        )}
      />
    </>
  );
};

export { BXKeyValue };
