import { Box, Theme } from "@mui/material";
import { FC } from "react";
import { useDragLayer } from "react-dnd";
import { BOX_HEIGHT } from "./FormBuilderEditor";
import { getItemClosestProp } from "./utils";

const layerStyles: any = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 1000,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
};

const getItemStyles = (step: number, initialOffset: any, currentOffset: any, initialClientOffset?: any) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }

  const { x: initialX, y: initialY } = initialOffset;
  const { x: clientX, y: clientY } = initialClientOffset;
  const { x, y } = currentOffset;

  const startX = Math.round(x - clientX + initialX);
  const startY = Math.round(y - clientY + initialY);

  // Calculate the stepper value for x and y
  const translateX = Math.round(startX / step) * step;
  const translateY = Math.round(startY / step) * step;

  const transform = `translate(${translateX}px, ${translateY}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
};

export const CustomDragLayer: FC<{ layoutBreak: string; boxPosition: any; canDrop: boolean; step: number }> = ({
  canDrop,
  step,
  layoutBreak,
  boxPosition,
}) => {
  const { isDragging, item, currentOffset, initialOffset, initialClientOffset } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
    initialClientOffset: monitor.getInitialClientOffset(),
  }));

  if (!isDragging) {
    return null;
  }
  const {
    fixedWidth = false,
    fixedHeight = false,
    widthPx,
    heightPx,
    widthPercentage,
    heightPercentage,
    defaultHeight,
    defaultWidth,
  } = item?.config;
  const { width } = boxPosition;

  return (
    <Box sx={layerStyles}>
      <Box sx={getItemStyles(step, initialOffset, currentOffset, initialClientOffset)}>
        <Box
          sx={{
            width: !fixedWidth
              ? (Number(getItemClosestProp(widthPercentage, layoutBreak)?.replace("%", "")) / 100) * width || defaultWidth
              : getItemClosestProp(widthPx, layoutBreak) || defaultWidth,
            height: !fixedHeight
              ? (Number(getItemClosestProp(heightPercentage, layoutBreak)?.replace("%", "")) / 100) * BOX_HEIGHT || defaultHeight
              : getItemClosestProp(heightPx, layoutBreak) || defaultHeight,
            backgroundColor: (theme: Theme) => (canDrop ? theme.palette.success.main : theme.palette.error.main),
            opacity: 0.3,
          }}
        />
      </Box>
    </Box>
  );
};
