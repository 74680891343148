import { BXEngine } from "src/BXEngine";
import { DragElement } from "./DragElement";
import { MUI_COMPONENTS } from "./FormBuilderEditor";

export const BoxComponent = (props: any) => {
  const { item, index, onLayout = () => {} } = props;

  const Component = MUI_COMPONENTS[item.type];

  return (
    <DragElement key={`${index}`} {...props}>
      {item?.config?.customComponent ? (
        <BXEngine
          auth={{}}
          componentName={item?.props?.name}
          componentData={item?.props}
          layout={[{ id: item?.id, type: item?.type } as any]}
          onLayout={onLayout}
        />
      ) : (
        <Component disabled {...item?.props} />
      )}
    </DragElement>
  );
};
