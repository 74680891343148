import axios from "axios";
import _ from "lodash";
import { createContext, Dispatch, FC, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { osVersion } from "react-device-detect";
import { QueryClient, QueryClientProvider, useInfiniteQuery, useQuery } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useLocation, useNavigate } from "react-router-dom";
import { isHostAvailable } from "src/App";
import { replaceItemPlaceholders } from "src/components/BXUI/DataTable/ActionButton";
import { setSession } from "src/contexts/JWTContext";
import useAuth from "src/hooks/useAuth";
import { BXApp, BXAppCollection, BXEnv } from "src/types/BXAppType";
import { DataSourceType, UIElement } from "src/types/UIElement";
import axiosServices from "src/utils/axios";
import { getAuthorizationHeader } from "src/utils/generalUtils";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { v4 as uuidv4 } from "uuid";
import { UserProfile } from "../_mockApis/user-profile/types";
import config from "../config";

type BXContextProps = {
  appDescriptor: BXApp[];
  setAppDescriptor: Dispatch<any>;
  fqdnApp: BXApp | undefined;
  setFqdnApp: Dispatch<any>;
  selectedTableRows: any[];
  currentApp?: BXApp;
  setCurrentApp: Dispatch<any>;
  envs?: BXEnv[];
  setEnvs: Dispatch<any>;
  addApp: (app: BXApp) => void;
  setSelectedTableRows: (rows: any) => void;
  setValue?: Function;
  getValue?: Function;
  loadingApps?: boolean;
  getAuth: (appId: string, profileId?: string, noProfile?: boolean) => any;
  registerAppDevice?: (appId: string, deviceApi?: any, fqdnApp?: any) => any;
  loginToApp?: (
    appId: string,
    email?: string,
    password?: string,
    cb?: () => void,
    profile?: any,
    _authApi?: any,
    fqdn?: any,
    loginType?: string
  ) => any;
  logoutOfApp?: (appId: string) => void;
  loadApps?: (fromLogin?: boolean) => void;
  isAdministrationMode: boolean;
  isSwitchingMode: boolean;
  setIsSwitchingMode: (value: boolean) => void;
  setIsAdministrationMode: (value: boolean) => void;
  appProfiles: { [key: string]: any };
  setAppProfiles: Dispatch<any>;
  appTokens: { [key: string]: any };
  currentProfileId?: string;
  setCurrentProfileId: Dispatch<any>;
  setLoadingApps: Dispatch<any>;
  viewStacks: UIElement[];
  setViewStacks: Dispatch<React.SetStateAction<UIElement[]>>;
};
export const BXContext = createContext<BXContextProps>({
  appDescriptor: [],
  setCurrentApp: _.noop,
  setEnvs: _.noop,
  fqdnApp: undefined,
  setFqdnApp: _.noop,
  setAppDescriptor: _.noop,
  addApp: _.noop,
  currentApp: undefined,
  envs: undefined,
  setValue: _.noop,
  getValue: _.noop,
  getAuth: _.noop,
  loadingApps: false,
  selectedTableRows: [],
  setSelectedTableRows: _.noop,
  isAdministrationMode: false,
  isSwitchingMode: false,
  setIsSwitchingMode: _.noop,
  setIsAdministrationMode: _.noop,
  appProfiles: {},
  setAppProfiles: _.noop,
  appTokens: {},
  currentProfileId: undefined,
  setCurrentProfileId: _.noop,
  setLoadingApps: _.noop,
  viewStacks: [],
  setViewStacks: _.noop,
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

/**
 *
 * @param children
 * @param appDescriptor
 * @constructor
 */
export const BXContextProvider: FC<{ appDescriptor?: BXApp[]; children?: ReactNode; setLoginFlag: any }> = ({
  children,
  appDescriptor: description,
  setLoginFlag,
}) => {
  const [appTokens, setAppTokens] = useState<{ [key: string]: any }>({});
  const [currentProfileId, setCurrentProfileId] = useState();
  const [appDescriptor, setAppDescriptor] = useState<BXApp[]>(description || []);
  const [fqdnApp, setFqdnApp] = useState<BXApp>();
  const [appProfiles, setAppProfiles] = useState<{ [key: string]: any }>({});
  const [selectedTableRows, setSelectedTableRows] = useState<any[]>([]);
  const [loadingApps, setLoadingApps] = useState<boolean>(false);
  const [currentApp, setCurrentApp] = useState<BXApp>();
  const [envs, setEnvs] = useState<BXEnv[]>();
  const [isAdministrationMode, setIsAdministrationMode] = useState(false);
  const [isSwitchingMode, setIsSwitchingMode] = useState(false);
  const [viewStacks, setViewStacks] = useState<UIElement[]>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { isSuperAdmin } = useAuth();

  useEffect(() => {
    if (isAdministrationMode) {
      if (
        location.pathname === "/buildx/app" ||
        location.pathname === "/buildx/users" ||
        location.pathname === "/buildx/oas" ||
        location.pathname === "/buildx/env" ||
        location.pathname == "/buildx/component" ||
        location.pathname.startsWith("/buildx/form-builder")
      ) {
        navigate({ pathname: location.pathname });
      } else {
        navigate({ pathname: "buildx/app" });
      }
      if (currentApp?.name) {
        document.title = currentApp?.name;
      }
    } else {
      document.title = fqdnApp?.name || process.env.REACT_APP_NAME!;
      if (currentApp?.name && !window.location.pathname.includes(currentApp?.name)) {
        const firstCollectionToHavePages = currentApp.templateConfig?.collections?.find(
          (collection: BXAppCollection) => collection.pages.length > 0
        );
        const collectionSlug = firstCollectionToHavePages?.slug;
        const firstPageSlug = firstCollectionToHavePages?.pages?.[0]?.slug;
        if (firstPageSlug) navigate({ pathname: `${currentApp.slug}/${collectionSlug}/${firstPageSlug}`.replaceAll(/\/+/g, "/") });
        else navigate({ pathname: `${currentApp.slug}`.replaceAll(/\/+/g, "/") });
      }
    }
  }, [isAdministrationMode]);

  const setAppSession = (appId: string, accessToken?: string | null, user?: UserProfile | null, profile?: any) => {
    if (profile?.id) {
      localStorage.setItem(appId + "-profileId", profile?.id);
      setCurrentProfileId(profile?.id);
    }

    if (accessToken) {
      setAppTokens((previous = {} as any) => {
        const prev = { ...previous };
        prev[appId + `-${profile?.id}-accessToken`] = accessToken;
        prev[appId + `-${profile?.id}-user`] = user;
        return prev;
      });

      localStorage.setItem(appId + `-${profile?.id}-accessToken`, accessToken);
      localStorage.setItem(appId + `-${profile?.id}-user`, JSON.stringify(user));
    } else {
      setAppTokens((previous = {} as any) => {
        const prev = { ...previous };
        delete prev[appId + `-${profile?.id}-accessToken`];
        delete prev[appId + `-${profile?.id}-user`];
        return prev;
      });

      localStorage.removeItem(appId + `-${profile?.id}-accessToken`);
      localStorage.removeItem(appId + `-${profile?.id}-user`);
    }
  };

  const loadApps = async (fromLogin?: boolean) => {
    setLoadingApps(true);
    axiosServices
      .get("/application")
      .then(async res => {
        try {
          const apps = res.data?.map?.((col: any) => col?.app);

          setAppDescriptor(apps);
          const _currentApp = apps?.find((app?: BXApp) => app?.slug === "/" + location.pathname.split("/")?.[1]) || apps?.[0];
          setCurrentApp(_currentApp);

          if (apps?.length && fromLogin) {
            const firstApp = apps?.[0];
            const firstCollectionToHavePages = firstApp.templateConfig?.collections?.find(
              (collection: BXAppCollection) => collection.pages.length > 0
            );
            const collectionSlug = firstCollectionToHavePages?.slug;
            const firstPageSlug = firstCollectionToHavePages?.pages?.[0]?.slug;
            if (firstPageSlug) navigate({ pathname: `${firstApp.slug}/${collectionSlug}/${firstPageSlug}`.replaceAll(/\/+/g, "/") });
            else navigate({ pathname: `${firstApp.slug}`.replaceAll(/\/+/g, "/") });
          }

          if (!_currentApp) {
            setLoadingApps(false);
          }
        } catch (e) {
          setLoadingApps(false);
        }
      })
      .catch(() => {
        setLoadingApps(false);
      });
  };

  const addApp = (app: BXApp) => {
    setAppDescriptor(old => [app, ...old]);
  };

  const [data, setData] = useState<any>({});

  useEffect(() => {
    // if (_.isEmpty(data)) {
    const extractedData = _.flatMapDeep(_.flatMapDeep(_.flatMapDeep(appDescriptor, "templateConfig.collections"), "pages"), "views");
    const initialStateData = extractedData.reduce((prev, current) => {
      current = current as UIElement;
      let code = current?.dataSource?.simple;
      try {
        code = JSON.parse(current?.dataSource?.simple);
      } catch (e) {}
      return {
        ...prev,
        [current?.id]: current?.dataSource?.sourceType !== "SIMPLE" ? current?.dataSource?.url : code,
      };
    }, {});
    setData(initialStateData);
    // }
  }, [appDescriptor]);

  useEffect(() => {
    if (currentApp?.id) {
      if (
        (location.pathname === "/buildx/app" ||
          location.pathname === "/buildx/users" ||
          location.pathname === "/buildx/oas" ||
          location.pathname === "/buildx/env" ||
          location.pathname == "/buildx/component" ||
          location.pathname.startsWith("/buildx/form-builder")) &&
        currentApp?.name
      ) {
        document.title = currentApp?.name;
      }
      if (!currentApp?.appConfig?.auth?.authApi) return setLoadingApps(false);
      setLoadingApps(true);

      const _currentProfileId = localStorage.getItem(currentApp?.id + "-profileId");
      if (
        localStorage.getItem("admin-login") ||
        isHostAvailable ||
        (currentApp?.appConfig?.withProfiles && currentApp?.appConfig?.isSingleSignOn)
          ? true
          : !currentApp?.appConfig?.isSingleSignOn
      ) {
        axiosServices
          .get(`application/${currentApp.id}/profile`)
          .then(({ data }) => {
            if (data?.length) {
              if (!_currentProfileId) {
                localStorage.setItem(currentApp?.id + "-profileId", data?.[0]?.id);
              }
              setCurrentProfileId(_currentProfileId || data?.[0]?.id);
            }

            let count = 0;
            setAppProfiles(prev => {
              const newData = { ...prev };
              newData[currentApp.id] = data;
              if (
                !localStorage.getItem("admin-login") &&
                !isHostAvailable &&
                currentApp?.appConfig?.isSingleSignOn &&
                currentApp?.appConfig?.withProfiles &&
                currentApp?.appConfig?.enableGoogleSignIn
              ) {
                newData[currentApp.id] = [{ signInWithGoogle: true }, ...data];
                const profile = {} as any;
                const token = localStorage.getItem(currentApp?.id + `-${profile?.id}-accessToken`);
                const user = localStorage.getItem(currentApp?.id + `-${profile?.id}-user`);
                setAppTokens((previous = {} as any) => {
                  const prev = { ...previous };
                  prev[currentApp?.id + `-${profile?.id}-accessToken`] = token;
                  prev[currentApp?.id + `-${profile?.id}-user`] = JSON.parse(user || "{}");
                  return prev;
                });
              }
              data?.forEach(async (profile: any, index: number) => {
                try {
                  const token = localStorage.getItem(currentApp?.id + `-${profile?.id}-accessToken`);
                  const user = localStorage.getItem(currentApp?.id + `-${profile?.id}-user`);
                  count = count + 1;
                  if (!token || !user) {
                    await registerAppDevice?.(currentApp?.id);
                    await loginToApp?.(
                      currentApp?.id,
                      profile?.creds?.username,
                      profile?.creds?.password,
                      () => {
                        setIsSwitchingMode(false);
                      },
                      profile
                    );
                  } else {
                    setAppTokens((previous = {} as any) => {
                      const prev = { ...previous };
                      prev[currentApp?.id + `-${profile?.id}-accessToken`] = token;
                      prev[currentApp?.id + `-${profile?.id}-user`] = JSON.parse(user || "{}");
                      return prev;
                    });
                  }
                  if (data?.length - 1 == index) {
                    setLoadingApps(false);
                  }
                } catch (e) {
                  setLoadingApps(false);
                }
              });

              return newData;
            });
            if (!count) {
              setLoadingApps(false);
            }
          })
          .catch(() => {
            setLoadingApps(false);
          });
      } else {
        const profile = {} as any;
        const token = localStorage.getItem(currentApp?.id + `-${profile?.id}-accessToken`);
        const user = localStorage.getItem(currentApp?.id + `-${profile?.id}-user`);
        setAppTokens((previous = {} as any) => {
          const prev = { ...previous };
          prev[currentApp?.id + `-${profile?.id}-accessToken`] = token;
          prev[currentApp?.id + `-${profile?.id}-user`] = JSON.parse(user || "{}");
          return prev;
        });
        setLoadingApps(false);
      }
    }
  }, [currentApp?.id, currentApp?.appConfig?.auth?.authApi]);

  useEffect(() => {
    const { token } = getAuth(currentApp?.id!) || {};
    if (currentProfileId && currentApp?.id && token && !currentApp?.appConfig?.isSingleSignOn) {
      axios
        .get(`${replaceBaseUrl("/user/me", currentApp)}`, {
          headers: { ...getAuthorizationHeader(currentApp?.appConfig?.auth, token) },
        })
        .then(({ data }) => {
          setAppTokens((previous = {} as any) => {
            const prev = { ...previous };
            prev[currentApp?.id + `-${currentProfileId}-user`] = data;
            return prev;
          });

          localStorage.setItem(currentApp?.id + `-${currentProfileId}-user`, JSON.stringify(data));
        });
    }
  }, [currentProfileId, currentApp?.id]);

  const setValue = (newData: any, id: string) => setData((oldData: any) => ({ ...oldData, [`${id}`]: newData }));
  const getValue = useCallback(
    (id: string) => {
      if (_.isNil(id)) return null;
      return data?.[id];
    },
    [data]
  );

  const getAuth = (appId: string, _profileId?: string, noProfile?: boolean) => {
    const profileId = _profileId || (!noProfile && currentProfileId);
    const profile = profileId ? appProfiles?.[appId]?.find((profile: any) => profile?.id == profileId) : appProfiles?.[appId]?.[0];

    const token = appTokens[appId + `-${profileId || profile?.id}-accessToken`];
    const user = appTokens[appId + `-${profileId || profile?.id}-user`];

    if (
      (currentApp?.appConfig?.isSingleSignOn &&
        !localStorage.getItem("admin-login") &&
        !isHostAvailable &&
        !(currentApp?.appConfig?.withProfiles && currentApp?.appConfig?.isSingleSignOn)) ||
      (!_profileId &&
        currentApp?.appConfig?.withProfiles &&
        currentApp?.appConfig?.isSingleSignOn &&
        currentApp?.appConfig?.enableGoogleSignIn)
    ) {
      return { token, user } as any;
    }
    return profile && ({ ...profile?.[appId]?.[0], token, user } as any);
  };

  const registerAppDevice = async (appId: string, _deviceApi?: any, fqdnApp?: any) => {
    const deviceApi = getAppDataSource(appId)?.appConfig?.auth?.deviceApi || _deviceApi;
    if (!deviceApi) return;

    const deviceUDID = uuidv4();

    const deviceInfo = {
      appVersion: config.appVersion,
      deviceType: config.deviceType,
      deviceUDID,
      osVersion,
      pushToken: "",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      userId: "",
    };

    const isTokenExist = !!localStorage.getItem(`${appId}-accessToken-device`);
    if (isTokenExist) return;
    const res = await axios.post(replaceBaseUrl(deviceApi, currentApp || fqdnApp), deviceInfo);

    if (res?.data?.accessToken) {
      localStorage.setItem(`${appId}-accessToken-device`, res.data.accessToken);
    }
    if (res?.data?.creationTime) {
      localStorage.setItem("token_created_at", res.data.creationTime);
    }
    localStorage.setItem("device_uuid", deviceUDID);
  };

  const getAppDataSource = (appId: string) => {
    return appDescriptor.find(app => app.id === appId);
  };

  const logoutOfApp = (appId: string) => {
    setAppSession(appId);
  };

  const loginToApp = async (
    appId: string,
    email?: string,
    password?: string,
    cb: any = () => {},
    _profile?: any,
    _authApi?: any,
    fqdnApp?: any,
    loginType?: string
  ) => {
    const authApi = getAppDataSource(appId)?.appConfig?.auth?.authApi || _authApi;

    if (!authApi) return;
    let response = {} as any;
    if (!loginType) {
      response = await axios.post(
        replaceBaseUrl(authApi, currentApp || fqdnApp),
        { email, password },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem(`${appId}-accessToken-device`),
          },
        }
      );
    } else if (loginType == "google-login") {
      const { googleInfo } = fqdnApp?.appConfig || {};
      const { clientId, authEndpoint } = googleInfo || {};
      const searchParams = new URLSearchParams(location.search);
      const validateFQDN = fqdnApp?.fqdn?.replace("https://", "")?.replace("http://", "");
      const redirectUri = `http${validateFQDN.startsWith("localhost") ? "" : "s"}://` + validateFQDN + "/auth/google";
      try {
        response = await axiosServices.post(
          replaceBaseUrl(authEndpoint, fqdnApp as any),
          {},
          {
            params: {
              client_id: clientId,
              code: searchParams.get("code"),
              redirectUri: redirectUri,
            },
          }
        );
      } catch (e) {
        enqueueSnackbarRef?.("The user is not authorized", {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      } finally {
        navigate("/login");
      }
    }

    const { accessToken, principal } = response?.data || {};
    let _data;

    if (fqdnApp) {
      try {
        const { accessToken, principal } = response.data;
        const { data } = await axiosServices.post(`app/${appId}/token-exchange`, {
          token: accessToken,
          isLogin: true,
          errorMessage: "The user is not authorized",
          organizationId: fqdnApp?.org?.id,
          applicationId: fqdnApp?.id,
        });
        setLoginFlag(data?.principal);
        setSession(data?.accessToken, data?.principal);
        loadApps?.(true);
      } catch (e: any) {
        enqueueSnackbarRef?.(e.message || "Wrong Services", {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      }
    }

    if (
      !_profile &&
      !localStorage.getItem("admin-login") &&
      !isHostAvailable &&
      !(fqdnApp?.appConfig?.withProfiles && fqdnApp?.appConfig?.isSingleSignOn)
        ? !fqdnApp
        : true
    ) {
      if (email && password) {
        const { data } = await axiosServices.post(`application/${appId}/profile`, {
          profileName: principal?.name,
          creds: {
            username: email,
            password: password,
          },
        });
        _data = data;
      }
    }

    const data = _profile || _data;

    setAppSession(appId, accessToken, principal, data);

    setAppProfiles(previous => {
      const prev = { ...previous };
      const profiles = prev?.[appId] || [];
      const results = profiles?.filter((profile: any) => profile?.id !== data?.id);
      results.push(data);
      prev[appId] = results;
      return prev;
    });
    cb?.(response);
    return response;
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <BXContext.Provider
        value={{
          appDescriptor: appDescriptor,
          setAppDescriptor: setAppDescriptor,
          fqdnApp,
          setFqdnApp,
          getAuth,
          currentApp,
          envs,
          setEnvs,
          getValue,
          setValue,
          setCurrentApp,
          addApp,
          loginToApp,
          logoutOfApp,
          registerAppDevice,
          loadingApps,
          loadApps,
          selectedTableRows,
          setSelectedTableRows,
          isAdministrationMode,
          setIsAdministrationMode,
          isSwitchingMode,
          setIsSwitchingMode,
          appProfiles,
          setAppProfiles,
          appTokens,
          currentProfileId,
          setCurrentProfileId,
          setLoadingApps,
          viewStacks,
          setViewStacks,
        }}
      >
        {children}
      </BXContext.Provider>
    </QueryClientProvider>
  );
};

export const replaceBaseUrl = (url: any, app?: BXApp) => {
  if (url?.startsWith("{{")) {
    const env = app?.env;

    const placeholders = url.match(/\{{(.*?)\}}/g);
    let newUrl = url;
    placeholders?.forEach(function (placeholder: string) {
      const variable = placeholder.replace("{{", "").replace("}}", "");
      const data = env?.config?.variables[variable];
      newUrl = newUrl.replace(placeholder, data);
    });

    return newUrl;
  } else if (url?.startsWith("/")) {
    return (app?.baseUrl || "") + url;
  } else {
    return url;
  }
};

/**
 * consumer of BX Context
 */
export const useBXContext = () => useContext(BXContext);

export const useValue = (
  id: any,
  limit: number | undefined = undefined,
  sourceType?: DataSourceType,
  replacementObject = {} as any,
  selectedRow?: any,
  userInputsValues?: { [inputName: string]: any },
  isUserInput?: boolean,
  withInfiniteQuery = true,
  options = {},
  cursor = undefined,
  __data = {},
  dataEntry = "",
  soundPlayer?: any,
  notificationMessage?: any,
  keys: any = [],
  cursorIndex?: any
) => {
  const { getValue, currentApp, getAuth, envs } = useBXContext();

  let url: any = getValue?.(id);

  const queryKeys = [id, ...keys];

  if (sourceType === "TABLE") {
    queryKeys.push(replacementObject?.id);
  }

  if (cursor) {
    queryKeys.push(cursor);
  }

  const customQuery = withInfiniteQuery ? useInfiniteQuery : (useQuery as any);
  return customQuery(
    queryKeys,
    async ({ pageParam }: any) => {
      if (!_.isString(url) || !url) return url;
      url = replaceBaseUrl(url, currentApp);

      if (sourceType === "TABLE" && !selectedRow) return url;

      url = replaceItemPlaceholders(
        url,
        replacementObject,
        selectedRow,
        userInputsValues,
        isUserInput,
        null,
        __data,
        undefined,
        undefined,
        undefined,
        currentApp?.env,
        { index: cursorIndex }
      );

      const { token } = getAuth(currentApp?.id!) || {};

      const { data } = await axiosServices.get(url, {
        headers: {
          ...getAuthorizationHeader(currentApp?.appConfig?.auth, token || localStorage.getItem("accessToken")),
        },
        params: { limit, cursor: cursor || pageParam },
      });

      if (!cursor && !pageParam) {
        const firstPageData =
          _.get((queryClient.getQueryData([id]) as any)?.pages?.[0], dataEntry) || _.get(queryClient.getQueryData([id]), dataEntry);

        const result = firstPageData?.find((item: any, index: number) => _.get(data, dataEntry)?.[index]?.id != item?.id);
        if (
          result ||
          (!firstPageData?.length &&
            _.get(data, dataEntry)?.length &&
            queryClient.getQueryState([id])?.status != "loading" &&
            queryClient.getQueryState([id])?.status != null)
        ) {
          soundPlayer?.current?.play();
          if (!document.hasFocus()) {
            document.title = "🔴" + (notificationMessage || "The view has been updated");
          }
          enqueueSnackbarRef?.(notificationMessage || "The view has been updated", {
            variant: "success",
            anchorOrigin: {
              horizontal: "right",
              vertical: "bottom",
            },
          });
        }
      }
      return data;
    },
    {
      cacheTime: 10000,
      keepPreviousData: true,
      getNextPageParam: (lastPage: any) => lastPage?.hasMore && lastPage?.cursor,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !isUserInput || !!cursor,
      ...options,
    }
  );
};
