import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";

import { FC } from "react";
import { Control, Controller } from "react-hook-form";

type BXSelectProps = {
  options: { label: string; value: any }[];
  label: string;
  control: Control;
  name: string;
  defaultValue?: any;
  id?: string;
} & SelectProps;

export const BXSelect: FC<BXSelectProps> = ({ name, control, label, id, options, defaultValue, ...rest }) => {
  const generateSelectOptions = () => {
    return options.map(option => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          <InputLabel id={id}>{label}</InputLabel>
          <Select fullWidth onChange={onChange} value={value || defaultValue || ""} label={label} size={"medium"} {...rest}>
            {generateSelectOptions()}
          </Select>
        </FormControl>
      )}
    />
  );
};
