import MonacoEditor from "@monaco-editor/react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { IconPlaylistAdd, IconTrashX } from "@tabler/icons";
import _ from "lodash";
import { FC, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { formatJSON } from "src/components/BXUI/DataTable/ActionButton";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { DataSourceType, ElementDataSource, UIElement } from "src/types/UIElement";
import { v4 as uuid } from "uuid";
import OASSelector from "../../OASSelector";
import DraggableRow from "../forms/DraggableRow";

type DataSourceProps = {
  value?: any;
  control: any;
  watch: any;
  errors: any;
  tables: UIElement[];
  setValue: any;
  viewType?: string;
  setIsTemplateError: any;
  views?: UIElement[] | undefined;
  view?: UIElement;
  onBuilderClick?: any;
};

export const inputTypes = ["Input", "Switch"];

export const DataSource: FC<DataSourceProps> = ({
  value,
  tables,
  control,
  errors,
  setValue,
  watch,
  viewType,
  setIsTemplateError,
  views,
  view,
  onBuilderClick,
}) => {
  const viewsWithoutCurrentView = views?.filter(item => item?.id != view?.id);

  const [dataSource, setDataSource] = useState<ElementDataSource | undefined>({
    ...value,
    sourceType: value?.sourceType || "API",
    simple: value?.simple || "{\n}",
  });

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "dataSource.userInputs",
  });

  const dataSourceTypes: DataSourceType[] = ["API", "TABLE", "USER INPUT", "SIMPLE"];

  if (viewType == "form" || viewType == "form-builder") {
    dataSourceTypes.splice(2, 3);
    dataSourceTypes.push("NONE");
  }
  if (viewType == "api") {
    dataSourceTypes.splice(1, 1);
    dataSourceTypes.splice(2, 1);
  }

  const moveElement = (dragIndex: any, hoverIndex: number) => {
    move(dragIndex, hoverIndex);
  };

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12} marginBottom={2}>
            {dataSourceTypes.map((type: DataSourceType) => (
              <Controller
                key={type}
                control={control}
                name={`dataSource.sourceType`}
                render={({ field: { onChange, value } }) => (
                  <ToggleButtonGroup color='primary' value={dataSource?.sourceType} exclusive>
                    <ToggleButton
                      style={{ marginRight: 12 }}
                      value={value}
                      onClick={() => {
                        setDataSource(old => ({
                          ...old,
                          sourceType: type,
                        }));
                        if (type != "TABLE") {
                          setValue("dataSource.table", undefined);
                        }
                        onChange(type);
                      }}
                      selected={value === type}
                      key={type}
                    >
                      {type}
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              />
            ))}
          </Grid>
          {dataSource?.sourceType === "SIMPLE" && (
            <Grid item xs={12}>
              <Controller
                control={control}
                name={`dataSource.simple`}
                render={({ field: { onChange, value } }) => (
                  <MonacoEditor
                    width='100%'
                    height='300px'
                    language='json'
                    theme='vs-dark'
                    value={!_.isString(value) ? JSON.stringify(value) : value}
                    options={{ colorDecorators: true }}
                    onChange={newValue => onChange(newValue)}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={2} mt={2}>
              {dataSource?.sourceType === "TABLE" && (
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`dataSource.table`}
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth>
                        <BXInput
                          name={"dataSource.table"}
                          control={control}
                          select
                          label='Table'
                          value={value?.id}
                          onChange={event => {
                            onChange(tables.find((table: any) => table?.id == event?.target.value));
                          }}
                        >
                          {!tables.length ? (
                            <MenuItem value=''>
                              <em>None</em>
                            </MenuItem>
                          ) : (
                            tables?.map(item => (
                              <MenuItem key={item?.id} value={item?.id}>
                                {item?.info?.name || item?.id}
                              </MenuItem>
                            ))
                          )}
                        </BXInput>
                      </FormControl>
                    )}
                  />
                </Grid>
              )}
              {dataSource?.sourceType != "SIMPLE" && dataSource?.sourceType !== "NONE" && dataSource?.sourceType === "API" && (
                <Grid item xs={6}>
                  <BXInput
                    name={"dataSource.apiUrl"}
                    control={control}
                    error={errors?.dataSource?.apiUrl}
                    fullWidth
                    id={"data-source-source"}
                    label={"Endpoint"}
                    InputProps={
                      process.env.REACT_APP_WITH_OAS == "true"
                        ? {
                            endAdornment: (
                              <InputAdornment position='end'>
                                <OASSelector
                                  swaggerProps={{
                                    type: viewType == "form" ? "form" : viewType == "form-builder" ? "form-builder" : "columns",
                                    template: watch("dataSource.template"),
                                    formBuilder: watch("dataSource.formBuilder"),
                                    onSuccess: (values: any) => {
                                      if (viewType == "form") {
                                        setValue("dataSource.template", values.template);
                                      }
                                      setValue("dataSource.url", values.url);
                                      setValue("dataSource.apiUrl", values.url);
                                      setValue("dataSource.dataEntry", values.dataEntry);
                                      setValue("config.columns", values.columns);
                                    },
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }
                        : undefined
                    }
                  />
                </Grid>
              )}
              {dataSource?.sourceType != "SIMPLE" &&
                dataSource?.sourceType !== "NONE" &&
                dataSource?.sourceType == "TABLE" &&
                !(viewType == "form" && dataSource?.sourceType == "TABLE") && (
                  <Grid item xs={6}>
                    <BXInput
                      name={"dataSource.tableUrl"}
                      control={control}
                      error={errors?.dataSource?.tableUrl}
                      fullWidth
                      id={"data-source-source"}
                      label={"Endpoint"}
                      InputProps={
                        process.env.REACT_APP_WITH_OAS == "true"
                          ? {
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <OASSelector
                                    swaggerProps={{
                                      type: viewType == "form" ? "form" : viewType == "form-builder" ? "form-builder" : "columns",
                                      template: watch("dataSource.template"),
                                      formBuilder: watch("dataSource.formBuilder"),
                                      onSuccess: (values: any) => {
                                        if (viewType == "form") {
                                          setValue("dataSource.template", values.template);
                                        }
                                        setValue("dataSource.url", values.url);
                                        setValue("dataSource.tableUrl", values.url);
                                        setValue("dataSource.dataEntry", values.dataEntry);
                                        setValue("config.columns", values.columns);
                                      },
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }
                          : undefined
                      }
                    />
                  </Grid>
                )}
              {dataSource?.sourceType != "SIMPLE" && dataSource?.sourceType !== "NONE" && dataSource?.sourceType == "USER INPUT" && (
                <Grid item xs={6}>
                  <BXInput
                    name={"dataSource.userInputUrl"}
                    control={control}
                    error={errors?.dataSource?.userInputUrl}
                    fullWidth
                    id={"data-source-source"}
                    label={"Endpoint"}
                    InputProps={
                      process.env.REACT_APP_WITH_OAS == "true"
                        ? {
                            endAdornment: (
                              <InputAdornment position='end'>
                                <OASSelector
                                  swaggerProps={{
                                    type: viewType == "form" ? "form" : viewType == "form-builder" ? "form-builder" : "columns",
                                    template: watch("dataSource.template"),
                                    formBuilder: watch("dataSource.formBuilder"),
                                    onSuccess: (values: any) => {
                                      if (viewType == "form") {
                                        setValue("dataSource.template", values.template);
                                      }
                                      setValue("dataSource.url", values.url);
                                      setValue("dataSource.userInputUrl", values.url);
                                      setValue("dataSource.dataEntry", values.dataEntry);
                                      setValue("config.columns", values.columns);
                                    },
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }
                        : undefined
                    }
                  />
                </Grid>
              )}
              {dataSource?.sourceType !== "NONE" && !(viewType == "form" && dataSource?.sourceType == "TABLE") && viewType != "api" && (
                <Grid item xs={6}>
                  <BXInput name={"dataSource.dataEntry"} control={control} fullWidth label={"Data entry"} />
                </Grid>
              )}
              {(viewType == "card-list" || viewType == "image-grid") && (
                <Grid item xs={6}>
                  <BXInput name={"dataSource.gridKey"} control={control} fullWidth label={"Key"} />
                </Grid>
              )}
              {viewType == "form" && (
                <Grid item xs={6}>
                  <BXInput
                    name={"dataSource.columnCount"}
                    control={control}
                    error={errors?.dataSource?.columnCount}
                    fullWidth
                    label={"Column Count"}
                  />
                </Grid>
              )}
              {dataSource?.sourceType == "USER INPUT" && (
                <Grid xs={12}>
                  <CardHeader title={"Inputs"} style={{ paddingBottom: 0 }} />
                  <CardContent>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Label</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Data Source</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {_.isEmpty(fields) && (
                            <TableRow>
                              <TableCell colSpan={12}>
                                <Typography textAlign={"center"}>No Inputs</Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          {fields?.map((col: any, index: number) => (
                            <DraggableRow
                              component={TableRow}
                              key={col?.id}
                              id={col.id}
                              index={index}
                              name='table-columns'
                              moveElement={moveElement}
                            >
                              <TableCell>{index + 1}.</TableCell>
                              <TableCell width={300}>
                                <BXInput
                                  name={`dataSource.userInputs[${index}].label`}
                                  control={control}
                                  label={"Label"}
                                  error={errors?.dataSource?.userInputs?.[index]?.label}
                                />
                              </TableCell>
                              <TableCell width={300}>
                                <FormControl fullWidth>
                                  <BXInput
                                    name={`dataSource.userInputs[${index}].type`}
                                    error={errors?.dataSource?.userInputs?.[index]?.type}
                                    control={control}
                                    select
                                    label={"Type"}
                                    id='demo-simple-select'
                                  >
                                    {inputTypes?.map(item => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </BXInput>
                                </FormControl>
                              </TableCell>
                              <TableCell width={300}>
                                <BXInput
                                  name={`dataSource.userInputs[${index}].inputDataSource`}
                                  control={control}
                                  error={errors?.dataSource?.userInputs?.[index]?.inputDataSource}
                                  label={"Source"}
                                />
                              </TableCell>

                              <TableCell>
                                <IconButton onClick={() => remove(index)}>
                                  <IconTrashX />
                                </IconButton>
                              </TableCell>
                            </DraggableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                  <Grid container justifyContent={"center"} alignItems='center'>
                    <Button
                      variant='outlined'
                      startIcon={<IconPlaylistAdd />}
                      onClick={() => {
                        append({ id: uuid(), type: "Input" });
                      }}
                    >
                      Add input
                    </Button>
                  </Grid>
                </Grid>
              )}
              {viewType == "form-builder" && (
                <Grid item xs={12} mt={1}>
                  <Typography mb={1}>Build your form :</Typography>
                  <OASSelector
                    swaggerProps={{
                      type: "form-builder",
                      onlyModels: true,
                      formBuilder: watch("dataSource.formBuilder"),
                      fillFormBuilder: true,
                      onSuccess: (values: any) => {
                        setValue("dataSource.formBuilder", values.formBuilder);
                      },
                    }}
                  />

                  <Button onClick={onBuilderClick} variant='contained' sx={{ marginLeft: 3 }}>
                    Build View
                  </Button>
                </Grid>
              )}
              {viewType == "form" && (
                <Grid item xs={12}>
                  <Box display={"flex"} alignItems='center' marginY={1}>
                    <Typography>Form View Template:</Typography>
                    {process.env.REACT_APP_WITH_OAS == "true" && (
                      <OASSelector
                        swaggerProps={{
                          type: "form",
                          onlyModels: true,
                          onSuccess: (values: any) => {
                            setValue("dataSource.template", values.template);
                          },
                        }}
                      />
                    )}
                  </Box>

                  <Controller
                    control={control}
                    name={`dataSource.template`}
                    render={({ field: { onChange, value } }) => (
                      <MonacoEditor
                        height='350px'
                        language='json'
                        theme='vs-dark'
                        value={!_.isString(value) ? formatJSON(JSON.stringify(value)) || "" : value}
                        options={{ colorDecorators: true }}
                        onChange={(newValue = "") => {
                          try {
                            onChange(newValue);
                            JSON.parse(newValue);
                            setIsTemplateError();
                          } catch (e: any) {
                            setIsTemplateError(e?.message);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              {viewType === "card-list" && (
                <>
                  <Grid item xs={6}>
                    <BXSwitch label='Play on hover' control={control} name='dataSource.playOnHover' />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Video Details</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <BXInput
                      name={"dataSource.videoTitle"}
                      control={control}
                      error={errors?.dataSource?.videoTitle}
                      fullWidth
                      label={"Title"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <BXInput
                      name={"dataSource.videoUsername"}
                      control={control}
                      error={errors?.dataSource?.videoUsername}
                      fullWidth
                      label={"Username"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <BXInput
                      name={"dataSource.videoSubTitle"}
                      control={control}
                      error={errors?.dataSource?.videoSubTitle}
                      fullWidth
                      label={"Sub Title"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <BXInput
                      name={"dataSource.videoUserImage"}
                      control={control}
                      error={errors?.dataSource?.videoUserImage}
                      fullWidth
                      label={"User Image"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              {viewType === "card-list" && (
                <Grid item container spacing={2} mt={1}>
                  <Grid item xs={12} ml={1}>
                    <BXSwitch label='Split View' control={control} name='dataSource.splitViewConfig.enabled' />
                  </Grid>
                  <Grid item xs={6} ml={1}>
                    <BXInput
                      name={`dataSource.splitViewConfig.viewId`}
                      control={control}
                      error={errors?.dataSource?.splitViewConfig?.viewId}
                      select
                      label={"View"}
                      disabled={!watch("dataSource.splitViewConfig.enabled")}
                    >
                      {viewsWithoutCurrentView?.map((item: any) => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.info?.name || item?.type}
                        </MenuItem>
                      ))}
                    </BXInput>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
