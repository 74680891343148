/**
 * general Page component
 * @constructor
 */
import { Box, Chip } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";
import _ from "lodash";

import React, { FC, useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { useBXContext } from "src/BXEngine/BXContext";
import { BXIcon } from "src/components/BXUI/Icon";
import MainCard from "src/components/cards/MainCard";
import { gridSpacing } from "src/stores/constant";
import { BXPageType } from "src/types/BXPageType";
import { replaceItemPlaceholders } from "../DataTable/ActionButton";

type BXPageProps = {
  page: BXPageType;
  mainAction?: {
    label: string;
    actionUrl: string;
    style: string;
    isALink: boolean;
  };
  children?: any[];
};
const BXPage: FC<BXPageProps> = ({ children: rawChildren, mainAction = null, page: { title, icon, views, layout } }) => {
  const { viewStacks, selectedTableRows, currentApp, setViewStacks } = useBXContext();
  const { pathname } = useLocation();
  // general page component
  const [activeView, setActiveView] = useState(0);

  useEffect(() => {
    return () => {
      setViewStacks([]);
      setActiveView(0);
    };
  }, [pathname]);

  const rowChildren = rawChildren?.sort(
    (x, y) => Number(x?.props?.layout?.[0]?.info?.visibility === "Hidden") - Number(y?.props?.layout?.[0]?.info?.visibility === "Hidden")
  );

  const children = React.Children.map(rowChildren, child => {
    return <React.Fragment>{child}</React.Fragment>;
  });

  return (
    <>
      <MainCard
        style={{ border: 0 }}
        title={
          <Grid container alignItems='center' justifyContent='space-between' spacing={gridSpacing}>
            <Grid item xs={12} md={5} paddingTop={"12px !important"}>
              <Grid container spacing={1} alignItems='center'>
                <Grid item>{icon && <BXIcon icon={icon} size={26} />}</Grid>
                <Grid item paddingTop={"0px !important"}>
                  <Typography variant='h3' fontSize={"16px"}>
                    {title}
                  </Typography>
                </Grid>
                {layout === "stack" && !!viewStacks?.length && (
                  <Grid container mt={1} ml={1} alignItems='center'>
                    <Chip
                      label={replaceItemPlaceholders(
                        children?.[activeView]?.props?.children?.props?.layout?.[0]?.info?.name,
                        null,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        // __data
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        currentApp?.env
                      )}
                      onClick={() => {
                        setViewStacks([]);
                      }}
                    />
                    <Typography style={{ cursor: "pointer" }} fontSize={"16px"}>
                      &nbsp; {`>`}&nbsp;
                    </Typography>

                    {viewStacks.map((viewStack: any, index) => {
                      const selectedRowFromOtherTables = selectedTableRows?.find(
                        item =>
                          item?.viewId == viewStack?.props?.layout?.[0]?.dataSource?.table?.id ||
                          item?.id == viewStack?.props?.selectedViewId
                      );
                      return (
                        <React.Fragment key={index}>
                          <Chip
                            label={replaceItemPlaceholders(
                              viewStack?.props?.layout?.[0]?.info?.name,
                              null,
                              selectedRowFromOtherTables,
                              undefined,
                              undefined,
                              undefined,
                              // __data
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              currentApp?.env
                            )}
                            onClick={() => {
                              if (viewStacks.length - 1 == index) return;

                              setViewStacks(prev => {
                                return prev.slice(0, index + 1);
                              });
                            }}
                            disabled={viewStacks.length - 1 == index}
                            style={
                              (viewStacks.length - 1 == index && {
                                backgroundColor: "transparent",
                                marginLeft: "-10px",
                                opacity: 1,
                              }) ||
                              {}
                            }
                          />
                          <Typography fontSize={"16px"} fontWeight={viewStacks.length - 1 == index ? "bold" : "500"}>
                            &nbsp;{viewStacks.length - 1 != index && `>`}&nbsp;
                          </Typography>
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container spacing='15' justifyContent='flex-end' alignItems='center'>
                <Grid item>{/* i deleted the search box */}</Grid>
                <Grid item>
                  {layout === "switch" && (
                    <>
                      {Array.isArray(views) && views.length > 1
                        ? _.cloneDeep(views)
                            .sort((x, y) => Number(x.info?.visibility === "Hidden") - Number(y.info?.visibility === "Hidden"))
                            .map((view, index) => {
                              if (view.info?.visibility == "Hidden") return <></>;
                              return (
                                <ToggleButton
                                  key={index}
                                  value={index}
                                  selected={index === activeView}
                                  size={"medium"}
                                  onClick={() => setActiveView(index)}
                                  style={{ marginLeft: 8 }}
                                >
                                  {view?.info?.name}
                                  {/* {icons?.[index]?.({ key: index, size: 24 })} */}
                                </ToggleButton>
                              );
                            })
                        : null}
                    </>
                  )}

                  {mainAction && (
                    <Button variant='contained' color='secondary' component={Link} to='/user/add/null'>
                      {mainAction.label}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        content={false}
        sx={{ backgroundColor: "transparent" }}
      >
        {Array.isArray(children) && layout === "switch" ? (
          children[activeView]
        ) : Array.isArray(children) && layout === "stack" ? (
          <>
            {viewStacks?.map((stack: any, index) => {
              return (
                <Box key={`${stack?.props?.selectedViewId}-${index}`} display={viewStacks?.length - 1 !== index ? "none" : "black"}>
                  {stack}
                </Box>
              );
            })}
            <Box display={viewStacks?.length ? "none" : "black"}>{children[activeView]}</Box>
          </>
        ) : (
          children
        )}
      </MainCard>
    </>
  );
};

export default BXPage;
