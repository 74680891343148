import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";

import MonacoEditor from "@monaco-editor/react";
import { IconTrashX } from "@tabler/icons";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { formatJSON } from "./ActionButton";

export const payloadModalTabs = ["Key/Value", "JSON"];

const CreatePayload: FC<any> = props => {
  const { payload: _payload = {}, onSave, onChange, onClose, isLoading, disabled, label, defaultTab } = props || {};

  const [payload, setPayload] = useState(_payload);

  useEffect(() => {
    setPayload(_payload);
  }, [_payload]);

  const [selectedTab, setSelectedTab] = useState(defaultTab || payloadModalTabs[0]);

  let canShowForm = true;
  let errorFormMessage: any = "";
  let jsonError: any = "";

  useEffect(() => {
    if (!jsonError) {
      onChange?.(payload || {});
    }
  }, [payload]);

  try {
    _.forOwn(JSON.parse(payload?.body || "{}"), value => {
      if (_.isObject(value)) {
        canShowForm = false;
        errorFormMessage = "The Json has nested object";
        return false;
      } else {
        canShowForm = true;
      }
    });
  } catch (e: any) {
    canShowForm = false;
    jsonError = e?.message;
    errorFormMessage = e?.message;
  }

  return (
    <>
      <Box mb={2}>
        <Box mb={2}>
          <Typography mb={2}>Headers:</Typography>
          <>
            {_.map(payload?.headers, (value, key) => {
              return (
                <Grid container spacing={2} mb={2}>
                  <Grid item xs>
                    <TextField
                      size='small'
                      fullWidth
                      label={"Key"}
                      value={key}
                      onChange={e => {
                        const oldJson: any = payload?.headers;
                        let newJson: any = {};
                        if (Number(e.target.value)) return;

                        _.forOwn(oldJson, (oldValue, oldKey) => {
                          if (oldKey == key) {
                            newJson[e.target.value] = oldValue;
                          } else {
                            newJson[oldKey] = oldValue;
                          }
                        });

                        setPayload((prev: any) => ({
                          ...prev,
                          headers: newJson,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      size='small'
                      fullWidth
                      label={"Value"}
                      value={value}
                      onChange={e => {
                        const oldJson: any = payload?.headers;

                        let newJson: any = {};
                        _.forOwn(oldJson, (oldValue, oldKey) => {
                          if (key == oldKey) {
                            newJson[oldKey] = e.target.value;
                          } else {
                            newJson[oldKey] = oldValue;
                          }
                        });
                        setPayload((prev: any) => ({
                          ...prev,
                          headers: newJson,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => {
                        const headers = { ...payload?.headers };
                        delete headers[key];
                        setPayload((prev: any) => ({
                          ...prev,
                          headers,
                        }));
                      }}
                    >
                      <IconTrashX color='red' />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
            <Button
              variant='outlined'
              onClick={() => {
                const headers = { ...payload?.headers };
                headers[""] = "";

                setPayload((prev: any) => ({
                  ...prev,
                  headers,
                }));
              }}
            >
              Add row
            </Button>
          </>
          <Box marginY={1}>
            <Divider />
          </Box>
        </Box>
      </Box>

      <Box marginBottom={2}>
        <Box marginBottom={2}>
          {payloadModalTabs.map(type => (
            <ToggleButtonGroup color='primary' value={selectedTab} exclusive>
              <ToggleButton
                style={{ marginRight: 12 }}
                value={selectedTab}
                onClick={() => {
                  setSelectedTab(type);
                }}
                selected={selectedTab === type}
                key={type}
              >
                {type}
              </ToggleButton>
            </ToggleButtonGroup>
          ))}
        </Box>
        <Box mb={2}>
          <Typography>Body:</Typography>
        </Box>
        {selectedTab == "JSON" ? (
          <MonacoEditor
            width='100%'
            height='400px'
            language='json'
            theme='vs-dark'
            value={payload?.body}
            options={{ colorDecorators: true }}
            onChange={newValue =>
              setPayload((old: any) => ({
                ...old,
                body: newValue,
              }))
            }
          />
        ) : (
          <>
            {canShowForm ? (
              <>
                {_.map(JSON.parse(payload?.body || "{}"), (value, key) => {
                  return (
                    <Grid container spacing={2} mb={2}>
                      <Grid item xs>
                        <TextField
                          size='small'
                          fullWidth
                          label={"Key"}
                          value={key}
                          onChange={e => {
                            const oldJson: any = JSON.parse(payload?.body || "{}");
                            // const isKeyExist = _.find(oldJson, (_value, _key) => _key == e.target.value);
                            if (Number(e.target.value)) return;

                            let newJson: any = {};
                            _.forOwn(oldJson, (oldValue, oldKey) => {
                              if (key == oldKey) {
                                newJson[e.target.value] = oldValue;
                              } else {
                                newJson[oldKey] = oldValue;
                              }
                            });
                            setPayload((prev: any) => ({
                              ...prev,
                              body: formatJSON(JSON.stringify(newJson)),
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          size='small'
                          fullWidth
                          label={"Value"}
                          value={value}
                          onChange={e => {
                            const oldJson: any = JSON.parse(payload?.body || "{}");

                            let newJson: any = {};
                            _.forOwn(oldJson, (oldValue, oldKey) => {
                              if (key == oldKey) {
                                newJson[oldKey] = e.target.value;
                              } else {
                                newJson[oldKey] = oldValue;
                              }
                            });
                            setPayload((prev: any) => ({
                              ...prev,
                              body: formatJSON(JSON.stringify(newJson)),
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() => {
                            const Json = JSON.parse(payload?.body || "{}");
                            delete Json[key];
                            setPayload((prev: any) => ({
                              ...prev,
                              body: formatJSON(JSON.stringify(Json)),
                            }));
                          }}
                        >
                          <IconTrashX color='red' />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                })}
                <Button
                  variant='outlined'
                  onClick={() => {
                    const Json = JSON.parse(payload?.body || "{}");
                    Json[""] = "";
                    setPayload((prev: any) => ({
                      ...prev,
                      body: formatJSON(JSON.stringify(Json)),
                    }));
                  }}
                >
                  Add row
                </Button>
              </>
            ) : (
              <Typography color='red'>{errorFormMessage}</Typography>
            )}
          </>
        )}
      </Box>

      {/* Modal Actions */}
      <Grid item xs={12}>
        <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
          <LoadingButton
            loading={isLoading}
            loadingPosition='start'
            variant={"contained"}
            aria-label={"save"}
            disabled={disabled}
            onClick={() => {
              if (jsonError) {
                enqueueSnackbarRef?.(jsonError, {
                  variant: "error",
                  anchorOrigin: {
                    horizontal: "right",
                    vertical: "bottom",
                  },
                });
              } else {
                onSave(payload || {});
              }
            }}
          >
            {label}
          </LoadingButton>
        </DialogActions>
      </Grid>
    </>
  );
};

export default CreatePayload;
