import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Grid, styled, Switch, TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { useNavigate, useParams } from "react-router-dom";
import { KeysToComponentMap } from "src/BXEngine";
import { useBXBuilderContext } from "src/BXEngine/BXBuilderContext";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { v4 as uuid } from "uuid";
import { BoxComponent } from "./BoxComponent";
import { ContainerGrid, CustomMediaCard } from "./components";
import { CustomAutocomplete } from "./components/CustomAutocomplete";
import { CustomCheckbox } from "./components/CustomCheckbox/CustomCheckbox";
import { CustomFileUpload } from "./components/CustomFileUpload";
import { CustomImage } from "./components/CustomImage";
import { LayoutToggleButtons } from "./components/LayoutToggleButtons/LayoutToggleButtons";
import { CustomDragLayer } from "./CustomDragLayer";
import { SideMenuItems } from "./SideMenuComponent";
import { ComponentItemType } from "./types";
import { getItemClosestProp, getNewPosition, layoutBreakPoints } from "./utils";

export const MUI_COMPONENTS: any = {
  TextField: styled(TextField)`
  placeholderTextColor: red !important
  cursor: grab;
    & .MuiOutlinedInput-root {
      & fieldset {
        color: white !important;
        border-color: #9f4db6 !important;
      }
    }
  `,
  Button: styled(Button)`
    background-color: #9f4db6 !important;
    color: white !important;
    cursor: grab;
  `,
  Typography: styled(Typography)`
    cursor: grab;
  `,
  Divider: styled(Divider)`
    cursor: grab;
  `,
  Switch: styled(Switch)`
    cursor: grab;
  `,
  CustomCheckbox: styled(CustomCheckbox)`
    cursor: grab;
  `,
  CustomImage: styled(CustomImage)`
    cursor: grab;
  `,
  CustomAutocomplete: styled(CustomAutocomplete)`
    cursor: grab;
  `,
  CustomMediaCard: styled(CustomMediaCard)`
    cursor: grab;
  `,
  CustomFileUpload: styled(CustomFileUpload)`
    cursor: grab;
  `,
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: { position: "fixed", top: 24, left: 24, zIndex: 100000 },
  },
}));
export const BOX_HEIGHT = 800;

const FormBuilderEditor: FC = () => {
  const ACCEPTS = [
    ComponentItemType.TextField,
    ComponentItemType.Button,
    ComponentItemType.Typography,
    ComponentItemType.Divider,
    ComponentItemType.Switch,
    ComponentItemType.CustomCheckbox,
    ComponentItemType.CustomImage,
    ComponentItemType.CustomAutocomplete,
    ComponentItemType.CustomMediaCard,
    ComponentItemType.CustomFileUpload,
    ..._.map(KeysToComponentMap, (value, key) => key),
  ];

  const { appId, collectionId, pageId, viewId } = useParams();
  const { apps, editView } = useBXBuilderContext();
  const navigate = useNavigate();
  const classes = useStyles();
  const [view, setView] = useState<any>([]);
  const [activeComponent, setActiveComponent] = useState<any>();
  const [layoutBreak, setLayoutBreak] = useState<any>("xl");
  const [canvasEnabled, setCanvasEnabled] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState(false);
  const [canDrop, setCanDrop] = useState(false);
  const boxRef = useRef<any>();
  const [value, setValue] = useState(0);
  const [keyCode, setKeyCode] = useState<string>();
  const [keyboardStep, setKeyboardStep] = useState(1);
  const [mouseStep, setMouseStep] = useState(10);

  const handleCanvasToggle = () => {
    setCanvasEnabled(prev => !prev);
  };
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const elements = view?.dataSource?.formBuilder;
  useEffect(() => {
    if (apps?.length && appId && collectionId && pageId && viewId) {
      const app = apps?.find(app => app?.id === appId);
      const collection = app?.templateConfig?.collections?.find(collection => collection?.id == collectionId);
      const page = collection?.pages?.find(page => page?.id === pageId);
      const view = page?.views?.find(view => view?.id === viewId);
      setView(view);
    }
  }, [apps?.length]);

  const moveBox = (data: any) => {
    const { item, ...rest } = data || {};

    const newItem = { ...item, ...rest };
    setView((prev: any) => {
      let elements = [];
      if (newItem?.id) {
        elements = prev?.dataSource?.formBuilder?.map((el: any, index: any) => (el.id === newItem.id ? { ...newItem } : el));
      } else {
        elements = [
          ...(prev?.dataSource?.formBuilder || []),
          {
            id: uuid(),
            ...newItem,
            props: {
              ...newItem?.props,
              id: `Component-${view?.dataSource?.formBuilder?.length || 0}`,
              key: `Component-${view?.dataSource?.formBuilder?.length || 0}`,
            },
          },
        ];
      }
      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilder: elements,
        },
      };
    });
  };
  const canDropComponent = (monitor?: DropTargetMonitor<any, unknown>, item?: any, newPosition?: any) => {
    const boxPosition = boxRef.current?.getBoundingClientRect();
    const delta = monitor ? monitor.getSourceClientOffset() : document.getElementById(item?.id)?.getBoundingClientRect();

    const component = monitor ? monitor?.getItem()?.config : item?.config;
    const dx =
      boxPosition?.x < Number(delta?.x + (newPosition?.x || 0)) &&
      Number(delta?.x + (newPosition?.x || 0)) <
        boxPosition?.x +
          boxPosition?.width -
          ((Number(getItemClosestProp(component?.widthPercentage, layoutBreak)?.replace("%", "")) / 100) * boxPosition?.width ||
            component?.defaultWidth);
    const dy =
      boxPosition?.y < Number(delta?.y + (newPosition?.y || 0)) &&
      Number(delta?.y + (newPosition?.y || 0)) <
        boxPosition?.y +
          BOX_HEIGHT -
          ((Number(getItemClosestProp(component?.heightPercentage, layoutBreak)?.replace("%", "")) / 100) * BOX_HEIGHT ||
            component?.defaultHeight);
    setCanDrop(dx && dy);
    return dx && dy;
  };
  const moveBoxFunction = (item: any, monitor?: any, newPosition?: any, step: number = 10) => {
    const delta = monitor ? monitor.getSourceClientOffset() : document.getElementById(item?.id)?.getBoundingClientRect();
    const boxPosition = boxRef.current?.getBoundingClientRect();
    const translateX = Math.round(Number(delta?.x + (newPosition?.x || 0)) / step) * step;
    const translateY = Math.round(Number(delta?.y + (newPosition?.y || 0)) / step) * step;
    const left = Number(translateX) - Number(boxPosition?.x);
    const top = Number(translateY) - Number(boxPosition?.y) + Number(item?.config?.draggingOffSet || 0);
    const leftPercentage = (left / boxPosition.width) * 100 + "%";
    const topPercentage = (top / BOX_HEIGHT) * 100 + "%";

    const PWidth = (item?.config?.defaultWidth / boxPosition?.width) * 100;
    const percentageWidth = PWidth > 100 ? "100%" : PWidth + "%";
    const PHeight = (item?.config?.defaultHeight / BOX_HEIGHT) * 100;
    const percentageHeight = PHeight > 100 ? "100%" : PHeight + "%";

    const hasCustom = item?.config?.hasCustom;
    moveBox({
      item,
      hasCustom: layoutBreak === "xs",
      left: { ...item?.left, xs: hasCustom ? item.left?.xs : left, [layoutBreak]: left },
      top: { ...item?.top, xs: hasCustom ? item.left?.xs : top, [layoutBreak]: top },
      leftPercentage: { ...item?.leftPercentage, xs: hasCustom ? item.left?.xs : leftPercentage, [layoutBreak]: leftPercentage },
      topPercentage: { ...item?.topPercentage, xs: hasCustom ? item.left?.xs : topPercentage, [layoutBreak]: topPercentage },
      config: !item?.config?.widthPx
        ? {
            ...item?.config,
            widthPx: { xs: item?.config?.defaultWidth, [layoutBreak]: item?.config?.defaultWidth },
            height: { xs: item?.config?.defaultHeight, [layoutBreak]: item?.config?.defaultHeight },
            widthPercentage: {
              xs: item?.config?.widthPercentage?.xs || percentageWidth,
              [layoutBreak]: item?.config?.widthPercentage?.[layoutBreak] || percentageWidth,
            },
            heightPercentage: {
              xs: item?.config?.heightPercentage?.xs || percentageHeight,
              [layoutBreak]: item?.config?.heightPercentage?.[layoutBreak] || percentageHeight,
            },
          }
        : { ...item?.config },
    });
    return undefined;
  };

  const [{ isOver }, drop] = useDrop({
    accept: ACCEPTS,
    drop: (item, monitor) => moveBoxFunction(item, monitor, undefined, mouseStep),
    canDrop: (item, monitor) => canDropComponent(monitor),
    collect: monitor => ({
      isOver: !monitor.isOver(),
    }),
  });
  const handleBuilderSave = () => {
    const elementError = view?.dataSource?.formBuilder?.find((item: any) => !item?.props?.id || !item?.props?.key);
    if (elementError) {
      handleSelectComponent(elementError);
      enqueueSnackbarRef?.("Save Failed Due To missing Component Key or Id", {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
      return;
    }
    setIsSaving(true);
    editView?.(appId!, collectionId!, pageId!, viewId!, view, () => {
      setIsSaving(false);
      enqueueSnackbarRef?.("Posted Successfully", {
        variant: "success",
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
    });
  };
  const handleBackButton = () => {
    navigate(`/buildx/app?appId=${appId}&collectionId=${collectionId}&pageId=${pageId}&viewId=${viewId}`);
  };
  const handleChangeLayout = (value: string | null) => {
    setLayoutBreak(value);
  };
  const handleChangeComponentProps = (newItem: any) => {
    setView((prev: any) => {
      let elements = [];
      elements = prev?.dataSource?.formBuilder?.map((el: any) => (el.id === newItem.id ? newItem : el));

      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilder: elements,
        },
      };
    });
  };
  const handleChangeViewProps = (newItem: any) => {
    setView((prev: any) => {
      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilder: elements,
        },
      };
    });
  };
  const handleDeleteItem = (itemId: any) => {
    setView((prev: any) => {
      let elements = [];
      elements = prev?.dataSource?.formBuilder?.filter((el: any) => el.id !== itemId);

      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilder: elements,
        },
      };
    });
  };
  const handleSelectComponent = (item: any) => {
    setActiveComponent(item?.id);
    setValue(2);
  };

  const handleCloseEdit = () => {
    setActiveComponent(undefined);
    setValue(1);
  };
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Shift") {
      setKeyboardStep(10);
      setMouseStep(step => (step === 1 ? 10 : 1));
    }
    if (getNewPosition(e.key, keyboardStep)) {
      setKeyCode(e.key);
    }
    return true;
  };
  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === "Shift") {
      setKeyboardStep(1);
    }
    setKeyCode(undefined);
  };
  useEffect(() => {
    if (keyCode) {
      const item = view?.dataSource?.formBuilder?.find((el: any, index: any) => el.id === activeComponent);
      canDropComponent(undefined, item, getNewPosition(keyCode, keyboardStep)) &&
        moveBoxFunction(item, null, getNewPosition(keyCode, keyboardStep), keyboardStep);
    }
  }, [keyCode]);

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    document.body.addEventListener("keyup", handleKeyUp);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
      document.body.removeEventListener("keyup", handleKeyUp);
    };
  }, [activeComponent]);

  const handleDynamicLayout = (values: any, item: any) => {
    const newItem = {
      ...item,
      config: {
        ...item?.config,
        defaultWidth: values?.width,
        defaultHeight: values?.height,
        heightPx: {
          xs: values?.height,
          xl: values?.height,
        },
      },
    };
    setView((prev: any) => {
      let elements = [];

      elements = prev?.dataSource?.formBuilder?.map((el: any, index: any) => (el.id === newItem.id ? { ...newItem } : el));

      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilder: elements,
        },
      };
    });
  };

  const handleCustomComponentSelect = (data: any) => {
    const item: any = {
      type: data?.config?.type,
      props: data,
      left: 10,
      top: 10,
      config: {
        defaultWidth: 150,
        defaultHeight: 50,
        heightPx: {
          xs: 50,
          xl: 50,
        },
        fixedWidth: false,
        fixedHeight: true,
        controlledComponent: true,
        disableResizeHeight: true,
      },
    };

    const boxPosition = boxRef.current?.getBoundingClientRect();
    const left = 10;
    const top = 10;
    const leftPercentage = (left / boxPosition.width) * 100 + "%";
    const topPercentage = (top / BOX_HEIGHT) * 100 + "%";

    const PWidth = (item?.fixedWidth / boxPosition?.width) * 100;
    const percentageWidth = PWidth > 100 ? "100%" : PWidth + "%";

    const PHeight = (item?.fixedWidth / BOX_HEIGHT) * 100;
    const percentageHeight = PHeight > 100 ? "100%" : PHeight + "%";

    moveBox({
      item,
      left: { ...item?.left, [layoutBreak]: left },
      top: { ...item?.top, [layoutBreak]: top },
      leftPercentage: { ...item?.leftPercentage, [layoutBreak]: leftPercentage },
      topPercentage: { ...item?.topPercentage, [layoutBreak]: topPercentage },
      widthPercentage: { ...item?.widthPercentage, [layoutBreak]: percentageWidth },
      heightPercentage: { ...item?.heightPercentage, [layoutBreak]: percentageHeight },
    });
    return undefined;
  };

  useEffect(() => {
    isOver && setCanDrop(false);
  }, [isOver]);

  return (
    <>
      <Box display='flex' gap={2} className={classes.root}>
        <CustomDragLayer
          step={mouseStep}
          canDrop={canDrop}
          layoutBreak={layoutBreak}
          boxPosition={boxRef?.current?.getBoundingClientRect()}
        />
        <LoadingButton variant='outlined' onClick={handleBackButton}>
          Back to config
        </LoadingButton>
        <LoadingButton variant='contained' loading={isSaving} onClick={handleBuilderSave}>
          Save
        </LoadingButton>
        <LayoutToggleButtons activeLayout={layoutBreak} onLayoutChange={handleChangeLayout} />
      </Box>
      <Grid container spacing={2} padding={2}>
        <Grid item lg={9} sm={7} xs={12} display='flex' justifyContent='center'>
          <Box
            id='canvas-box'
            sx={{
              position: "relative",
              justifyContent: "center",
              width: layoutBreakPoints?.find(el => el?.id === layoutBreak)?.width,
              minHeight: BOX_HEIGHT,
              border: "0.5px solid #ccc",
              borderRadius: "10px",
              overflow: "hidden",
            }}
            ref={drop}
          >
            <ContainerGrid canvasEnabled={canvasEnabled} />
            <Box ref={boxRef}>
              {!!elements?.length &&
                elements?.map((item: any, index: number) => {
                  return (
                    <BoxComponent
                      key={item?.id}
                      item={item}
                      layoutBreak={layoutBreak}
                      index={index}
                      boxPosition={boxRef?.current?.getBoundingClientRect()}
                      setView={setView}
                      onLayout={(values: any) => handleDynamicLayout(values, item)}
                      activeComponent={activeComponent}
                      setActiveComponent={handleSelectComponent}
                    />
                  );
                })}
            </Box>
          </Box>
        </Grid>
        <Grid item container display='flex' justifyContent='center' lg={3} sm={5} xs={12}>
          <SideMenuItems
            layoutBreak={layoutBreak}
            activeComponent={view?.dataSource?.formBuilder?.find((item: any) => item?.id === activeComponent)}
            onChangeProp={handleChangeComponentProps}
            onChangeViewProps={handleChangeViewProps}
            onDiscardEdit={handleCloseEdit}
            tab={value}
            handleChangeTab={handleChangeTab}
            onCanvasToggle={handleCanvasToggle}
            canvasEnabled={canvasEnabled}
            onDeleteItem={handleDeleteItem}
            onCustomComponentSelect={handleCustomComponentSelect}
          />
        </Grid>
      </Grid>
    </>
  );
};

export { FormBuilderEditor };
