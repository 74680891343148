import { Autocomplete, Box, CircularProgress, Divider, Grid, Switch, Tab, Tabs, TextField, Theme, Typography } from "@mui/material";
import { IconList, IconTable } from "@tabler/icons";
import { useState } from "react";
import { useQuery } from "react-query";
import axios from "src/utils/axios";
import { DragElement } from "./DragElement";
import { BOX_HEIGHT, MUI_COMPONENTS } from "./FormBuilderEditor";
import { ComponentEditor } from "./components";
import { TabPanelProps } from "./types";
import { components } from "./utils";

export const SideMenuComponent = (props: any) => {
  const { item } = props;

  const Component = MUI_COMPONENTS[item.type];

  return (
    <Grid item xs={6}>
      <DragElement resizable={false} {...props}>
        <Component disabled {...item?.props} />
      </DragElement>
    </Grid>
  );
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-2`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const SideMenuItems = ({
  tab,
  handleChangeTab,
  layoutBreak,
  onDeleteItem,
  activeComponent,
  onChangeViewProps,
  onChangeProp,
  onCanvasToggle,
  canvasEnabled,
  onCustomComponentSelect,
}: any) => {
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  let queryKey = ["component-list"];

  if (searchText.trim()) {
    queryKey.push(searchText.trim());
  }
  const { data: options, isFetching } = useQuery(
    queryKey,
    ({ pageParam }) => {
      return axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/admin/component", {
        params: {
          cursor: pageParam,
          keyword: searchText || undefined,
        },
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!autoCompleteOpen,
    }
  );

  const loading = autoCompleteOpen && isFetching;

  return (
    <Box sx={{ backgroundColor: (theme: Theme) => theme.palette.background.paper, borderRadius: "10px", width: "100%" }}>
      <Grid item xs={12} sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={handleChangeTab} aria-label='basic tabs example'>
          <Tab label=' Add New' {...a11yProps(0)} />
          <Tab label='View Props' {...a11yProps(1)} />
          <Tab label='Inspect' {...a11yProps(2)} />
        </Tabs>
      </Grid>
      <TabPanel value={tab} index={0}>
        <Grid container spacing={2} alignItems='center'>
          {!!components?.length &&
            components?.map((item, index: number) => {
              return <SideMenuComponent key={index} item={item} index={index} hideSource={false} layoutBreak={layoutBreak} />;
            })}
          <Grid item xs={12} fontWeight={700} mt={3}>
            <Typography>Components</Typography>
          </Grid>

          <Box sx={{ width: "100%" }} mt={1.5}>
            <Divider />
          </Box>

          <Grid item xs={12}>
            <Autocomplete
              sx={{ width: 300 }}
              open={autoCompleteOpen}
              onOpen={() => {
                setAutoCompleteOpen(true);
              }}
              onClose={() => {
                setAutoCompleteOpen(false);
              }}
              onChange={(e, newValue) => {
                onCustomComponentSelect?.(newValue);
              }}
              isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
              getOptionLabel={option => option.name}
              options={(options?.data?.items as any) || []}
              renderOption={(props, option) => (
                <li {...props}>
                  {option?.config?.type === "autoComplete" && <IconList style={{ marginRight: 5 }} />}
                  {option?.config?.type === "formTable" && <IconTable style={{ marginRight: 5 }} />}
                  {option.name}
                </li>
              )}
              loading={loading}
              componentsProps={{
                clearIndicator: {
                  sx: {
                    marginRight: 0.3,
                  },
                },
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Components'
                  onChange={e => setSearchText(e.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{loading ? <CircularProgress color='inherit' size={20} /> : null}</>,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <Box display='flex' justifyContent='space-between' alignItems='center' pb={3}>
              <Typography>Item Properties:</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label={"Key"} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label={"Name"} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label={"Canvas Height"} value={BOX_HEIGHT} />
          </Grid>
          <Grid display='flex' item xs={12} alignItems='center' gap={"1px"}>
            <Switch checked={canvasEnabled} onChange={onCanvasToggle} inputProps={{ "aria-label": "controlled" }} />
            <Typography>Canvas Grid</Typography>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <ComponentEditor item={activeComponent} onDeleteItem={onDeleteItem} onChangeProp={onChangeProp} />
      </TabPanel>
    </Box>
  );
};
