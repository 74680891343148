import { CircularProgress, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { IconRefresh } from "@tabler/icons";
import update from "immutability-helper";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import ReactJson from "react-json-view";
import { TableItem } from "src/components/BXUI/DataTable/Item";
import { Pagination } from "src/components/pagination";
import { ElementBaseProps } from "src/types/UIElement";
import axiosServices from "src/utils/axios";
import { getAuthorizationHeader } from "src/utils/generalUtils";
import { queryClient, replaceBaseUrl, useBXContext, useValue } from "../../../BXEngine/BXContext";
import { UserInput } from "../UserInput";
import { ActionButton, replaceItemPlaceholders } from "./ActionButton";

/**
 *
 */
type BXDataTableCellType = "numeric" | "text" | "complex";

/**
 * @type BXDataTableColumn
 * description of table column. including header and cell type
 */
export type BXDataTableColumn = {
  name: string;
  sortable?: boolean;
  filterable?: boolean;
  cellType?: BXDataTableCellType;
  children: BXDataTableColumn[]; // applies only if cellType is complex
  verticalAlign?: string;
  horizontalAlign?: string;
  source?: string;
  type?: string;
  allowWrap?: boolean;
};

type DataTableProps = {
  columns: BXDataTableColumn[];
  actions?: any[];
  auth: any;
  layout: string;
  info?: {
    name?: string;
    showApiMode?: string;
    paginationMode?: string;
  };
  order?: {
    enabled?: boolean;
  };
  views: any;
  showModalHeader?: boolean;
} & ElementBaseProps;

export const BXDataTable: FC<DataTableProps> = props => {
  const {
    columns = [],
    auth,
    id = "",
    dataSource,
    actions,
    views,
    info,
    order,
    selectedViewId,
    __data = {},
    closeModal,
    parentIds = [],
    showModalHeader,
    config,
  } = props;
  const [userInputsValues, setUserInputValues] = useState({});
  const [isApiMode, setIsApiMode] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [cursors, setCursors] = useState([undefined]);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const { palette } = useTheme();
  const { selectedTableRows, setSelectedTableRows, currentApp, getAuth } = useBXContext();
  const selectedRow = selectedTableRows?.find(item => item?.viewId == id);
  const selectedRowFromOtherTables = selectedTableRows?.find(item => item?.viewId == dataSource?.table?.id || item?.id == selectedViewId);

  const withInfiniteScroll = !info?.paginationMode || info?.paginationMode == "Infinite Scroll";

  // we need the key here to access the right data
  const { data, isFetching, hasNextPage, fetchNextPage, refetch, isError } = useValue(
    id,
    dataSource?.limit || 20,
    dataSource?.sourceType,
    dataSource?.sourceType == "USER INPUT" ? userInputsValues : selectedRowFromOtherTables,
    selectedRowFromOtherTables,
    userInputsValues,
    dataSource?.sourceType == "USER INPUT",
    withInfiniteScroll,
    {},
    cursors?.[currentPage - 1],
    __data,
    undefined,
    undefined,
    undefined,
    [selectedViewId]
  );

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: hasNextPage || false,
    onLoadMore: () => fetchNextPage(),
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: isError,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    return () => {
      if (dataSource?.sourceType == "USER INPUT") {
        queryClient.setQueryData([id, selectedViewId], () => ({
          pages: [],
        }));
      }
    };
  }, []);

  const filterSelectedChildren = (items: any, _id: any) => {
    items.forEach((item: any) => {
      if (item?.dataSource?.table?.id == _id) {
        items = items.filter((_item: any) => _item?.dataSource?.table?.id != _id);
        items = filterSelectedChildren(items, item?.viewId);
      }
    });
    return items;
  };

  const handleSelectRow = (itemData: any, isReplace?: boolean) => {
    const item = { ...itemData, viewId: id, dataSource };
    setSelectedTableRows((prev: any[]) => {
      let newData = [...prev];

      // find if is there row selected in the table
      const checkTableRowIsExist = newData.find((_item: any) => item?.id != _item?.id && _item?.viewId == id);

      if (checkTableRowIsExist) {
        newData = newData.filter(prevData => prevData.id != checkTableRowIsExist?.id || prevData?.viewId != id);
      }

      // find if the item is exist in the array with same table
      const checkItemIsExist = newData.find((_item: any) => item?.id === _item?.id && _item?.viewId == id);

      if (!checkItemIsExist) {
        newData.push(item);
      } else {
        if (!isReplace) {
          newData = filterSelectedChildren(newData, id);
          newData = newData.filter(prevData => prevData.id != checkItemIsExist?.id || prevData?.viewId != id);
        }
      }

      return newData;
    });
  };

  // @ts-ignore
  let entries: any = [];

  if (!withInfiniteScroll) {
    entries = (_.isNil(dataSource?.dataEntry) ? data : _.get(data, dataSource?.dataEntry as any))?.filter?.(Boolean);
  } else {
    entries = (
      _.isNil(dataSource?.dataEntry)
        ? _.flatten(data?.pages)
        : _.flatten(data?.pages?.map((p: any) => _.get(p, dataSource?.dataEntry as any)))
    )?.filter?.(Boolean);
  }

  const handleDragHover = (dragIndex: any, hoverIndex: number) => {
    const queryKeys = [id, selectedViewId];
    const cursor = cursors?.[currentPage - 1];

    if (dataSource?.sourceType === "TABLE") {
      const replacementObject = selectedRowFromOtherTables;
      queryKeys.push(replacementObject?.id);
    }

    if (cursor) {
      queryKeys.push(cursor);
    }

    const pageIndex = data?.pages?.findIndex(
      (items: any) => _.get(items, dataSource?.dataEntry as any)?.findIndex((item: any) => entries.indexOf(item) === dragIndex) !== -1
    );

    const pageSize = 20;
    const page = data?.pages?.[pageIndex];
    const item = !withInfiniteScroll
      ? _.get(data, dataSource?.dataEntry as any)?.[dragIndex]
      : _.get(page, dataSource?.dataEntry as any)?.[dragIndex % pageSize];

    let newPage = {};
    let newData: any = [];

    if (withInfiniteScroll) {
      _.get(page, dataSource?.dataEntry as any).splice(dragIndex % pageSize, 1);
      newPage = {
        ...page,
        [dataSource?.dataEntry as any]: [
          ..._.get(page, dataSource?.dataEntry as any).slice(0, hoverIndex % pageSize),
          item,
          ..._.get(page, dataSource?.dataEntry as any).slice(hoverIndex % pageSize),
        ],
      };

      newData = [...data.pages.slice(0, pageIndex), newPage, ...data.pages.slice(pageIndex + 1)];
    }

    const payload = !withInfiniteScroll
      ? {
          ...data,
          items: update(data?.items, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, item],
            ],
          }),
        }
      : { ...data, pages: newData };

    queryClient.setQueryData(queryKeys, payload);
  };

  const handleUpdateElement = (dragIndex: any, hoverIndex: number) => {
    const queryKeys = [id, selectedViewId];
    const cursor = cursors?.[currentPage - 1];

    if (dataSource?.sourceType === "TABLE") {
      const replacementObject = selectedRowFromOtherTables;
      queryKeys.push(replacementObject?.id);
    }

    if (cursor) {
      queryKeys.push(cursor);
    }

    const pageIndex = data?.pages?.findIndex(
      (items: any) => _.get(items, dataSource?.dataEntry as any)?.findIndex((item: any) => entries.indexOf(item) === dragIndex) !== -1
    );

    const pageSize = 20;
    const page = data?.pages?.[pageIndex];
    const dragItem = !withInfiniteScroll
      ? _.get(data, dataSource?.dataEntry as any)?.[dragIndex]
      : _.get(page, dataSource?.dataEntry as any)?.[dragIndex % pageSize];

    const dropItem = !withInfiniteScroll
      ? _.get(data, dataSource?.dataEntry as any)?.[dragIndex - 1 === -1 ? 1 : dragIndex - 1]
      : _.get(page, dataSource?.dataEntry as any)?.[(dragIndex - 1 === -1 ? 1 : dragIndex - 1) % pageSize];

    const url = replaceBaseUrl(config.order.endpoint, currentApp);
    const { token } = getAuth(currentApp?.id!) || {};

    axiosServices.request({
      url: replaceItemPlaceholders(
        url,
        dragItem,
        selectedRowFromOtherTables,
        userInputsValues,
        dataSource?.sourceType == "USER INPUT",
        undefined,
        __data,
        undefined,
        undefined,
        { drag: dragItem, drop: dropItem, direction: dragIndex === 0 ? "BEFORE" : "AFTER" },
        currentApp?.env
      ),
      method: config.order.method,
      headers: {
        ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
      },
    });
  };

  return (
    <Box marginY={1} paddingX={1}>
      <Card variant={"outlined"}>
        <Grid container paddingX={2} mt={!showModalHeader ? 2 : 0} mb={!showModalHeader ? 2 : 0}>
          <Grid container item xs={12} justifyContent='flex-end'>
            {!showModalHeader && (
              <>
                <Typography flex={1} fontSize={"16px"} lineHeight={2}>
                  {replaceItemPlaceholders(
                    info?.name,
                    null,
                    selectedRowFromOtherTables,
                    undefined,
                    undefined,
                    undefined,
                    __data,
                    undefined,
                    undefined,
                    undefined,
                    currentApp?.env
                  )}
                </Typography>
                <Tooltip title='Refresh'>
                  <Box mr={1} alignSelf='center'>
                    <IconButton
                      onClick={() => {
                        setIsRefreshing(true);
                        queryClient.refetchQueries([id, selectedViewId]).then(() => {
                          setIsRefreshing(false);

                          // enqueueSnackbarRef?.("Refreshed Successfully", {
                          //   variant: "success",
                          //   anchorOrigin: {
                          //     horizontal: "right",
                          //     vertical: "bottom",
                          //   },
                          // });
                        });
                      }}
                      disabled={isFetching}
                      style={{ backgroundColor: palette.grey[100] }}
                    >
                      {isRefreshing ? <CircularProgress size='16px' /> : <IconRefresh color={palette.text.primary} size={16} />}
                    </IconButton>
                  </Box>
                </Tooltip>
              </>
            )}
            {!withInfiniteScroll && !!entries?.length && (
              <>
                <Pagination
                  currentPage={currentPage}
                  totalPageCount={cursors?.length}
                  hasNextPage={data?.hasMore && !isFetching}
                  isFetching={isFetching}
                  onPageChange={(page: number) => {
                    setCurrentPage(page);
                    if (page > currentPage) {
                      setCursors(prev => [...prev, data.cursor]);
                    } else {
                      setCursors(prev => prev.slice(0, page));
                    }
                  }}
                />
              </>
            )}
            {_.isArray(actions) && !_.isEmpty(actions.filter(action => action.isGlobal)) && (
              <>
                {actions
                  ?.filter(action => action.isGlobal)
                  .map(action => (
                    <Box key={action.id} ml={1} alignSelf='center'>
                      <ActionButton
                        auth={auth}
                        item={{}}
                        tableId={id}
                        action={action}
                        selectedRowFromOtherTables={selectedRowFromOtherTables}
                        userInputsValues={userInputsValues}
                        views={views}
                        __data={__data}
                        closeModal={closeModal}
                        iconButton={!action?.showButtonLabel}
                        parentIds={parentIds}
                      />
                    </Box>
                  ))}
              </>
            )}

            {info?.showApiMode != "Hidden" && !showModalHeader && (
              <Box display='flex' alignItems='center' border={"1px solid white"} ml={2} paddingX={1.5} borderRadius={10}>
                <Typography>API Mode</Typography>
                <Switch onChange={(_, value) => setIsApiMode(value)} />
              </Box>
            )}
          </Grid>
          {dataSource?.sourceType == "USER INPUT" && (
            <Card variant={"outlined"} style={{ width: "100%", marginTop: 8, padding: "24px 24px 24px 18px" }}>
              <Grid xs={dataSource?.userInputs?.length != 1 ? 12 : 6} item container spacing={2}>
                {dataSource?.userInputs?.map((input, index) => (
                  <UserInput key={index} input={input} setUserInputValues={setUserInputValues} />
                ))}
                {dataSource?.userInputs?.length == 1 && (
                  <Grid item>
                    <Button
                      variant={"contained"}
                      onClick={() => {
                        refetch({ queryKey: [userInputsValues] });
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                )}
              </Grid>
              {dataSource?.userInputs?.length != 1 && (
                <Grid container item mt={3} alignItems='center' justifyContent='center'>
                  <Button
                    variant={"contained"}
                    onClick={() => {
                      refetch({ queryKey: [userInputsValues] });
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              )}
            </Card>
          )}
        </Grid>

        <TableContainer>
          <Table stickyHeader>
            {!isApiMode && (
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      style={{ background: palette.background.default }}
                      align={column.cellType === "numeric" ? "right" : "left"}
                      key={column.name}
                      sx={{ pl: 3 }}
                    >
                      {column.name}
                    </TableCell>
                  ))}
                  {_.isArray(actions) && !_.isEmpty(actions.filter(action => action.isItem)) && (
                    <TableCell style={{ background: palette.background.default }} />
                  )}
                </TableRow>
              </TableHead>
            )}
            {isApiMode ? (
              <ReactJson src={entries as any} theme={"paraiso"} style={{ width: "100%" }} />
            ) : (
              <TableBody>
                {!_.isEmpty(entries) &&
                  entries?.map?.((row: any, index: number) => (
                    <TableItem
                      order={order}
                      auth={auth}
                      key={`gi-${row?.id}-${index}`}
                      views={views}
                      handleDragHover={handleDragHover}
                      handleUpdateElement={handleUpdateElement}
                      columns={columns}
                      actions={actions}
                      userInputsValues={userInputsValues}
                      isUserInput={dataSource?.sourceType == "USER INPUT"}
                      item={row}
                      onSelectRow={handleSelectRow}
                      isSelected={selectedRow && row?.id == selectedRow?.id}
                      selectedRowFromOtherTables={selectedRowFromOtherTables}
                      tableId={id}
                      index={index}
                      __data={__data}
                      closeModal={closeModal}
                      parentIds={parentIds}
                    />
                  ))}
                {_.isEmpty(entries) && !isFetching && (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Typography component='div' align='center'>
                        {dataSource?.sourceType != "TABLE" || selectedRowFromOtherTables
                          ? "No Records Available"
                          : `Select Data from ${dataSource?.table?.info?.name} Table`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {(isFetching || hasNextPage) && (
                  <TableRow ref={withInfiniteScroll ? sentryRef : undefined}>
                    <TableCell colSpan={columns.length || 1}>Loading</TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
};
