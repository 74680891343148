import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";

import { IconEdit, IconPlus, IconTrash } from "@tabler/icons";
import { FC, useLayoutEffect, useRef } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import BXModal from "../Modal";

const BXFormTable: FC<any> = props => {
  const { control, inputValidationName, componentData, inputLabel, onLayout = () => {} } = props;
  const { config } = componentData || {};

  const { columns } = config || {};

  const { control: _control } = useForm();

  const { fields, append, update, remove } = useFieldArray({
    control: control || _control,
    name: inputValidationName,
  });
  const myRef = useRef<any>(null);

  useLayoutEffect(() => {
    const height = myRef.current?.offsetHeight;
    const width = myRef.current?.offsetWidth;
    onLayout?.({ width, height });
  }, []);

  const newRow: any = {};
  const editRow: any = {};
  return (
    <>
      {control ? (
        <Controller
          control={control}
          name={inputValidationName!}
          render={({ field: { onChange } }) => (
            <TableContainer>
              <Grid container alignItems={"center"}>
                <Grid item xs>
                  <Typography fontWeight={500}>{inputLabel}</Typography>
                </Grid>
                <Grid item>
                  <BXModal
                    label={"Add Row"}
                    icon={<IconPlus />}
                    buttonProps={{
                      variant: "contained",
                      startIcon: <IconPlus />,
                    }}
                    title={"Add Row"}
                  >
                    {(handleClose: Function) => {
                      return (
                        <Grid container spacing={2}>
                          {columns?.map((column: any) => {
                            return (
                              <Grid item xs={6}>
                                <TextField
                                  variant='outlined'
                                  label={column?.value}
                                  fullWidth
                                  onChange={e => {
                                    newRow[column.key] = e.target.value;
                                  }}
                                />
                              </Grid>
                            );
                          })}
                          <Grid item xs={12} display='flex' justifyContent='center' mt={2}>
                            <Button
                              variant='contained'
                              onClick={() => {
                                append(newRow);
                                handleClose();
                              }}
                            >
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    }}
                  </BXModal>
                </Grid>
              </Grid>
              <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size={"medium"}>
                <TableHead>
                  <TableRow>
                    {!!columns?.length && columns?.map((column: any) => <TableCell key={column.key}>{column.value}</TableCell>)}
                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {!!fields?.length &&
                    fields?.map((row: any, index: any) => {
                      return (
                        <TableRow hover key={row.id || index}>
                          {columns?.map((column: any) => (
                            <TableCell key={column.key}>{row[column?.key]}</TableCell>
                          ))}
                          <TableCell align='right'>
                            <BXModal
                              label={"Edit Row"}
                              icon={<IconEdit />}
                              buttonProps={{
                                variant: "contained",
                                startIcon: <IconEdit />,
                              }}
                              title={"Edit Row"}
                              withoutLabel
                            >
                              {(handleClose: Function) => {
                                return (
                                  <Grid container spacing={2}>
                                    {columns?.map((column: any) => {
                                      editRow[column.key] = row?.[column.key] || "";

                                      return (
                                        <Grid item xs={6}>
                                          <TextField
                                            variant='outlined'
                                            label={column?.value}
                                            fullWidth
                                            defaultValue={row?.[column.key] || ""}
                                            onChange={e => {
                                              editRow[column.key] = e.target.value;
                                            }}
                                          />
                                        </Grid>
                                      );
                                    })}
                                    <Grid item xs={12} display='flex' justifyContent='center' mt={2}>
                                      <Button
                                        variant='contained'
                                        onClick={() => {
                                          update(index, editRow);
                                          handleClose();
                                        }}
                                      >
                                        Save
                                      </Button>
                                    </Grid>
                                  </Grid>
                                );
                              }}
                            </BXModal>
                            <IconButton
                              onClick={() => {
                                remove(index);
                              }}
                              sx={{ ml: 2 }}
                            >
                              <IconTrash color='red' />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        />
      ) : (
        <TableContainer ref={myRef}>
          <Grid container alignItems={"center"}>
            <Grid item xs>
              <Typography fontWeight={500}>{inputLabel}</Typography>
            </Grid>
            <Grid item>
              <BXModal
                label={"Add Row"}
                icon={<IconPlus />}
                buttonProps={{
                  variant: "contained",
                  startIcon: <IconPlus />,
                }}
                title={"Add Row"}
              >
                {(handleClose: Function) => {
                  return (
                    <Grid container spacing={2}>
                      {columns?.map((column: any) => {
                        return (
                          <Grid item xs={6}>
                            <TextField
                              variant='outlined'
                              label={column?.value}
                              fullWidth
                              onChange={e => {
                                newRow[column.key] = e.target.value;
                              }}
                            />
                          </Grid>
                        );
                      })}
                      <Grid item xs={12} display='flex' justifyContent='center' mt={2}>
                        <Button
                          variant='contained'
                          onClick={() => {
                            append(newRow);
                            handleClose();
                          }}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  );
                }}
              </BXModal>
            </Grid>
          </Grid>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size={"medium"}>
            <TableHead>
              <TableRow>
                {!!columns?.length && columns?.map((column: any) => <TableCell key={column.key}>{column.value}</TableCell>)}
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {!!fields?.length &&
                fields?.map((row: any, index: any) => {
                  return (
                    <TableRow hover key={row.id || index}>
                      {columns?.map((column: any) => (
                        <TableCell key={column.key}>{row[column?.key]}</TableCell>
                      ))}
                      <TableCell align='right'>
                        <BXModal
                          label={"Edit Row"}
                          icon={<IconEdit />}
                          buttonProps={{
                            variant: "contained",
                            startIcon: <IconEdit />,
                          }}
                          title={"Edit Row"}
                          withoutLabel
                        >
                          {(handleClose: Function) => {
                            return (
                              <Grid container spacing={2}>
                                {columns?.map((column: any) => {
                                  editRow[column.key] = row?.[column.key] || "";

                                  return (
                                    <Grid item xs={6}>
                                      <TextField
                                        variant='outlined'
                                        label={column?.value}
                                        fullWidth
                                        defaultValue={row?.[column.key] || ""}
                                        onChange={e => {
                                          editRow[column.key] = e.target.value;
                                        }}
                                      />
                                    </Grid>
                                  );
                                })}
                                <Grid item xs={12} display='flex' justifyContent='center' mt={2}>
                                  <Button
                                    variant='contained'
                                    onClick={() => {
                                      update(index, editRow);
                                      handleClose();
                                    }}
                                  >
                                    Save
                                  </Button>
                                </Grid>
                              </Grid>
                            );
                          }}
                        </BXModal>
                        <IconButton
                          onClick={() => {
                            remove(index);
                          }}
                          sx={{ ml: 2 }}
                        >
                          <IconTrash color='red' />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export { BXFormTable };
