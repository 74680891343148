import { IconButton, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import { IconDots } from "@tabler/icons";
import _ from "lodash";
import { FC, useState } from "react";
import { useBXContext } from "src/BXEngine/BXContext";
import { ActionButton, replaceItemPlaceholders } from "src/components/BXUI/DataTable/ActionButton";

export const ItemActions: FC<{
  actions?: any[];
  auth: any;
  selectedRowFromOtherTables: any;
  onStateChange: (newState: string) => void;
  disabled?: boolean;
  item: any;
  views: any;
  userInputsValues?: { [inputName: string]: any };
  isUserInput?: boolean;
  tableId: any;
  onSelectRow?: (object: any) => void;
  __data?: any;
  closeModal?: any;
  parentIds?: any;
}> = ({
  actions: _actions,
  onStateChange,
  disabled,
  item,
  auth,
  selectedRowFromOtherTables,
  userInputsValues,
  isUserInput,
  views,
  tableId,
  onSelectRow,
  closeModal,
  parentIds,
  __data = {},
}) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { palette } = useTheme();
  const { currentApp } = useBXContext();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const actions = _actions?.filter(action => {
    const data = replaceItemPlaceholders(
      action?.condition,
      item,
      selectedRowFromOtherTables,
      userInputsValues,
      isUserInput,
      undefined,
      __data,
      undefined,
      undefined,
      undefined,
      currentApp?.env
    );
    return !(!eval(data) && !_.isEmpty(data));
  });

  if (_.isArray(actions) && !_.isEmpty(actions))
    return (
      <>
        {actions?.slice(0, 3)?.map(action => (
          <ActionButton
            auth={auth}
            item={item}
            disabled={disabled}
            key={action.id}
            action={action}
            onStateChange={onStateChange}
            selectedRowFromOtherTables={selectedRowFromOtherTables}
            userInputsValues={userInputsValues}
            isUserInput={isUserInput}
            views={views}
            onSelectRow={onSelectRow}
            tableId={tableId}
            __data={__data}
            closeModal={closeModal}
            parentIds={parentIds}
            iconButton={!action?.showButtonLabel}
          />
        ))}
        {actions?.length > 3 && (
          <>
            <Box style={{ cursor: "pointer" }} onClick={handleClick}>
              <IconButton
                style={{
                  // backgroundColor: palette.primary.main, padding: 6,
                  marginLeft: 8,
                }}
              >
                <IconDots height={16} width={16} color='currentColor' />
              </IconButton>
            </Box>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box padding={2} justifyContent='center' display='flex' flexDirection={"column"}>
                {actions?.slice(3, actions.length)?.map(action => (
                  <Box mb={0.5}>
                    <ActionButton
                      auth={auth}
                      item={item}
                      disabled={disabled}
                      key={action.id}
                      action={action}
                      onStateChange={data => {
                        handleClose?.();
                        onStateChange(data);
                      }}
                      selectedRowFromOtherTables={selectedRowFromOtherTables}
                      userInputsValues={userInputsValues}
                      isUserInput={isUserInput}
                      views={views}
                      onSelectRow={onSelectRow}
                      tableId={tableId}
                      iconButton={false}
                      fullWidth
                      withBorder={false}
                      variant='outlined'
                      __data={__data}
                      closeModal={closeModal}
                      parentIds={parentIds}
                    />
                  </Box>
                ))}
              </Box>
            </Popover>
          </>
        )}
      </>
    );
  return <></>;
};
