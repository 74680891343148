import { yupResolver } from "@hookform/resolvers/yup";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { IconActivity, IconPlaylistAdd, IconTrashX } from "@tabler/icons";
import axios from "axios";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import ReactMarkdown from "react-markdown";
import CreatePayload from "src/components/BXUI/DataTable/CreatePayload";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { BXIcon } from "src/components/BXUI/Icon";
import BXModal from "src/components/BXUI/Modal";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import { UIElement } from "src/types/UIElement";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { DataSource } from "src/views/pages/BuildX/AppBuilder/components/DataSource";
import { v4 as uuid } from "uuid";
import * as yup from "yup";
import OASSelector from "../../OASSelector";
import UploadInfo from "../components/UploadInfo";
import ViewInfo from "../components/ViewInfo";
import DraggableRow from "./DraggableRow";
import StatusMessages from "./StatusMessages";

type CreateViewFormProps = {
  appId?: string;
  collectionId?: string;
  pageId?: string;
  viewId?: string;
  onSave: any;
  onCancel: Function;
  view?: UIElement;
  id?: string | number;
  tables: UIElement[];
  views: UIElement[] | undefined;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  layout?: string;
};

const CreateViewForm: FC<CreateViewFormProps> = ({
  onCancel = _.noop,
  onSave = _.noop,
  view,
  layout,
  id,
  tables,
  views,
  setIsDirty,
  appId,
  collectionId,
  pageId,
  viewId,
}) => {
  const schema = yup
    .object({
      type: yup.string().required("Field is required"),
      info: yup.object().shape({
        name: yup
          .string()
          .test("name", "View should be unique", name => {
            const result = views?.find(_view => _view?.info?.name == name && _view?.id != view?.id);
            return !result;
          })
          .required("Field is required"),
      }),
      uploadConfig: yup.object().shape({
        uploadAllowedTypes: yup
          .array()
          .test("uploadAllowedTypes", "Field is required", values => (view?.type == "upload" ? !!values?.length : true))
          .required("Field is required"),
        singedUrl: yup.string().when("uploadUrlType", {
          is: (uploadUrlType: any) => uploadUrlType == "aws" && view?.type == "upload",
          then: yup.string().required("Field is required"),
        }),
        customUrl: yup.string().when("uploadUrlType", {
          is: (uploadUrlType: any) => uploadUrlType == "custom" && view?.type == "upload",
          then: yup.string().required("Field is required"),
        }),
      }),
      dataSource: yup.object().shape({
        userInputs: yup.array().of(
          yup.object().shape({
            label: yup.string().required("Field is required"),
            inputDataSource: yup.string().required("Field is required"),
          })
        ),
        apiUrl: yup.string().when("sourceType", {
          is: (sourceType: any) => sourceType !== "API" || view?.type == "upload",
          then: yup.string(),
          otherwise: yup.string().required("Field is required"),
        }),
        tableUrl: yup.string().when("sourceType", {
          is: (sourceType: any) => sourceType != "TABLE",
          then: yup.string(),
          otherwise: yup.string().required("Field is required"),
        }),
        userInputUrl: yup.string().when("sourceType", {
          is: (sourceType: any) => sourceType != "USER INPUT",
          then: yup.string(),
          otherwise: yup.string().required("Field is required"),
        }),
        columnCount: yup.string().when("sourceType", {
          is: (sourceType: any) => view?.type == "form",
          then: yup.string().required("Field is required"),
        }),
      }),
      config: yup.object().shape({
        columns: yup.array().of(
          yup.object().shape({
            name: yup.string().required("Field is required"),
            source: yup.string().required("Field is required"),
          })
        ),
        ...(view?.type != "form-builder" && {
          actions: yup.array().of(
            yup.object().shape({
              label: yup.string().required("Field is required"),
              source: yup.string().when("actionSourceType.type", {
                is: "API",
                then: yup.string().required("Field is required"),
              }),
              actionViewType: yup.string().when("actionSourceType.type", {
                is: (data: any) => data != "API" && layout === "stack" && data != "Download",
                then: yup.string().required("Field is required"),
              }),
              method: yup.string().when("actionSourceType.type", {
                is: "API",
                then: yup.string().required("Field is required"),
              }),
              isDismissibleView: yup.string().when("actionSourceType.type", {
                is: "API",
                then: yup.string().required("Field is required"),
              }),
            })
          ),
        }),
      }),
    })
    .required();

  const [isTemplateError, setIsTemplateError] = useState();
  const [tabValue, setTabValue] = useState(1);

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm<FieldValues>({
    defaultValues: {
      ...view,
      dataSource: {
        ...view?.dataSource,
        apiUrl: view?.dataSource?.apiUrl || view?.dataSource?.url,
        tableUrl: view?.dataSource?.tableUrl || view?.dataSource?.url,
        userInputUrl: view?.dataSource?.userInputUrl || view?.dataSource?.url,
        sourceType: view?.dataSource?.sourceType || "API",
        simple: view?.dataSource?.simple || "{\n}",
        columnCount: view?.dataSource?.columnCount || 3,
        formBuilder: view?.dataSource?.formBuilder?.map(item =>
          item?.config?.isAction
            ? {
                ...item,
                actionConfig: {
                  ...item?.actionConfig,
                  actionSourceType: item?.actionConfig?.actionSourceType?.id
                    ? item?.actionConfig?.actionSourceType
                    : { id: -1, type: "API" },
                  showModal: item?.actionConfig?.showModal || "No",
                  modalSize: item?.actionConfig?.modalSize || "lg",
                  actionViewType: item?.actionConfig?.actionViewType || "Modal",
                  isDismissibleView: item?.actionConfig?.isDismissibleView || "Yes",
                  downloadType: "directUrl",
                },
              }
            : item
        ),
      },
      config: {
        ...view?.config,
        actions: view?.config?.actions?.map((item: any) => ({
          ...item,
          actionSourceType: item.actionSourceType?.id ? item.actionSourceType : { id: -1, type: "API" },
          showModal: item?.showModal || "No",
          modalSize: item?.modalSize || "lg",
          actionViewType: item?.actionViewType || "Modal",
          isDismissibleView: item?.isDismissibleView || "Yes",
          downloadType: "directUrl",
        })),
      },
      info: {
        ...view?.info,
        visibility: view?.info?.visibility || "Visible",
        showApiMode: view?.info?.showApiMode || "Visible",
        paginationMode: view?.info?.paginationMode || "Pagination",
      },
      uploadConfig: {
        ...view?.uploadConfig,
        uploadAllowedTypes: view?.uploadConfig?.uploadAllowedTypes || [],
        uploadUrlType: view?.uploadConfig?.uploadUrlType || "aws",
        isInput: view?.uploadConfig?.isInput || "No",
        fileNumber: view?.uploadConfig?.fileNumber || 1,
      },
    },
    reValidateMode: "onChange",
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  useCallbackPrompt(isDirty);

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onSaveData = async (values: any, openBuilder?: boolean) => {
    const data = _.cloneDeep(values);

    if (values.dataSource?.sourceType === "API") {
      values.dataSource.url = values.dataSource.apiUrl;
    } else if (values.dataSource?.sourceType === "TABLE") {
      values.dataSource.url = values.dataSource.tableUrl;
    } else if (values.dataSource?.sourceType === "USER INPUT") {
      values.dataSource.url = values.dataSource.userInputUrl;
    }

    if (data?.type === "form" && data?.dataSource?.template && !isTemplateError) {
      const componentNames: any = [];
      const generateForm = (template: any, path = "") => {
        if (_.isObject(template) as any) {
          _.forOwn(template, (value, key) => {
            if (_.isArray(value)) {
              value?.forEach((item, index) => {
                if (_.isObject(item) as any) {
                  generateForm(value, path);
                }
              });
            } else if (_.isObject(value)) {
              generateForm(value, path);
            } else {
              if (value?.[0] === "{" && value?.[value?.length - 1] === "}") {
                const newValue = value.substring(1, value.length - 1);
                const options = newValue.split(",");
                const viewName = options?.[4];
                if (viewName) {
                  componentNames.push(viewName);
                }
              }
            }
          });
        }
      };

      let code = {};
      try {
        code = JSON.parse(data?.dataSource?.template);
      } catch (e) {}

      generateForm(code);
      const templateComponentIds: any = [];
      const names = componentNames?.join(",");
      if (names) {
        const { data } = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/api/admin/component/list?names=${names}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });
        data?.items?.forEach((item: any) => {
          templateComponentIds.push(item?.id);
        });
      }
      values.dataSource.templateComponentIds = templateComponentIds;
      return onSave(values);
    }

    if (values.dataSource?.sourceType === "API") {
      values.dataSource.url = values.dataSource.apiUrl;
    } else if (values.dataSource?.sourceType === "TABLE") {
      values.dataSource.url = values.dataSource.tableUrl;
    } else if (values.dataSource?.sourceType === "USER INPUT") {
      values.dataSource.url = values.dataSource.userInputUrl;
    }
    onSave(values, openBuilder);
  };

  const handleBuilderClick = () => {
    handleSubmit((values: any) => onSaveData(values, true))();
  };

  const modalTabs = [
    {
      name: "info",
      label: "Info",
      condition: view?.type != "upload",
      value: 1,
    },
    {
      name: "dataSource",
      label: "Data source",
      condition: view?.type != "upload",
      value: 2,
    },
    {
      name: "columns",
      label: "Columns",
      condition: view?.type == "data-table",
      value: 3,
    },
    {
      name: "actions",
      label: "Actions",
      condition: view?.type != "upload" && view?.type != "api",
      value: 4,
    },
    {
      name: "reorder",
      label: "Reorder",
      condition: view?.type == "data-table",
      value: 5,
    },
    {
      name: "help",
      label: "Help",
      condition: view?.type != "api",
      value: 6,
    },
  ];

  return (
    <Box component='form' noValidate autoComplete='off'>
      {view?.type != "upload" && (
        <Box sx={{ bgcolor: "background.paper" }}>
          <Tabs value={tabValue} onChange={handleChange} textColor='primary' indicatorColor='primary' style={{ textTransform: "none" }}>
            {view?.type != "upload" &&
              modalTabs.map(
                ({ value, label, condition, name }) =>
                  condition && (
                    <Tab
                      label={
                        <>
                          {(!!errors?.[name] || !!(errors as any)?.config?.[name]) && (
                            <Badge color='error' variant='dot' style={{ position: "absolute", top: 10, right: 10 }} />
                          )}
                          <Typography fontWeight={"bold"}>{label}</Typography>
                        </>
                      }
                      sx={{ textTransform: "none" }}
                      value={value}
                    />
                  )
              )}
          </Tabs>
        </Box>
      )}

      <Grid container spacing={3}>
        {tabValue == 1 && (
          <>
            <Grid item xs={12}>
              <ViewInfo control={control} errors={errors} viewType={view?.type} watch={watch} />
            </Grid>
            {view?.type == "upload" && (
              <Grid item xs={12}>
                <UploadInfo
                  control={control}
                  errors={errors}
                  viewType={view?.type}
                  setValue={setValue}
                  getValues={getValues}
                  watch={watch}
                />
              </Grid>
            )}
          </>
        )}
        {view?.type !== "upload" && tabValue == 2 && (
          <Grid item xs={12}>
            <DataSource
              tables={tables?.filter(item => item?.id != view?.id)}
              setValue={setValue}
              value={getValues("dataSource")}
              control={control}
              watch={watch}
              errors={errors}
              views={views}
              view={view}
              viewType={view?.type}
              setIsTemplateError={setIsTemplateError}
              onBuilderClick={handleBuilderClick}
            />
          </Grid>
        )}
        {view?.type != "api" && (
          <Grid item xs={12}>
            <ViewConfigEditor
              views={views}
              layout={layout}
              watch={watch}
              view={view}
              setValue={setValue}
              getValues={getValues}
              control={control}
              errors={errors}
              setIsDirty={setIsDirty}
              tabValue={tabValue}
              reset={reset}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
            <Button
              onClick={
                !isTemplateError
                  ? handleSubmit((values: any) => onSaveData(values))
                  : () => {
                      enqueueSnackbarRef?.(isTemplateError, {
                        variant: "error",
                        anchorOrigin: {
                          horizontal: "right",
                          vertical: "bottom",
                        },
                      });
                    }
              }
              variant={"contained"}
              aria-label={"save"}
            >
              Save
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Box>
  );
};

type ViewConfigEditorProps = {
  view?: UIElement;
  watch: any;
  control: any;
  errors: any;
  setValue: any;
  getValues: any;
  views: UIElement[] | undefined;
  layout?: string;
  setIsDirty?: any;
  tabValue?: any;
  reset: any;
};
export const getHeadersValue = (headers: any) => {
  if (!headers) return "";
};

const dataTypes = [
  "String",
  "Date",
  "Date Time",
  "Boolean",
  "Image",
  "Video",
  "Audio",
  "HyperLink",
  // , "Component"
];

const ViewConfigEditor: FC<ViewConfigEditorProps> = ({
  view,
  control,
  errors,
  layout,
  setValue,
  getValues,
  watch,
  views,
  setIsDirty,
  tabValue,
  reset,
}) => {
  const [actionTabValue, setActionTabValue] = useState("api");

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "config.columns",
  });

  const {
    fields: actions,
    append: appendAction,
    remove: removeAction,
    move: moveAction,
  } = useFieldArray({
    control,
    name: view?.type == "form-builder" ? "dataSource.formBuilder" : "config.actions",
  });

  const data = views?.filter(item => item?.id != view?.id);
  const actionSourceTypes = [
    { id: -1, type: "API" },
    { id: -2, type: "Download" },
  ];

  data?.forEach((item: any) => actionSourceTypes.push(item));

  const moveTableColumnElement = (dragIndex: any, hoverIndex: number) => {
    move(dragIndex, hoverIndex);
  };

  const moveTableActionElement = (dragIndex: any, hoverIndex: number) => {
    moveAction(dragIndex, hoverIndex);
  };

  return (
    <Grid container spacing={3}>
      {view?.type === "data-table" && tabValue == 3 && (
        <Grid item style={{ width: "100%" }}>
          <Card>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Column Label</TableCell>
                      <TableCell>Type</TableCell>
                      {/* <TableCell>Components</TableCell> */}
                      <TableCell>Data Source</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.isEmpty(fields) && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Typography textAlign={"center"}>No columns</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {fields?.map((col: any, index: number) => (
                      <DraggableRow
                        component={TableRow}
                        key={col?.id}
                        id={col.id}
                        index={index}
                        name='table-columns'
                        moveElement={moveTableColumnElement}
                      >
                        <TableCell>{index + 1}.</TableCell>
                        <TableCell width={250}>
                          <BXInput
                            label={"Label"}
                            name={`config.columns[${index}].name`}
                            control={control}
                            error={errors?.config?.columns?.[index]?.name}
                            draggable
                            onDragStart={e => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          />
                        </TableCell>

                        <TableCell width={250}>
                          <FormControl fullWidth>
                            <BXInput
                              name={`config.columns[${index}].type`}
                              control={control}
                              error={errors?.config?.columns?.[index]?.type}
                              select
                              label={"Type"}
                              id='demo-simple-select'
                            >
                              {dataTypes?.map(item => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </BXInput>
                          </FormControl>
                        </TableCell>
                        {/* <TableCell width={250}>
                          <FormControl fullWidth>
                            <BXInput
                              name={`config.columns[${index}].componentId`}
                              control={control}
                              error={errors?.config?.columns?.[index]?.componentId}
                              select
                              label={"Components"}
                            >
                              {dataTypes?.map(item => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </BXInput>
                          </FormControl>
                        </TableCell> */}

                        <TableCell>
                          <BXInput
                            label={"Source"}
                            name={`config.columns[${index}].source`}
                            control={control}
                            error={errors?.config?.columns?.[index]?.source}
                            draggable
                            onDragStart={e => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {watch(`config.columns[${index}].type`) === "String" && (
                            <BXModal
                              label={"Details"}
                              buttonProps={{
                                variant: "outlined",
                              }}
                              onClose={() => {
                                setActionTabValue("api");
                              }}
                              title={"Details"}
                            >
                              {(handleClose: Function) => {
                                return (
                                  <Grid container margin={2}>
                                    <Grid item>
                                      <BXSwitch
                                        name={`config.columns[${index}].allowWrap`}
                                        control={control}
                                        error={errors?.config?.columns?.[index]?.allowWrap}
                                        label={"Allow Wrap"}
                                      />
                                    </Grid>
                                  </Grid>
                                );
                              }}
                            </BXModal>
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              const newData = getValues();
                              newData.config.columns = newData.config.columns?.filter((a: any, _index: number) => _index != index);
                              reset(newData);
                            }}
                          >
                            <IconTrashX />
                          </IconButton>
                        </TableCell>
                      </DraggableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <Grid container justifyContent={"center"} alignItems='center' mb={2}>
              <Button variant='outlined' startIcon={<IconPlaylistAdd />} onClick={() => append({ id: uuid(), type: "String" })}>
                Add Column
              </Button>
            </Grid>
          </Card>
        </Grid>
      )}

      {view?.type !== "upload" && tabValue == 4 && (
        <Grid item overflow={"hidden"} width='100%'>
          <Card>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Action name</TableCell>
                      <TableCell width={300}>Source</TableCell>
                      {/* {layout === "stack" && <TableCell>Navigation</TableCell>} */}

                      <TableCell>Icon</TableCell>
                      {/* <TableCell width={300}>URL</TableCell> */}
                      {/* <TableCell>Method</TableCell> */}

                      {/* {view?.type == "card-list" && <TableCell>Highlight value</TableCell>} */}

                      {/* <TableCell>Modal Size</TableCell> */}
                      {/* <TableCell>Dismissible View</TableCell> */}
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.isEmpty(actions) && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Typography textAlign={"center"}>No action</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {actions?.map((action: any, index: number) => {
                      if (!action?.config?.isAction && view?.type === "form-builder") return;

                      const prefixActionPath =
                        view?.type === "form-builder" ? `dataSource.formBuilder[${index}].actionConfig` : `config.actions[${index}]`;

                      const prefixActionErrorPath =
                        view?.type === "form-builder"
                          ? errors?.dataSource?.formBuilder?.[index]?.actionConfig
                          : errors?.config?.actions?.[index];

                      const ParentComponent: any = view?.type != "form-builder" ? DraggableRow : TableRow;
                      const parentComponentProps =
                        view?.type != "form-builder"
                          ? {
                              component: TableRow,
                              key: action?.id,
                              id: action.id,
                              index: index,
                              name: "table-columns",
                              moveElement: moveTableActionElement,
                            }
                          : { key: action?.id, id: action.id };

                      return (
                        <ParentComponent {...parentComponentProps}>
                          <TableCell>{index + 1}.</TableCell>
                          <TableCell style={{ minWidth: 200 }}>
                            <BXInput
                              name={
                                view?.type === "form-builder"
                                  ? `dataSource.formBuilder[${index}].props.children`
                                  : `${prefixActionPath}.label`
                              }
                              disabled={view?.type === "form-builder"}
                              control={control}
                              error={prefixActionErrorPath?.label}
                              label={"Label"}
                              draggable
                              onDragStart={e => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            />
                          </TableCell>
                          <TableCell style={{ minWidth: 150 }}>
                            <FormControl fullWidth>
                              <BXInput
                                name={`${prefixActionPath}.actionSourceType.id`}
                                control={control}
                                error={prefixActionErrorPath?.actionSourceType?.id}
                                select
                                label={"Source"}
                                id='demo-simple-select'
                                onChange={event => {
                                  setValue(
                                    `${prefixActionPath}.actionSourceType`,
                                    actionSourceTypes?.find((view: any) => view?.id == event?.target.value)
                                  );
                                }}
                              >
                                {actionSourceTypes?.map((item: any) => (
                                  <MenuItem key={item?.id} value={item?.id}>
                                    {item?.info?.name || item?.type}
                                  </MenuItem>
                                ))}
                              </BXInput>
                            </FormControl>
                          </TableCell>
                          <TableCell style={{ minWidth: 200 }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <div>
                                <FormControl fullWidth>
                                  <BXInput
                                    name={`${prefixActionPath}.icon`}
                                    control={control}
                                    error={prefixActionErrorPath?.icon}
                                    label={"Icon"}
                                    variant='outlined'
                                    fullWidth
                                    draggable
                                    onDragStart={e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }}
                                  />
                                </FormControl>
                              </div>
                              <div style={{ marginLeft: 5 }}>
                                <BXIcon icon={watch(`${prefixActionPath}.icon`)} />
                              </div>
                            </div>
                          </TableCell>
                          <TableCell width={50}>
                            <TabContext value={actionTabValue}>
                              <BXModal
                                label={"Details"}
                                buttonProps={{
                                  variant: "outlined",
                                  style: !!prefixActionErrorPath
                                    ? {
                                        borderColor: "red",
                                      }
                                    : {},
                                }}
                                onClose={() => {
                                  setActionTabValue("api");
                                }}
                                title={"Details"}
                              >
                                {(handleClose: Function) => {
                                  return (
                                    <>
                                      {watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
                                        <TabList
                                          onChange={(event, newValue) => {
                                            setActionTabValue(newValue);
                                          }}
                                        >
                                          <Tab sx={{ textTransform: "none" }} label='API' value='api' />
                                          <Tab sx={{ textTransform: "none" }} label='Payload' value='payload' />
                                          <Tab sx={{ textTransform: "none" }} label='Messages' value='messages' />
                                        </TabList>
                                      )}
                                      <TabPanel sx={{ width: "100%" }} value='api'>
                                        <Grid container spacing={2.5}>
                                          {layout === "stack" &&
                                            watch(`${prefixActionPath}.actionSourceType`)?.type != "API" &&
                                            watch(`${prefixActionPath}.actionSourceType`)?.type != "Download" && (
                                              <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                  <BXInput
                                                    name={`${prefixActionPath}.actionViewType`}
                                                    control={control}
                                                    error={prefixActionErrorPath?.actionViewType}
                                                    select
                                                    label={"Navigation"}
                                                  >
                                                    {["Modal", "Stack"]?.map((item: any) => (
                                                      <MenuItem key={item} value={item}>
                                                        {item}
                                                      </MenuItem>
                                                    ))}
                                                  </BXInput>
                                                </FormControl>
                                              </Grid>
                                            )}

                                          {watch(`${prefixActionPath}.actionSourceType`)?.type == "Download" && (
                                            <Tabs
                                              value={watch(`${prefixActionPath}.downloadType`)}
                                              onChange={(_, newValue) => {
                                                setValue(`${prefixActionPath}.downloadType`, newValue);
                                              }}
                                              aria-label='basic tabs example'
                                            >
                                              <Tab sx={{ textTransform: "none" }} label='Direct Url' value='directUrl' />
                                              <Tab sx={{ textTransform: "none" }} label='API' value='api' />
                                            </Tabs>
                                          )}

                                          {(watch(`${prefixActionPath}.actionSourceType`)?.type == "API" ||
                                            (watch(`${prefixActionPath}.actionSourceType`)?.type == "Download" &&
                                              watch(`${prefixActionPath}.downloadType`) === "directUrl")) && (
                                            <Grid item xs={12}>
                                              <BXInput
                                                label={"Url"}
                                                fullWidth
                                                multiline
                                                name={`${prefixActionPath}.source`}
                                                control={control}
                                                error={prefixActionErrorPath?.source}
                                                InputProps={
                                                  process.env.REACT_APP_WITH_OAS == "true"
                                                    ? {
                                                        endAdornment: (
                                                          <InputAdornment position='start'>
                                                            <OASSelector
                                                              swaggerProps={{
                                                                type: "action",
                                                                isForm: view?.type === "form",
                                                                template: watch("dataSource.template"),
                                                                columns: watch("config.columns"),
                                                                onSuccess: (values: any) => {
                                                                  setValue(`${prefixActionPath}`, {
                                                                    ...getValues(`${prefixActionPath}`),
                                                                    ...values.action,
                                                                    ur: values.url,
                                                                  });
                                                                },
                                                              }}
                                                            />
                                                          </InputAdornment>
                                                        ),
                                                      }
                                                    : undefined
                                                }
                                              />
                                            </Grid>
                                          )}

                                          {watch(`${prefixActionPath}.downloadType`) === "api" && (
                                            <Grid item container spacing={2}>
                                              <Grid item xs={12}>
                                                <BXInput
                                                  label={"Endpoint"}
                                                  name={`${prefixActionPath}.downloadEndpoint`}
                                                  control={control}
                                                  error={prefixActionErrorPath?.downloadEndpoint}
                                                />
                                              </Grid>
                                              <Grid item xs={12}>
                                                <BXInput
                                                  label={"Key"}
                                                  name={`${prefixActionPath}.downloadKey`}
                                                  control={control}
                                                  error={prefixActionErrorPath?.downloadKey}
                                                />
                                              </Grid>
                                            </Grid>
                                          )}
                                          {watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
                                            <Grid item xs={12}>
                                              <BXInput
                                                label={"Method"}
                                                name={`${prefixActionPath}.method`}
                                                select
                                                control={control}
                                                error={prefixActionErrorPath?.method}
                                              >
                                                {["GET", "POST", "PUT", "PATCH", "DELETE"]?.map(item => (
                                                  <MenuItem key={item} value={item}>
                                                    {item}
                                                  </MenuItem>
                                                ))}
                                              </BXInput>
                                            </Grid>
                                          )}
                                          {watch(`${prefixActionPath}.actionSourceType`)?.type == "Download" && (
                                            <Grid item xs={4}>
                                              <BXSwitch
                                                name={`${prefixActionPath}.openImage`}
                                                control={control}
                                                error={prefixActionErrorPath?.openImage}
                                                label={"Open Image"}
                                              />
                                            </Grid>
                                          )}
                                          {view?.type != "form" &&
                                            view?.type != "form-builder" &&
                                            watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
                                              <Grid item xs={12}>
                                                <BXInput
                                                  label={"Condition"}
                                                  fullWidth
                                                  name={`${prefixActionPath}.condition`}
                                                  control={control}
                                                  error={prefixActionErrorPath?.condition}
                                                />
                                              </Grid>
                                            )}

                                          {(view?.type == "card-list" || view?.type == "image-grid") &&
                                            watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
                                              <Grid item xs={12}>
                                                <BXInput
                                                  label={"Highlight Value"}
                                                  fullWidth
                                                  name={`${prefixActionPath}.highlightedValue`}
                                                  control={control}
                                                  error={prefixActionErrorPath?.highlightedValue}
                                                />
                                              </Grid>
                                            )}
                                          {view?.type != "form" &&
                                            view?.type != "form-builder" &&
                                            watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
                                              <Grid item xs={12}>
                                                <BXInput
                                                  label={"Show Modal"}
                                                  name={`${prefixActionPath}.showModal`}
                                                  control={control}
                                                  select
                                                  error={prefixActionErrorPath?.showModal}
                                                >
                                                  {["Yes", "No"]?.map(item => (
                                                    <MenuItem key={item} value={item}>
                                                      {item}
                                                    </MenuItem>
                                                  ))}
                                                </BXInput>
                                              </Grid>
                                            )}

                                          {watch(`${prefixActionPath}.actionSourceType`)?.type != "API" &&
                                            watch(`${prefixActionPath}.actionSourceType`)?.type != "Download" &&
                                            view?.type != "form" &&
                                            view?.type != "form-builder" &&
                                            watch(`${prefixActionPath}.actionViewType`) != "Stack" && (
                                              <Grid item xs={12}>
                                                <BXInput
                                                  label={"Modal Size"}
                                                  name={`${prefixActionPath}.modalSize`}
                                                  control={control}
                                                  select
                                                  error={prefixActionErrorPath?.modalSize}
                                                >
                                                  {["xs", "xl", "sm", "md", "lg"]?.map(item => (
                                                    <MenuItem key={item} value={item}>
                                                      {item}
                                                    </MenuItem>
                                                  ))}
                                                </BXInput>
                                              </Grid>
                                            )}

                                          {watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
                                            <Grid item xs={12}>
                                              <BXInput
                                                label={"Dismissible View"}
                                                name={`${prefixActionPath}.isDismissibleView`}
                                                control={control}
                                                select
                                                error={prefixActionErrorPath?.isDismissibleView}
                                              >
                                                {["Yes", "No"]?.map(item => (
                                                  <MenuItem key={item} value={item}>
                                                    {item}
                                                  </MenuItem>
                                                ))}
                                              </BXInput>
                                            </Grid>
                                          )}
                                          {view?.type != "form" && view?.type != "form-builder" && (
                                            <>
                                              <Grid item xs={12}>
                                                <FormControl>
                                                  <BXSwitch
                                                    name={`${prefixActionPath}.showButtonLabel`}
                                                    control={control}
                                                    label={"Show Button Label"}
                                                    error={(errors as any)?.config?.actions?.[index]?.showButtonLabel}
                                                  />
                                                </FormControl>
                                              </Grid>
                                              <Grid item xs={12}>
                                                <Typography>Level</Typography>
                                                <FormControl>
                                                  <Box display='flex' alignItems={"center"}>
                                                    <Switch
                                                      defaultChecked={!!action?.isGlobal}
                                                      onChange={(_, value) => {
                                                        setValue(`${prefixActionPath}.isGlobal`, value);
                                                        setValue(`${prefixActionPath}.isItem`, !value);
                                                      }}
                                                    />
                                                    <Typography>{watch(`${prefixActionPath}.isGlobal`) ? "Global" : "Row"}</Typography>
                                                  </Box>
                                                </FormControl>
                                              </Grid>
                                            </>
                                          )}
                                        </Grid>
                                      </TabPanel>
                                      <TabPanel sx={{ width: "100%" }} value='payload'>
                                        {watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
                                          <Grid item xs={12}>
                                            <CreatePayload
                                              payload={{
                                                headers: getValues(`${prefixActionPath}.headers`),
                                                body: getValues(`${prefixActionPath}.body`),
                                              }}
                                              onChange={(payload: any) => {
                                                setValue(`${prefixActionPath}.headers`, payload.headers);
                                                setValue(`${prefixActionPath}.body`, payload.body);
                                              }}
                                              onSave={(payload: any) => {
                                                setValue(`${prefixActionPath}.headers`, payload.headers);
                                                setValue(`${prefixActionPath}.body`, payload.body);
                                                setIsDirty(true);
                                                handleClose();
                                              }}
                                              defaultTab={"JSON"}
                                              label={"Done"}
                                              onClose={() => handleClose()}
                                            />
                                          </Grid>
                                        )}
                                      </TabPanel>
                                      <TabPanel sx={{ width: "100%" }} value='messages'>
                                        <Grid container spacing={2.5}>
                                          <StatusMessages actionIndex={index} control={control} namePrefix={prefixActionPath} />
                                          <Grid item xs={12}>
                                            <Divider />
                                          </Grid>
                                          <Grid item xs={12}>
                                            <BXSwitch
                                              name={`${prefixActionPath}.dialog.enabled`}
                                              control={control}
                                              label={"Confirmation"}
                                              error={(errors as any)?.config?.actions?.[index]?.dialog?.enabled}
                                            />
                                          </Grid>
                                          <Grid item xs={12}>
                                            <BXInput
                                              label={"Message"}
                                              variant='outlined'
                                              multiline
                                              control={control}
                                              name={`${prefixActionPath}.dialog.message`}
                                              error={(errors as any)?.config?.actions?.[index]?.dialog?.message}
                                              disabled={!watch(`${prefixActionPath}.dialog.enabled`)}
                                            />
                                          </Grid>
                                        </Grid>
                                      </TabPanel>
                                    </>
                                  );
                                }}
                              </BXModal>
                            </TabContext>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                const newData = getValues();
                                newData.config.actions = newData.config.actions?.filter((a: any, _index: number) => _index != index);
                                reset(newData);
                              }}
                            >
                              <IconTrashX />
                            </IconButton>
                          </TableCell>
                        </ParentComponent>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            {view?.type != "form-builder" && (
              <Grid container justifyContent={"center"} alignItems='center' mb={2}>
                <Button
                  variant='outlined'
                  startIcon={<IconActivity />}
                  onClick={() => {
                    appendAction({
                      id: uuid(),
                      type: "API",
                      showModal: "No",
                      isItem: true,
                      isDismissibleView: "Yes",
                      actionSourceType: { id: -1, type: "API" },
                      downloadType: "directUrl",
                    });
                  }}
                >
                  Add Action
                </Button>
              </Grid>
            )}
          </Card>
        </Grid>
      )}
      {tabValue === 5 && (
        <Grid item container spacing={2} margin={2}>
          <Grid item xs={12}>
            <BXSwitch label={"Enable"} name={`config.order.enabled`} control={control} error={errors?.config?.order?.enabled} />
          </Grid>
          <Grid item xs={6}>
            <BXInput label={"Endpoint"} name={`config.order.endpoint`} control={control} error={errors?.config?.order?.endpoint} />
          </Grid>

          <Grid item xs={6}>
            <BXInput label={"Method"} name={`config.order.method`} select control={control} error={errors?.config?.order?.method}>
              {["GET", "POST", "PUT", "PATCH", "DELETE"]?.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </BXInput>
          </Grid>
        </Grid>
      )}

      {tabValue === 6 && (
        <Grid item>
          <ReactMarkdown>
            {`
      1- If you want to get value from current thing then use {name}\n
      2- If you want to get value from another or selected thing then use {#.name}\n
      3- If you want to get value from form template then use {$.name}\n
      4- This is the definition of the form field:

      {
        "fieldName": "{Field Label, Field Type, Required || Blank, Value, Component Name || View Name, Default Value}"
      }
      
      - Position 0: Field Variable Name - This is used to reference the form field object and extract the value of the form field.
      - Position 1: Field Label - String describing the label that will be shown for the field
      - Position 2: Field Type - Supported types are []
      - Position 3: Required or Optional or Blank
      - Position 4: Value - Value of the field. Can be retrieved from an API, or input by user.
      - Position 5: Component Name or View Name - Name of custom component that is defined under "Manage Components". Supported views are "Upload View".
      - Position 6: Default Value - Value is set to default value if it is not specified.`}
          </ReactMarkdown>
        </Grid>
      )}
    </Grid>
  );
};

export { CreateViewForm };
