import Grid from "@mui/material/Grid";
import { TextFieldProps } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FC, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { BXInput } from "src/components/BXUI/FormControls/Input";
import { BXIcon } from "src/components/BXUI/Icon";

type BXIconPickerProps = {
  label: string;
  control: Control<any>;
  name: string;
  defaultValue?: any;
  id?: string;
  error?: any;
} & TextFieldProps;

export const BXIconPicker: FC<BXIconPickerProps> = ({ name, control, label, id, defaultValue, error, ...rest }) => {
  const [icon, setIcon] = useState<string | undefined>(undefined);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Grid container spacing={1}>
          <Grid item xs={11}>
            <BXInput
              name={name}
              fullWidth
              control={control}
              label={label || "Icon"}
              variant='outlined'
              id={"bx-page-create-icon-input"}
              error={error}
              onChange={e => {
                setIcon(e.target.value);
                onChange(e);
              }}
            />
            <Typography variant={"caption"}>Enter logo URL to view as image</Typography>
          </Grid>
          <Grid item alignItems={"center"} mt={1}>
            <BXIcon icon={icon || value} />
          </Grid>
        </Grid>
      )}
    />
  );
};
