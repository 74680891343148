import { Button, DialogActions, Grid, IconButton } from "@mui/material";
import { IconTrashX } from "@tabler/icons";
import { BXInput } from "src/components/BXUI/FormControls";
import { v4 as uuid } from "uuid";

const CreateDynamicFields = (props: any) => {
  const { fields, remove, append, handleSubmit, control, onChange, watch, name, keyLabel, valueLabel } = props;

  return (
    <>
      <Grid item xs={12}>
        {fields?.map((item: any, index: number) => {
          return (
            <Grid container spacing={2} mb={2} key={item.id}>
              <Grid item xs>
                <BXInput
                  size='small'
                  fullWidth
                  label={keyLabel || "Key"}
                  name={`${name || "config.variables"}[${index}].key`}
                  control={control}
                  onChange={() => {
                    onChange?.(watch()?.config?.variables);
                  }}
                />
              </Grid>
              <Grid item xs>
                <BXInput
                  size='small'
                  fullWidth
                  label={valueLabel || "Value"}
                  name={`${name || "config.variables"}[${index}].value`}
                  control={control}
                  onChange={() => {
                    onChange?.(watch()?.config?.variables);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    remove(index);
                    onChange?.(watch()?.config?.variables);
                  }}
                >
                  <IconTrashX color='red' />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
        <Button
          variant='outlined'
          onClick={() => {
            append({ id: uuid(), key: "", value: "" });
            onChange?.(watch()?.config?.variables);
          }}
        >
          Add row
        </Button>
      </Grid>
      {handleSubmit && (
        <Grid item xs={12}>
          <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
            <Button onClick={handleSubmit} variant={"contained"} aria-label={"save"}>
              Save
            </Button>
          </DialogActions>
        </Grid>
      )}
    </>
  );
};

export default CreateDynamicFields;
