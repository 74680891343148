export enum ComponentItemType {
  TextField = "TextField",
  Button = "Button",
  Typography = "Typography",
  Divider = "Divider",
  Switch = "Switch",
  CustomCheckbox = "CustomCheckbox",
  CustomAutocomplete = "CustomAutocomplete",
  CustomImage = "CustomImage",
  CustomMediaCard = "CustomMediaCard",
  CustomFileUpload = "CustomFileUpload",
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
