import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Box, Button, DialogActions, FormControl, Grid, TextField } from "@mui/material";
import axios from "axios";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { BXInput } from "src/components/BXUI/FormControls";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import * as yup from "yup";

type CreateUserFormProps = {
  onSave: SubmitHandler<FieldValues>;
  onCancel?: Function;
  editing?: boolean;
  user?: any;
  height?: string | number;
  withoutPassword?: boolean;
  onlyPassword?: boolean;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateUserForm: FC<CreateUserFormProps> = ({
  onCancel = _.noop,
  onSave = _.noop,
  user,
  editing = false,
  height,
  withoutPassword = false,
  onlyPassword = false,
  setIsDirty,
}) => {
  const schema = yup
    .object({
      name: !onlyPassword ? yup.string().required() : yup.string(),
      handle: !onlyPassword ? yup.string().required() : yup.string(),
      email: !onlyPassword ? yup.string().email("Invalid email format").required("Mail is required") : yup.string(),
      password: onlyPassword ? yup.string().required() : yup.string(),
      confirmPassword: onlyPassword
        ? yup
            .string()
            .required()
            .oneOf([yup.ref("password"), null], "Passwords must match")
        : yup.string(),
    })
    .required();

  const defaultValues = onlyPassword
    ? { id: user?.id, password: "" }
    : { id: user?.id, name: user?.name, handle: user?.handle, email: user?.email, roles: user?.roles };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<FieldValues>({
    defaultValues: editing
      ? defaultValues
      : {
          roles: [
            {
              id: "USER",
              name: "USER",
            },
          ],
        },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const [roles, setRoles] = useState([]);

  useCallbackPrompt(isDirty);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_HOST_API_KEY + "/api/admin/role", {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      })
      .then(res => {
        setRoles(res.data);
      });
    setIsDirty?.(isDirty);
  }, [isDirty]);

  return (
    <Box component='form' noValidate autoComplete='off' flex={1} height={height}>
      <Grid container spacing={3} height={height}>
        {!onlyPassword ? (
          <>
            <Grid item xs={12}>
              <BXInput
                name={"name"}
                control={control}
                label='Full name'
                variant='outlined'
                error={errors.name}
                id={"bx-user-create-name-input"}
              />
            </Grid>
            <Grid item xs={12}>
              <BXInput
                name={"handle"}
                control={control}
                label='Username'
                variant='outlined'
                error={errors.handle}
                id={"bx-user-create-handle-input"}
              />
            </Grid>

            <Grid item xs={12}>
              <BXInput
                name={"email"}
                control={control}
                label='Email'
                variant='outlined'
                error={errors.email}
                id={"bx-user-create-form-input"}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"roles"}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <Autocomplete
                      options={roles}
                      multiple
                      defaultValue={value}
                      getOptionLabel={(option: any) => option.name}
                      filterSelectedOptions
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option.name == value.name;
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={"Role"}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            {!withoutPassword && (
              <Grid item xs={12}>
                <BXInput
                  name={"password"}
                  type={"password"}
                  control={control}
                  label='Password'
                  variant='outlined'
                  error={errors.password}
                  id={"bx-user-create-password-input"}
                />
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <BXInput
                name={"password"}
                type={"password"}
                control={control}
                label='New Password'
                variant='outlined'
                error={errors.password}
                id={"bx-user-create-password-input"}
              />
            </Grid>
            <Grid item xs={12}>
              <BXInput
                name={"confirmPassword"}
                type={"password"}
                control={control}
                label='Confirm Password'
                variant='outlined'
                error={errors.confirmPassword}
                id={"bx-user-create-password-input"}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
            <Button onClick={handleSubmit(onSave)} variant={"contained"} aria-label={"save"}>
              Save
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Box>
  );
};
