import { ButtonProps, CardMediaProps, DividerProps, SwitchProps, TextFieldProps, TypographyProps } from "@mui/material";
import type { CustomCheckboxProps } from "./components/CustomCheckbox";
import { CustomFileUploadProps } from "./components/CustomFileUpload";
import { CustomImageProps } from "./components/CustomImage";
import { ComponentItemType } from "./types";

export const stepperCount = 10;

export const layoutBreakPoints = [
  { id: "xl", title: "Xl", width: "100%" },
  { id: "lg", title: "Lg", width: "85%" },
  { id: "md", title: "Md", width: "70%" },
  { id: "sm", title: "Sm", width: "60%" },
  { id: "xs", title: "Xs", width: "40%" },
];
export const typographyComponents = [
  { id: "span", title: "span" },
  { id: "strong", title: "strong" },
  { id: "h1", title: "h1" },
  { id: "h2", title: "h2" },
  { id: "h3", title: "h3" },
  { id: "h4", title: "h4" },
  { id: "h5", title: "h5" },
  { id: "h6", title: "h6" },
];

export const components = [
  {
    type: ComponentItemType.TextField,
    props: {
      label: "Text Field",
      defaultValue: "",
      autoComplete: "off",
      placeholder: "Placeholder",
      InputProps: {
        sx: {
          height: "100%",
        },
      },
      sx: {
        width: "100%",
        height: "100%",
      },
    } as TextFieldProps,
    config: {
      defaultWidth: 150,
      defaultHeight: 50,
      fixedWidth: false,
      fixedHeight: false,
      controlledComponent: true,
    },
  },
  {
    type: ComponentItemType.Button,
    props: {
      variant: "contained",
      disableRipple: true,
      sx: {
        width: "100%",
        height: "100%",
      },
      children: "Button",
    } as ButtonProps,
    config: {
      defaultWidth: 150,
      defaultHeight: 50,
      fixedWidth: false,
      fixedHeight: false,
      isAction: true,
    },
  },
  {
    type: ComponentItemType.Typography,
    props: {
      children: "Typography",
      fontSize: "14px",
      wordwrap: "break-word",
      overflow: "hidden",
      component: "div",
      sx: {
        width: "100%",
        height: "100%",
      },
    } as TypographyProps,
    config: {
      defaultWidth: 150,
      defaultHeight: 50,
      fixedWidth: false,
      fixedHeight: true,
    },
  },
  {
    type: ComponentItemType.Divider,
    props: {
      sx: {
        width: "100%",
        height: "100%",
      },
      height: "100px",
    } as DividerProps,
    config: {
      defaultWidth: 150,
      defaultHeight: 50,
      fixedWidth: false,
      fixedHeight: true,
      disableResizeHeight: true,
    },
  },
  {
    type: ComponentItemType.Switch,
    props: {
      sx: {
        width: "100%",
        height: "100%",
      },
      height: "100px",
    } as SwitchProps,
    config: {
      defaultWidth: 60,
      defaultHeight: 38,
      fixedWidth: false,
      fixedHeight: true,
      disableResizeHeight: true,
    },
  },
  {
    type: ComponentItemType.CustomCheckbox,
    props: {
      sx: {
        width: "100%",
        height: "100%",
      },
      height: "100px",
      label: "Label",
      defaultChecked: true,
    } as CustomCheckboxProps,
    config: {
      defaultWidth: 60,
      defaultHeight: 38,
      fixedWidth: false,
      fixedHeight: true,
      disableResizeHeight: true,
    },
  },
  {
    type: ComponentItemType.CustomAutocomplete,
    props: {
      sx: {
        width: "100%",
        height: "100%",
      },
    } as any,
    config: {
      defaultWidth: 150,
      defaultHeight: 50,
      fixedWidth: false,
      fixedHeight: true,
      disableResizeHeight: true,
    },
  },
  {
    type: ComponentItemType.CustomImage,
    props: {
      sx: {
        width: "100%",
        height: "100%",
      },
      height: "100px",
      component: "img",
    } as CustomImageProps,
    config: {
      defaultWidth: 60,
      defaultHeight: 60,
      fixedWidth: false,
      fixedHeight: false,
    },
  },
  {
    type: ComponentItemType.CustomMediaCard,
    props: {
      sx: {
        width: "100%",
        height: "100%",
      },
      height: "100px",
      component: "img",
    } as CardMediaProps,
    config: {
      defaultWidth: 60,
      defaultHeight: 60,
      fixedWidth: false,
      fixedHeight: false,
    },
  },
  {
    type: ComponentItemType.CustomFileUpload,
    props: {
      sx: {
        width: "100%",
        height: "100%",
      },
      height: "100px",
      label: "Upload File",
    } as CustomFileUploadProps,
    config: {
      defaultWidth: 60,
      defaultHeight: 60,
      fixedWidth: false,
      fixedHeight: false,
    },
  },
];

export const getItemClosestProp = (prop: any, breakPoint: string) => {
  if (prop?.[breakPoint]) return prop?.[breakPoint];
  const layoutBreak = layoutBreakPoints?.find(el => prop?.[el?.id]);
  return layoutBreak?.id && prop?.[layoutBreak.id];
};

export const getNewPosition = (key: string | undefined, step: number) => {
  switch (key) {
    case "ArrowUp":
      return { y: -step };
    case "ArrowDown":
      return { y: +step };
    case "ArrowLeft":
      return { x: -step };
    case "ArrowRight":
      return { x: +step };
    default:
      return undefined;
  }
};
