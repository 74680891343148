import { Box, Button, Grid, MenuItem, Select, SelectChangeEvent, Switch, TextField, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { typographyComponents } from "../../utils";

interface ComponentEditorProps {
  item: any;
  onDeleteItem?: (itemId: any) => void;
  onChangeProp?: (item: any) => void;
}

export const ComponentEditor: FC<ComponentEditorProps> = ({ item, onDeleteItem, onChangeProp }) => {
  const { key, id, autoplay, src, sx, defaultValue, disabled, placeholder, required, label, children, fontSize, component } =
    item?.props || {};
  const [styles, setStyles] = useState(JSON.stringify(sx));
  const handleChangeStyles = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setStyles(e.target.value);
  };

  useEffect(() => {
    setStyles(JSON.stringify(sx));
  }, [item?.id]);
  const saveStyleHandler = () => {
    const newItem = { ...item, props: { ...item?.props, sx: { ...JSON.parse(styles) } } };
    onChangeProp?.(newItem);
  };
  const handleChangeProp = (type: string) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newItem = { ...item, props: { ...item?.props, [type]: e.target.value } };
    onChangeProp?.(newItem);
  };
  const handleChangeSelect = (type: string) => (event: SelectChangeEvent) => {
    const newItem = { ...item, props: { ...item?.props, [type]: event.target.value as string } };
    onChangeProp?.(newItem);
  };
  const handleToggleProp = (type: string) => () => {
    const newItem = { ...item, props: { ...item?.props, [type]: !item?.props?.[type] } };
    onChangeProp?.(newItem);
  };
  if (!item)
    return (
      <Typography display='flex' flex={1} justifyContent='center' alignItems='center'>
        No Selected Item
      </Typography>
    );

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={12}>
        <Box display='flex' justifyContent='space-between' alignItems='center' pb={3}>
          <Typography>Item Properties:</Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <TextField fullWidth label={"ID"} value={id || ""} onChange={handleChangeProp("id")} />
      </Grid>
      <Grid item xs={6}>
        <TextField fullWidth label={"Key"} value={key || ""} onChange={handleChangeProp("key")} />
      </Grid>
      <Grid item xs={6}>
        <TextField fullWidth label={"Default Value"} value={defaultValue || ""} onChange={handleChangeProp("defaultValue")} />
      </Grid>
      {item?.type === "TextField" && (
        <>
          <Grid item xs={6}>
            <TextField fullWidth label={"label"} value={label || ""} onChange={handleChangeProp("label")} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label={"Place Holder"} value={placeholder || ""} onChange={handleChangeProp("placeholder")} />
          </Grid>
          <Grid display='flex' item xs={12} alignItems='center' gap={"1px"}>
            <Switch checked={required} onChange={handleToggleProp("required")} inputProps={{ "aria-label": "controlled" }} />
            <Typography>Required</Typography>
          </Grid>
          <Grid display='flex' item xs={12} alignItems='center' gap={"1px"}>
            <Switch checked={disabled} onChange={handleToggleProp("disabled")} inputProps={{ "aria-label": "controlled" }} />
            <Typography>Disabled</Typography>
          </Grid>
        </>
      )}
      {item?.type === "Button" && (
        <Grid item xs={6}>
          <TextField fullWidth label={"Text"} value={children || ""} onChange={handleChangeProp("children")} />
        </Grid>
      )}
      {item?.type === "Typography" && (
        <>
          <Grid item xs={6}>
            <TextField fullWidth label={"Text"} value={children || ""} onChange={handleChangeProp("children")} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label={"font Size"} value={fontSize || ""} onChange={handleChangeProp("fontSize")} />
          </Grid>
          <Grid item xs={6}>
            <Select fullWidth value={component} label='Component' placeholder='Component' onChange={handleChangeSelect("component")}>
              {typographyComponents?.map(item => (
                <MenuItem key={item?.id} value={item?.id}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </>
      )}
      {item?.type === "CustomMediaCard" && (
        <>
          <Grid item xs={6}>
            <TextField fullWidth label={"Source"} value={src || ""} onChange={handleChangeProp("src")} />
          </Grid>
          <Grid item xs={6}>
            <Select fullWidth value={component} label='Component' placeholder='Component' onChange={handleChangeSelect("component")}>
              <MenuItem key='img' value='img'>
                img
              </MenuItem>
              <MenuItem key='video' value='video'>
                video
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Grid display='flex' item xs={12} alignItems='center' gap={"1px"}>
              <Switch checked={autoplay} onChange={handleToggleProp("autoplay")} inputProps={{ "aria-label": "controlled" }} />
              <Typography>Autoplay</Typography>
            </Grid>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant='filled'
          id='outlined-multiline-static'
          label='Custom CSS'
          multiline
          value={styles || ""}
          rows={9}
          onChange={handleChangeStyles}
        />
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth onClick={saveStyleHandler} variant='contained' disabled={JSON.stringify(sx) === styles}>
          Save Style
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth onClick={() => onDeleteItem?.(item.id)} variant='contained'>
          Delete Item
        </Button>
      </Grid>
    </Grid>
  );
};
