import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { IconX } from "@tabler/icons";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";

// style constant
const useStyles = makeStyles((theme: any) => ({
  paper: {
    minHeight: "70vh",
    maxHeight: "70vh",
  },
}));

type BXModalProps = {
  title: string;
  icon?: any;
  buttonProps?: ButtonProps;
  label?: string;
  children?: React.ReactNode | Function;
  minWidth?: any;
  maxWidth?: any;
  open?: boolean;
  showHeader?: boolean;
  withoutLabel?: boolean;
  isDirty?: boolean;
  onClose?: () => void;
  headerRightComponent?: any;
  paperProps?: any;
  buttonComponent?: any;
};
const BXModal: FC<BXModalProps> = ({
  children,
  buttonProps,
  label,
  title,
  icon,
  open: _open,
  onClose,
  maxWidth = "md",
  showHeader = true,
  withoutLabel,
  isDirty = false,
  headerRightComponent = <></>,
  paperProps = {},
  buttonComponent,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = (e: any) => {
    e.stopPropagation();
    buttonProps?.onClick?.(e);
    setOpen(true);
  };
  const handleClose = (confirmed?: boolean, cb?: () => void) => {
    if (confirmed == true || !isDirty) {
      onClose?.();
      setOpen(false);
      if (typeof cb == "function") cb?.();
      return;
    }
    if (isDirty) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("There are some changes, Are you sure you want to navigate ?")) {
        onClose?.();
        setOpen(false);
        cb?.();
      }
    }
  };

  const classes = useStyles();

  useEffect(() => {
    if (!_.isNil(_open)) {
      setOpen(_open);
    }
  }, [_open]);

  return (
    <>
      {buttonComponent ? (
        <span onClick={handleOpen}>{buttonComponent}</span>
      ) : !_.isNil(_open) ? null : _.isNil(title) || withoutLabel ? (
        <Tooltip title={label || ""}>
          <IconButton {...(_.isNil(buttonProps) ? {} : buttonProps)} onClick={handleOpen}>
            {icon}
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={label || ""}>
          <LoadingButton {...(_.isNil(buttonProps) ? {} : buttonProps)} onClick={handleOpen}>
            {label}
          </LoadingButton>
        </Tooltip>
      )}

      <Dialog
        open={open}
        onClose={handleClose as any}
        closeAfterTransition
        keepMounted={false}
        BackdropProps={{
          timeout: 500,
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        fullWidth
        maxWidth={maxWidth}
        PaperProps={{
          onClick: e => {
            e.stopPropagation();
          },
          ...paperProps,
        }}
        // classes={{ paper: classes.paper }}
      >
        {showHeader ? (
          <>
            <DialogTitle display={"flex"} alignItems='center'>
              <Box flex={1} display={"flex"} alignItems='center'>
                {icon && icon}
                <Typography marginLeft={1} id='modal-modal-title' fontSize={16} fontWeight={800}>
                  {title}
                </Typography>
              </Box>
              <Box sx={{ cursor: "pointer", display: "flex" }} onClick={() => handleClose()}>
                {headerRightComponent}
                <IconX />
              </Box>
            </DialogTitle>
            <Divider />
          </>
        ) : (
          <Box sx={{ cursor: "pointer", display: "flex", justifyContent: "flex-end", paddingRight: "14px" }} onClick={() => handleClose()}>
            <IconX />
          </Box>
        )}
        <DialogContent style={{ padding: 10 }}>{_.isFunction(children) ? children?.(handleClose) : children}</DialogContent>
      </Dialog>
    </>
  );
};

export default BXModal;
