// project imports
import _ from "lodash";
import { RouteObject } from "react-router";
import { Navigate } from "react-router-dom";
import BXPage from "src/components/BXUI/Page";
import { PageAuthGuard } from "src/components/BXUI/PageAuth/PageAuthGuard";
import MainLayout from "src/layout/MainLayout";
import { BXApp } from "src/types/BXAppType";
import AuthGuard from "src/utils/route-guard/AuthGuard";
import { AppBuilder } from "src/views/pages/BuildX/AppBuilder";
import { FormBuilderEditor } from "src/views/pages/BuildX/FormBuilder";

import { ManageComponents } from "src/views/pages/BuildX/ManageComponents";
import { ManageEnv } from "src/views/pages/BuildX/ManageEnv";
import { ManageOAS } from "src/views/pages/BuildX/ManageOAS";
import { ManageUsers } from "src/views/pages/BuildX/ManageUsers";
import { BXEngine } from "../BXEngine";
import { BXBuilderContextProvider } from "../BXEngine/BXBuilderContext";

// ==============================|| MAIN ROUTING ||============================== //

// if app has no pages at all, then we need to create layout for pageless app to say that there is no page for this app
const MainRoutes = (bxApps: BXApp[], currentApp?: BXApp, isLoggedIn?: boolean, loadingApps?: boolean) => {
  const routes: RouteObject[] = bxApps.map(app => {
    const result = {
      breadcrumbs: true,
      path: `/${app.slug}`,
      children: _.flatten(
        app.templateConfig?.collections?.map(collection =>
          collection.pages.map(page => ({
            id: page.name,
            breadcrumbs: true,
            path: `${collection.slug.split("/").filter(Boolean).join("/")}/${page.slug}`.replaceAll(/\/+/g, "/"),
            element: (
              <PageAuthGuard app={app}>
                <BXPage page={page}>
                  {page?.views
                    ?.filter(view => view.info?.visibility !== "Hidden")
                    ?.map?.(view => (
                      <BXEngine page={page} auth={{}} key={view?.id} layout={[view]} />
                    ))}
                </BXPage>
              </PageAuthGuard>
            ),
          }))
        )
      ),
    };
    return result;
  });

  if (
    (!loadingApps && currentApp?.templateConfig && !currentApp?.templateConfig?.collections?.length) ||
    currentApp?.templateConfig?.collections ||
    !isLoggedIn
  ) {
    routes.push({
      path: "*",
      element: <Navigate to={"/"} />,
    });
  }

  routes.push({
    path: "/buildx/app",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <AppBuilder />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });
  routes.push({
    path: "/buildx/users",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <ManageUsers />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });
  routes.push({
    path: "/buildx/oas",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <ManageOAS />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });
  routes.push({
    path: "/buildx/env",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <ManageEnv />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });

  routes.push({
    path: "/buildx/component",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <ManageComponents />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });

  routes.push({
    path: "/buildx/form-builder/:appId/:collectionId/:pageId/:viewId",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <FormBuilderEditor />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });

  return [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout navigation={routes} />
        </AuthGuard>
      ),
      children: [...routes],
    },
  ];
};

export default MainRoutes;
