import { Button, Input } from "@mui/material";
import { FC } from "react";
import { CustomFileUploadProps } from "./types";

export const CustomFileUpload: FC<CustomFileUploadProps> = props => {
  return (
    <label htmlFor='upload-photo'>
      <Input style={{ display: "none" }} type='file' {...props} />
      <Button color='secondary' variant='contained' component='span'>
        {props?.label}
      </Button>
    </label>
  );
};
