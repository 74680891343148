import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const paginationStyles = makeStyles((theme: Theme) => ({
  paginationContainer: {
    display: "flex",
    "list-style-type": "none",
    padding: 0,
    margin: 0,
  },
  paginationItem: {
    height: "32px",
    "text-align": "center",
    margin: "auto 4px",
    color: theme.palette.text.primary,
    display: "flex",
    "box-sizing": "border-box",
    "align-items": "center",
    "letter-spacing": "0.01071em",
    "border-radius": "16px",
    "line-height": "1.43",
    "font-size": "13px",
    "min-width": "32px",
    justifyContent: "center",

    "&.dots:hover": {
      "background-color": "transparent",
      cursor: "default",
    },
    "&:hover": {
      "background-color": theme.palette.primary.light,
      cursor: "pointer",
    },

    "&.selected": {
      "background-color": theme.palette.primary.light,
    },

    "&.disabled": {
      "pointer-events": "none",
      color: theme.palette.primary.light,
      "&:hover": {
        "background-color": "transparent",
        cursor: "default",
      },
    },
  },
}));
