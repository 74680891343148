// action - state management
import * as actionTypes from './actions';
import { DefaultRootStateProps } from 'src/types';

export interface LoaderActionProps {
    type: string;
    payload: any;
}

const initialState: DefaultRootStateProps['loader'] = {
    loaderType: 'circle',
    isLoading: false,
    fullScreen: true
};

// ==============================|| LOADER REDUCER ||============================== //

const loaderReducer = (state = initialState, action: LoaderActionProps) => {
    switch (action.type) {
        case actionTypes.LOADER_OPEN:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.LOADER_CLOSE:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
};

export default loaderReducer;
