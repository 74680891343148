import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { BXEngine } from "src/BXEngine";
import { queryClient, replaceBaseUrl, useBXContext } from "src/BXEngine/BXContext";
import { BXIcon } from "src/components/BXUI/Icon";
import { UIElement } from "src/types/UIElement";
import axios from "src/utils/axios";
import { getAuthorizationHeader, getHashesFromString, getLastKeyFromObject, reverseDataOfObjectValues } from "src/utils/generalUtils";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { BXConfirmationDialog } from "../AlertDialog/ConfirmationDialog";
import BXModal from "../Modal";
import { ViewerModal } from "../viewerModal";
import CreatePayload from "./CreatePayload";

export const replaceItemPlaceholders = (
  url: string = "",
  item: any,
  selectedRowFromOtherTables?: any,
  userInputsValues?: { [inputName: string]: any },
  isUserInput?: boolean,
  formData?: any,
  __data = {},
  fallback: any = "",
  actionResponse: any = {},
  dnd?: any,
  env?: any,
  pagination?: any
) => {
  const placeholders = url.match(/\{(.*?)\}/g);

  placeholders?.forEach(function (placeholder) {
    var phText = "";

    if (
      placeholder.startsWith("{#") ||
      placeholder.startsWith("{$.") ||
      placeholder.startsWith("{_response.") ||
      placeholder.startsWith("{_drag.") ||
      placeholder.startsWith("{_drop.") ||
      placeholder.startsWith("{_dnd") ||
      placeholder.startsWith("{_pagination")
    ) {
      const index = placeholder.split("").findIndex((key: string) => key == ".");
      phText = placeholder.substring(index + 1, placeholder.length - 1);
    } else if (placeholder.startsWith("{{")) {
      phText = placeholder.substring(2, placeholder.length - 1);
    } else {
      phText = placeholder.substring(1, placeholder.length - 1);
    }

    let data;

    if (placeholder.startsWith("{$.")) {
      data = formData?.();
    } else if (placeholder.startsWith("{_response.")) {
      data = actionResponse;
    } else if (placeholder.startsWith("{_dnd")) {
      data = dnd;
    } else if (placeholder.startsWith("{_pagination")) {
      data = pagination;
    } else if (placeholder.startsWith("{{")) {
      data = env?.config?.variables;
    } else {
      data = placeholder?.startsWith("{##")
        ? reverseDataOfObjectValues(__data)[getHashesFromString(placeholder)]
        : placeholder.startsWith("{#.")
        ? isUserInput
          ? userInputsValues
          : selectedRowFromOtherTables
        : item;
    }

    const replacedValue = !_.isNil(_.get(data, phText)) ? _.get(data, phText) : fallback;
    if (typeof replacedValue == "object") {
      url = replacedValue;
    } else {
      url = url.replace(placeholder.startsWith("{{") ? placeholder + "}" : placeholder, replacedValue);
    }
  });

  return url;
};

export const iterate = (
  obj: any = {},
  item: any,
  selectedRowFromOtherTables: any,
  isUserInput: any,
  userInputsValues: any,
  formData?: any,
  __data = {},
  env?: any
) => {
  Object.keys(obj).forEach(key => {
    let template = obj?.[key];
    const placeholders = String(template)?.match(/\{(.*?)\}/g);

    placeholders?.forEach(function (placeholder: any) {
      if (placeholder?.[0] === "{" && placeholder?.[placeholder?.length - 1] === "}") {
        let text = "";

        if (placeholder?.startsWith("{#") || placeholder?.startsWith("{$.")) {
          const index = placeholder.split("").findIndex((key: string) => key == ".");

          text = placeholder.substring(index + 1, placeholder.length - 1);
        } else if (placeholder.startsWith("{{")) {
          text = placeholder.substring(2, placeholder.length - 1);
        } else {
          text = placeholder.substring(1, placeholder.length - 1);
        }

        const checkIfIncludesArray = placeholder.includes("[]");
        let arrayObjectKey = "";

        if (checkIfIncludesArray) {
          const arrayOfStrings = text.split("");
          const index = arrayOfStrings.findIndex((key: string, index: number) => key == "[" && arrayOfStrings?.[index + 1] == "]");
          if (index != -1) {
            arrayObjectKey = text.substring(index + 3, text.length);
            text = text.substring(index, 0);
          }
        }

        if (placeholder?.startsWith("{$.")) {
          let data = _.get(formData?.(), text);
          if (checkIfIncludesArray && arrayObjectKey && typeof data == "object") {
            const filterData = data?.length && data?.map((item: any) => item?.[arrayObjectKey]);
            if (filterData) {
              data = filterData;
            }
          }
          template = typeof data === "object" ? data : template.replace(placeholder, data ?? "");
          obj[key] = template;
        } else if (placeholder.startsWith("{{")) {
          const data = _.get(env?.config?.variables, text);
          template = typeof data === "object" ? data : template.replace(placeholder, data ?? "");
          obj[key] = template;
        } else {
          const data =
            _.get(
              placeholder?.startsWith("{##")
                ? reverseDataOfObjectValues(__data)[getHashesFromString(placeholder)]
                : placeholder?.startsWith("{#.")
                ? isUserInput
                  ? userInputsValues
                  : selectedRowFromOtherTables
                : item,
              text
            ) || "";

          template = typeof data === "object" ? data : template.replace(placeholder, data ?? "");

          obj[key] = template;
        }
      }
    });
    if (typeof obj[key] === "object" && obj[key] !== null) {
      iterate(obj[key], item, selectedRowFromOtherTables, isUserInput, userInputsValues, formData, __data, env);
    }
  });

  return obj;
};

export const formatJSON = (val = {}) => {
  try {
    const res = JSON.parse(val as any);
    return JSON.stringify(res, null, 2);
  } catch {
    return null;
  }
};

export const ActionButton: FC<{
  action: any;
  auth?: any;
  onStateChange?: (newState: string) => void;
  disabled?: boolean;
  item: any;
  selectedRowFromOtherTables: any;
  userInputsValues?: { [inputName: string]: any };
  isUserInput?: boolean;
  withoutLabel?: boolean;
  views?: UIElement[];
  onSelectRow?: any;
  tableId: any;
  handleSubmit?: any;
  iconButton?: boolean;
  formData?: any;
  fullWidth?: boolean;
  variant?: string;
  __data?: any;
  closeModal?: any;
  parentIds?: any;
  isDismissibleView?: string;
  withBorder?: boolean;
  buttonComponent?: any;
}> = ({
  action: _action = {},
  onStateChange = () => {},
  disabled = false,
  item,
  auth,
  selectedRowFromOtherTables,
  userInputsValues,
  isUserInput,
  views,
  onSelectRow,
  withoutLabel,
  tableId,
  handleSubmit,
  formData,
  parentIds,
  buttonComponent,
  iconButton = true,
  fullWidth = false,
  variant = "contained",
  __data = {},
  closeModal = () => {},
  withBorder = true,
}) => {
  const [errorMessage, setError] = useState(null);
  const [action, setAction] = useState(_action);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const { palette } = useTheme();
  const { currentApp, currentProfileId, envs, setViewStacks } = useBXContext();

  const { source, actionSourceType, actionViewType, method, id, body, showModal, modalSize, headers = {} } = action;
  useEffect(() => {
    const newBody =
      _action?.body &&
      formatJSON(
        JSON.stringify(
          iterate(
            JSON.parse(_action?.body || "{}"),
            item,
            selectedRowFromOtherTables,
            isUserInput,
            userInputsValues,
            formData,
            __data,
            currentApp?.env
          )
        )
      );

    setAction({
      ..._action,
      body: newBody,
      headers: iterate(
        { ..._action?.headers },
        item,
        selectedRowFromOtherTables,
        isUserInput,
        userInputsValues,
        formData,
        __data,
        currentApp?.env
      ),
    });
  }, [selectedRowFromOtherTables, item, userInputsValues, isUserInput]);

  // const { token } = auth || {};

  const onAction = (variables: any) => {
    const token = localStorage.getItem(currentApp?.id + `-${currentProfileId}-accessToken`);
    onStateChange("loading");
    const url = replaceBaseUrl(source, currentApp);

    let data = undefined;

    if (handleSubmit) {
      data =
        _action?.body &&
        iterate(
          JSON.parse(_action?.body || "{}"),
          item,
          selectedRowFromOtherTables,
          isUserInput,
          userInputsValues,
          formData,
          __data,
          currentApp?.env
        );
    } else {
      data = body && JSON.parse(body || "{}");
    }

    return axios.request({
      url: replaceItemPlaceholders(
        url,
        item,
        selectedRowFromOtherTables,
        userInputsValues,
        isUserInput,
        formData,
        __data,
        undefined,
        undefined,
        undefined,
        currentApp?.env
      ),
      method: method?.toLowerCase?.(),
      headers: {
        ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
        ...iterate(headers, item, selectedRowFromOtherTables, isUserInput, userInputsValues, formData, __data, currentApp?.env),
      },
      data,
    });
  };

  const { mutate, isLoading } = useMutation(onAction, {
    onSuccess: async (data, variables, context) => {
      const statusMessage = action?.statusMessages?.find((item: any) => item?.key == data?.request?.status)?.value;

      enqueueSnackbarRef?.(
        replaceItemPlaceholders(
          statusMessage,
          item,
          selectedRowFromOtherTables,
          userInputsValues,
          isUserInput,
          formData,
          __data,
          undefined,
          data?.data,
          undefined,
          currentApp?.env
        ) || "Posted Successfully",
        {
          variant: "success",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        }
      );

      if (_action?.isDismissibleView != "No") {
        closeModal?.(data?.data);
      }
      queryClient.refetchQueries([tableId]);
      if (parentIds) {
        parentIds.forEach((id: any) => queryClient.refetchQueries([id]));
      }
      onStateChange("normal");
      // success: remove item from data
      if (method.toLowerCase() === "delete") {
      } else if (method.toLowerCase() === "post") {
        // update record because it is an item action
      } else if (method.toLowerCase() === "put") {
        // update record because it is an item action
      } else if (method.toLowerCase() === "patch") {
        // update record because it is an item action
      }
    },
    onError: (error: any, variables) => {
      setError(error || "Something went wrong");
      onStateChange("normal");
      const statusMessage = action?.statusMessages?.find((item: any) => item?.key == error?.requestStatusCode)?.value;
      const errorMessage = replaceItemPlaceholders(
        statusMessage,
        item,
        selectedRowFromOtherTables,
        userInputsValues,
        isUserInput,
        formData,
        __data,
        undefined,
        error,
        undefined,
        currentApp?.env
      );

      enqueueSnackbarRef?.(
        errorMessage || error?.response?.data?.error || JSON.parse(error?.response?.config?.data || "{}")?.errorMessage || "Wrong Services",
        {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        }
      );
    },

    mutationKey: id,
  });

  const isAPI = _.isNaN(actionSourceType?.type) || actionSourceType?.type == "API";
  const isDownload = actionSourceType?.type == "Download";
  const isStack = actionViewType == "Stack";

  const selectedView = views?.find(view => view?.id == actionSourceType?.id);

  const data = replaceItemPlaceholders(
    action?.condition,
    item,
    selectedRowFromOtherTables,
    userInputsValues,
    isUserInput,
    undefined,
    __data,
    undefined,
    undefined,
    undefined,
    currentApp?.env
  );

  if (!eval(data) && !_.isEmpty(data)) return <></>;

  const handleActionClick = () => {
    if (action?.dialog?.enabled && !openConfirmation) return setOpenConfirmation(true);
    if (handleSubmit) {
      handleSubmit(mutate)();
    } else {
      mutate({}, {});
    }
  };

  const handleDownloadClick = async () => {
    const data =
      replaceItemPlaceholders(action.source, item, selectedRowFromOtherTables, userInputsValues, isUserInput, undefined, __data, null) ||
      "";

    const token = localStorage.getItem(currentApp?.id + `-${currentProfileId}-accessToken`);

    let url = "";
    if (action?.downloadType === "api") {
      const { data } = await axios.get(
        replaceItemPlaceholders(
          action?.downloadEndpoint,
          item,
          selectedRowFromOtherTables,
          userInputsValues,
          isUserInput,
          formData,
          __data,
          undefined,
          undefined,
          undefined,
          currentApp?.env
        ),
        {
          headers: {
            ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
          },
        }
      );
      url = data?.[action?.downloadKey];
    }

    fetch(url || data)
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", blob.type);
        document.body.appendChild(link);
        link.click();
      });
  };
  return (
    <>
      {isAPI ? (
        <>
          {action?.dialog?.enabled && (
            <BXConfirmationDialog
              open={openConfirmation}
              title={action?.dialog?.message || "Are you sure?"}
              iconButton
              onConfirm={() => {
                handleActionClick();
                setOpenConfirmation(false);
              }}
              onCancel={() => {
                setOpenConfirmation(false);
              }}
            />
          )}
          {showModal == "No" ? (
            <Tooltip title={action?.label}>
              {buttonComponent ? (
                <span onClick={handleActionClick}>{buttonComponent}</span>
              ) : iconButton ? (
                <IconButton
                  onClick={handleActionClick}
                  disabled={disabled}
                  // style={{ backgroundColor: palette.primary.main, padding: 6 }}
                >
                  <BXIcon icon={action.icon} width={16} height={16} color={"currentColor"} />
                </IconButton>
              ) : (
                <LoadingButton
                  loading={isLoading}
                  loadingPosition='start'
                  aria-label={action.label}
                  variant={variant as any}
                  disabled={disabled}
                  onClick={handleActionClick}
                  startIcon={action?.icon && <BXIcon icon={action?.icon} width={20} height={20} color={palette.text.primary} />}
                  fullWidth={fullWidth}
                  style={!withBorder ? { borderWidth: 0, justifyContent: "flex-start" } : {}}
                >
                  <Typography color={palette.text.primary}>{action.label}</Typography>
                </LoadingButton>
              )}
            </Tooltip>
          ) : (
            <BXModal
              buttonComponent={buttonComponent}
              label={action.label}
              icon={<BXIcon icon={action?.icon} width={14} height={14} color={"currentColor"} />}
              buttonProps={{
                variant: "contained",
                startIcon: <BXIcon icon={action.icon} width={14} height={14} color={"currentColor"} />,
              }}
              title={action.label}
              withoutLabel={_.isNil(withoutLabel) ? true : withoutLabel}
            >
              {(handleClose: Function) => {
                return (
                  <CreatePayload
                    payload={{ body: action?.body, headers: action?.headers }}
                    onSave={(payload: any) => {
                      setAction((prev: any) => ({ ...prev, ...payload }));
                      setTimeout(() => {
                        mutate({}, {});
                      }, 0);
                    }}
                    label={action?.label}
                    onClose={() => handleClose()}
                    isLoading={isLoading}
                    disabled={disabled}
                  />
                );
              }}
            </BXModal>
          )}
        </>
      ) : isDownload ? (
        <Tooltip title={action?.label}>
          {buttonComponent ? (
            <span onClick={handleDownloadClick}>{buttonComponent}</span>
          ) : iconButton ? (
            <IconButton
              onClick={handleDownloadClick}
              disabled={disabled}
              // style={{ backgroundColor: palette.primary.main, padding: 6 }}
            >
              <BXIcon icon={action.icon} width={16} height={16} color={"currentColor"} />
            </IconButton>
          ) : (
            <LoadingButton
              loading={isLoading}
              loadingPosition='start'
              aria-label={action.label}
              variant={variant as any}
              disabled={disabled}
              onClick={handleDownloadClick}
              startIcon={action?.icon && <BXIcon icon={action?.icon} width={20} height={20} color={palette.text.primary} />}
              fullWidth={fullWidth}
              style={!withBorder ? { borderWidth: 0, justifyContent: "flex-start" } : {}}
            >
              <Typography color={palette.text.primary}>{action.label}</Typography>
            </LoadingButton>
          )}
        </Tooltip>
      ) : isStack ? (
        buttonComponent ? (
          <span
            onClick={e => {
              e.stopPropagation();
              onSelectRow?.(item, true);
              setViewStacks(
                prev =>
                  [
                    ...prev,
                    <BXEngine
                      auth={{}}
                      page={{ views, layout: selectedView } as any}
                      layout={[{ ...(selectedView as any), type: actionSourceType?.type }]}
                      isVisible
                      selectedViewId={item.id}
                      __data={{
                        ...__data,
                        [(getLastKeyFromObject(__data) || "") + "#"]: item,
                      }}
                      closeModal={closeModal}
                      parentIds={[...parentIds, tableId]}
                    />,
                  ] as UIElement[]
              );
            }}
          >
            {buttonComponent}
          </span>
        ) : (
          <Tooltip title={action?.label}>
            {iconButton ? (
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  onSelectRow?.(item, true);
                  setViewStacks(
                    prev =>
                      [
                        ...prev,
                        <BXEngine
                          auth={{}}
                          page={{ views, layout: selectedView } as any}
                          layout={[{ ...(selectedView as any), type: actionSourceType?.type }]}
                          isVisible
                          selectedViewId={item.id}
                          __data={{
                            ...__data,
                            [(getLastKeyFromObject(__data) || "") + "#"]: item,
                          }}
                          closeModal={closeModal}
                          parentIds={[...parentIds, tableId]}
                        />,
                      ] as UIElement[]
                  );
                }}
                disabled={disabled}
                // style={{ backgroundColor: palette.primary.main, padding: 6 }}
              >
                <BXIcon icon={action.icon} width={16} height={16} color={"currentColor"} />
              </IconButton>
            ) : (
              <LoadingButton
                loadingPosition='start'
                aria-label={action.label}
                variant={variant as any}
                fullWidth={fullWidth}
                style={!withBorder ? { borderWidth: 0, justifyContent: "flex-start" } : {}}
                onClick={e => {
                  e.stopPropagation();
                  onSelectRow?.(item, true);
                  setViewStacks(
                    prev =>
                      [
                        ...prev,
                        <BXEngine
                          auth={{}}
                          page={{ views, layout: selectedView } as any}
                          layout={[{ ...(selectedView as any), type: actionSourceType?.type }]}
                          isVisible
                          selectedViewId={item.id}
                          __data={{
                            ...__data,
                            [(getLastKeyFromObject(__data) || "") + "#"]: item,
                          }}
                          closeModal={closeModal}
                          parentIds={[...parentIds, tableId]}
                        />,
                      ] as UIElement[]
                  );
                }}
                disabled={disabled}
                startIcon={action?.icon && <BXIcon icon={action?.icon} width={20} height={20} color={palette.text.primary} />}
              >
                {action.label}
              </LoadingButton>
            )}
          </Tooltip>
        )
      ) : (
        <ViewerModal
          action={action}
          actionSourceType={actionSourceType}
          item={item}
          selectedView={selectedView}
          onSelectRow={onSelectRow}
          views={views}
          withoutLabel={iconButton}
          iconColor='currentColor'
          buttonComponent={buttonComponent}
          buttonProps={{
            style: iconButton
              ? {
                  // backgroundColor: palette.primary.main, padding: 6
                }
              : { ...(!withBorder && { borderWidth: 0, justifyContent: "flex-start" }) },
            fullWidth,
            variant,
          }}
          modalSize={modalSize}
          __data={__data}
          id={tableId}
          parentIds={parentIds}
        />
      )}
    </>
  );
};
