// third-party
import { Link } from "react-router-dom";
// import moment from 'moment';

// material-ui
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useTheme from "@mui/styles/useTheme";
import { Mousewheel, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './styles.css';

// import EditIcon from '@mui/icons-material/Edit';

// Tabler
// import { IconEye } from '@tabler/icons';

// project imports
import { IconCode } from "@tabler/icons";
import classNames from "classnames";
import _ from "lodash";
import { Fragment, useState } from "react";
import ReactJson from "react-json-view";
import { useMutation } from "react-query";
import { BXEngine } from "src/BXEngine";
import { queryClient, replaceBaseUrl, useBXContext } from "src/BXEngine/BXContext";
import Avatar from "src/components/extended/Avatar";
import { gridSpacing } from "src/stores/constant";
import { UIElement } from "src/types/UIElement";
import { PowerPack } from "src/types/powerPack";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axios from "src/utils/axios";
import { getAuthorizationHeader, getLastKeyFromObject } from "src/utils/generalUtils";
import { BXConfirmationDialog } from "../BXUI/AlertDialog/ConfirmationDialog";
import { formatJSON, iterate, replaceItemPlaceholders } from "../BXUI/DataTable/ActionButton";
import CreatePayload from "../BXUI/DataTable/CreatePayload";
import { BXIcon } from "../BXUI/Icon";
import BXModal from "../BXUI/Modal";
import { ViewerModal } from "../BXUI/viewerModal";
// assets
// const assetImages = require.context('assets/images', true);
// const defaultCover: string = assetImages('./not_available_grey.png').default;

// style card
const useStyles = makeStyles((theme: Theme) => ({
  followerBlock: {
    padding: "0px",
    // background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
    border: theme.palette.mode === "dark" ? "1px solid transparent" : `1px solid${theme.palette.grey[100]}`,
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
  primaryLight: {
    color: theme.palette.mode === "dark" ? theme.palette.grey[900] : theme.palette.grey[500],
    cursor: "pointer",
  },
  btnProfile: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: 0,
    borderRadius: 0,
    borderBottom: "2.5px solid",
    "&:hover ": {
      color: "#fff",
    },
    "&.MuiButton-outlinedPrimary:hover ": {
      background: theme.palette.primary.main,
    },
    "&.MuiButton-outlinedSecondary": {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
    "&.MuiButton-outlinedSecondary:hover ": {
      background: theme.palette.error.main,
      color: "#fff",
    },
  },
  btnActionProfile: {
    flex: "1 1 auto",
    margin: "5px",
    borderRadius: "4px",
    color: theme.palette.text.primary,
  },
  btnActionProfileSelected: {
    backgroundColor: theme.palette?.text?.primary + " !important",
    color: theme.palette?.primary?.main,
  },
  videoMedia: {
    height: "330px",
    // border: `0.5px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.common.black}`,
    [theme.breakpoints.down("lg")]: {
      height: "220px",
    },
  },
  creatorImage: {
    width: 40,
    height: 40,
    marginRight: 10,
    float: "left",
  },
  bgPrimary: {
    background: theme.palette.mode === "dark" ? theme.palette.background.default : theme.palette.background.paper,
  },
  elliptSingleLine: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
  assetId: {
    fontSize: "0.65rem",
  },
  swiperContainer: {
    width: '100%',
    height: '100%',
  },
  swiperSlideImg: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'scale-down',
  }
}));

// Power Pack Card Interfaces

interface PowerPacksCardProps extends PowerPack {
  handleView?: (id: string) => void;
  handleMint?: (id: string) => void;
  handleDelete?: (id: string) => void;
  handleFeatured?: (id: string, value: boolean) => void;
  handleFlagged?: (id: string, value: boolean) => void;
  caption?: string;
  originalUrl?: string;
  dataString?: string;
  createdOn?: number;
  creator?: any;
  index?: number;
  stats?: any;
  actions?: any[];
  selectedRowFromOtherTables: any;
  userInputsValues?: { [inputName: string]: any };
  isUserInput?: boolean;
  views: any;
  onSelectRow: any;
  gridId: any;
  coreData: any;
  __data?: any;
  closeModal?: any;
  parentIds?: any;
  viewType?: string;
  src?: string;
  prevId?: string;
  gridKey?: string;
  playOnHover?: boolean;
  dataSource?: any;
}

export const PowerPackDetailsCard = (item: PowerPacksCardProps) => {
  const {
    index: cardIndex,
    cover,
    highUri,
    id = "",
    lowUri,
    gridKey,
    actions,
    selectedRowFromOtherTables,
    userInputsValues,
    isUserInput,
    views,
    onSelectRow,
    playOnHover,
    gridId,
    coreData,
    viewType,
    prevId,
    dataSource,
    __data = {},
    closeModal,
    parentIds = [],
  } = item;

  const theme: any = useTheme();
  const classes = useStyles();

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const { currentApp, currentProfileId, envs, setViewStacks } = useBXContext();

  const playVideo = (event: React.MouseEvent<HTMLVideoElement>) => {
    const vid: HTMLMediaElement = event.target as HTMLMediaElement;
    if (vid) vid.play();
  };

  const pauseVideo = (event: React.MouseEvent<HTMLVideoElement>) => {
    const vid: HTMLMediaElement = event.target as HTMLMediaElement;
    if (vid) vid.pause();
  };

  const { originalUrl, stats, creator, caption, prettyTime } = !gridKey ? coreData : _.get(coreData, gridKey) || {};
  const src = typeof coreData != "object" ? coreData : viewType == "image-grid" ? _.get(coreData, gridKey || "") : originalUrl;

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  const renderCoverCard = (lowAssetUri: string | undefined, highAssetUri: string | undefined, covers: string[] | undefined) => {
    if (coreData?.feedItemType == "PHOTO") {
      return (
        //@ts-ignore
        <Swiper pagination={pagination} modules={[Pagination, Mousewheel]} loop={true} slideToClickedSlide={true} className={classes.videoMedia}>
          {coreData.mediaItemDataList.map((image: any, index: number) => (
            <SwiperSlide key={coreData.id + index}>
              <CardMedia
                sx={{ border: `0px` }}
                className={classes.swiperSlideImg}
                src={image.url}
                component='img'
              />
            </SwiperSlide>
          ))}
        </Swiper>)
    } else {
      return (<CardMedia
        onMouseEnter={viewType == "card-list" && playOnHover ? playVideo : undefined}
        onMouseLeave={viewType == "card-list" && playOnHover ? pauseVideo : undefined}
        sx={{ border: `0px`, backgroundSize: "contain", objectFit: "contain" }}
        className={classes.videoMedia}
        component={viewType == "card-list" ? "video" : "img"}
        src={src}
        image={src}
        controls={viewType == "card-list"}
      />)
    }

  };

  const onAction = (variables: any) => {
    const { method, body, headers, source, isDismissibleView } = variables || {};
    const token = localStorage.getItem(currentApp?.id + `-${currentProfileId}-accessToken`);
    const url = replaceBaseUrl(source, currentApp);
    return axios.request({
      url: replaceItemPlaceholders(
        url,
        item,
        selectedRowFromOtherTables,
        userInputsValues,
        isUserInput,
        undefined,
        __data,
        undefined,
        undefined,
        undefined,
        currentApp?.env
      ),
      method: method?.toLowerCase?.(),
      headers: {
        ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
        ...iterate(headers, item, selectedRowFromOtherTables, isUserInput, userInputsValues, null, __data, currentApp?.env),
      },
      data: body && iterate(JSON.parse(body || "{}"), item, selectedRowFromOtherTables, isUserInput, userInputsValues, null, __data),
    });
  };

  const { mutate, isLoading } = useMutation(onAction, {
    onSuccess: async (action: any, variables, context) => {
      const statusMessage = variables?.statusMessages?.find((item: any) => item?.key == action?.request?.status)?.value;
      enqueueSnackbarRef?.(
        replaceItemPlaceholders(
          statusMessage,
          item,
          selectedRowFromOtherTables,
          userInputsValues,
          isUserInput,
          undefined,
          __data,
          undefined,
          action?.data,
          undefined,
          currentApp?.env
        ) || "Posted Successfully",
        {
          variant: "success",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        }
      );

      if (variables?.isDismissibleView != "No") {
        closeModal?.();
      }
      queryClient.refetchQueries([gridId]);
      if (parentIds) {
        parentIds.forEach((id: any) => queryClient.refetchQueries([id]));
      }
      const { method } = action || {};
      // success: remove item from data
      if (method?.toLowerCase() === "delete") {
      } else if (method?.toLowerCase() === "post") {
        // update record because it is an item action
      } else if (method?.toLowerCase() === "put") {
        // update record because it is an item action
      } else if (method?.toLowerCase() === "patch") {
        // update record because it is an item action
      }
    },
    onError: (error: any, variables) => {
      const statusMessage = variables?.statusMessages?.find((item: any) => item?.key == error?.requestStatusCode)?.value;

      enqueueSnackbarRef?.(
        replaceItemPlaceholders(
          statusMessage,
          item,
          selectedRowFromOtherTables,
          userInputsValues,
          isUserInput,
          undefined,
          __data,
          undefined,
          error,
          undefined,
          currentApp?.env
        ) ||
        error?.response?.data?.error ||
        JSON.parse(error?.response?.config?.data || "{}")?.errorMessage ||
        "Wrong Services",
        {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        }
      );
      // setError(error || "Something went wrong");
    },

    mutationKey: id,
  });

  const handleActionClick = (action: any) => {
    if (action?.dialog?.enabled && !openConfirmation) setOpenConfirmation(true);
    else {
      mutate(action);
    }
  };

  return (
    <Card style={{ backgroundColor: theme.palette.background }} className={classes.followerBlock} key={id} sx={{ boxShadow: 7 }}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} sx={{ "&.MuiGrid-root": { pt: 2 } }}>
          {renderCoverCard(lowUri, highUri, cover)}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} paddingX={1.5} pb={1}>
            {viewType == "card-list" && (
              <>
                <Grid item>
                  <Avatar
                    alt={creator?.name}
                    src={
                      replaceItemPlaceholders(
                        dataSource?.videoUserImage,
                        coreData,
                        selectedRowFromOtherTables,
                        userInputsValues,
                        isUserInput,
                        undefined,
                        __data,
                        null,
                        undefined,
                        undefined,
                        currentApp?.env
                      ) || creator?.profilePhoto
                    }
                  />
                </Grid>
                <Grid item xs sx={{ overflow: "hidden" }}>
                  <Typography
                    className={classes.elliptSingleLine}
                    variant='caption'
                    component='div'
                    sx={{ color: theme.palette.grey[700], minHeight: 20 }}
                  >
                    {replaceItemPlaceholders(
                      dataSource?.videoTitle,
                      coreData,
                      selectedRowFromOtherTables,
                      userInputsValues,
                      isUserInput,
                      undefined,
                      __data,
                      null,
                      undefined,
                      undefined,
                      currentApp?.env
                    ) || creator?.name}
                  </Typography>
                  <Typography variant='caption' color='primary' fontWeight={600} component={Link} to={`/user/edit/${creator?.id}`}>
                    @
                    {replaceItemPlaceholders(
                      dataSource?.videoUsername,
                      coreData,
                      selectedRowFromOtherTables,
                      userInputsValues,
                      isUserInput,
                      undefined,
                      __data,
                      null,
                      undefined,
                      undefined,
                      currentApp?.env
                    ) || creator?.handle}
                  </Typography>
                  <br />
                  <Tooltip title={caption || ""}>
                    <Typography
                      height={20}
                      variant='subtitle2'
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        width: "100%",
                      }}
                    >
                      {replaceItemPlaceholders(
                        dataSource?.videoSubTitle,
                        coreData,
                        selectedRowFromOtherTables,
                        userInputsValues,
                        isUserInput,
                        undefined,
                        __data,
                        null,
                        undefined,
                        undefined,
                        currentApp?.env
                      ) || caption}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <BXModal icon={<IconCode />} title='Api Mode' maxWidth='lg' withoutLabel>
                    {(handleClose: any) => {
                      return (
                        <>
                          <ReactJson src={coreData as any} theme={"paraiso"} style={{ width: "100%" }} />
                        </>
                      );
                    }}
                  </BXModal>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={3} textAlign='center'>
                      <Typography variant='h6'>{stats?.playCount || 0}</Typography>
                      <Typography variant='caption'>Views</Typography>
                    </Grid>
                    <Grid item xs={3} textAlign='center'>
                      <Typography variant='h6'>{stats?.commentCount || 0}</Typography>
                      <Typography variant='caption'>Comments</Typography>
                    </Grid>
                    <Grid item xs={3} textAlign='center'>
                      <Typography variant='h6'>{stats?.likeCount || 0}</Typography>
                      <Typography variant='caption'>Likes</Typography>
                    </Grid>
                    <Grid item xs={3} textAlign='center'>
                      <Typography variant='h6'>{prettyTime || 0}</Typography>
                      <Typography variant='caption'>Age</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Grid container flexWrap='wrap' justifyContent='space-between' marginLeft='-5px' width='calc(100% + 5px)'>
                {actions?.map((action, index) => {
                  const { actionSourceType, showModal, modalSize, actionViewType } = action;
                  const isAPI = _.isNaN(actionSourceType?.type) || actionSourceType?.type == "API";
                  const isDownload = actionSourceType?.type == "Download";
                  const selectedView = views?.find((view: any) => view?.id == actionSourceType?.id);
                  const isStack = actionViewType == "Stack";

                  const data = replaceItemPlaceholders(
                    action?.condition,
                    item,
                    selectedRowFromOtherTables,
                    userInputsValues,
                    isUserInput,
                    undefined,
                    __data,
                    null,
                    undefined,
                    undefined,
                    currentApp?.env
                  );

                  const highlightedValue = replaceItemPlaceholders(
                    action?.highlightedValue,
                    item,
                    selectedRowFromOtherTables,
                    userInputsValues,
                    isUserInput,
                    undefined,
                    __data,
                    null,
                    undefined,
                    undefined,
                    currentApp?.env
                  );

                  if ((!eval(data) && !_.isEmpty(data)) || action.isGlobal) return <></>;

                  if (isAPI) {
                    return (
                      <Fragment key={`${action?.id}-${index}`}>
                        {action?.dialog?.enabled && (
                          <BXConfirmationDialog
                            open={openConfirmation}
                            title={action?.dialog?.message || "Are you sure?"}
                            iconButton
                            onConfirm={() => {
                              handleActionClick(action);
                              setOpenConfirmation(false);
                            }}
                            onCancel={() => {
                              setOpenConfirmation(false);
                            }}
                          />
                        )}
                        {!showModal || showModal == "No" ? (
                          <Tooltip placement='bottom' title={action?.label}>
                            <IconButton
                              className={classNames(classes.btnActionProfile, {
                                [classes.btnActionProfileSelected]: eval(highlightedValue),
                              })}
                              aria-label={action?.label}
                              data-id={id}
                              onClick={() => handleActionClick(action)}
                            >
                              <BXIcon icon={action?.icon} width={20} height={20} color={"currentColor"} />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <BXModal
                            label={action.label}
                            icon={<BXIcon icon={action?.icon} width={20} height={20} color={"currentColor"} />}
                            buttonProps={{
                              variant: "contained",
                              startIcon: <BXIcon icon={action?.icon} width={20} height={20} color={"currentColor"} />,
                              className: classNames(classes.btnActionProfile, {
                                [classes.btnActionProfileSelected]: eval(highlightedValue),
                              }),
                            }}
                            title={action.label}
                            withoutLabel={true}
                          >
                            {(handleClose: Function) => {
                              return (
                                <CreatePayload
                                  payload={{
                                    body: formatJSON(
                                      JSON.stringify(
                                        iterate(
                                          JSON.parse(action?.body || "{}"),
                                          item,
                                          selectedRowFromOtherTables,
                                          isUserInput,
                                          userInputsValues,
                                          null,
                                          __data,
                                          currentApp?.env
                                        )
                                      )
                                    ),
                                    headers: iterate(
                                      { ...action?.headers },
                                      item,
                                      selectedRowFromOtherTables,
                                      isUserInput,
                                      userInputsValues,
                                      null,
                                      __data,
                                      currentApp?.env
                                    ),
                                  }}
                                  onSave={(payload: any) => {
                                    handleActionClick({ ...action, ...payload });
                                    handleClose?.();
                                  }}
                                  label={action?.label}
                                  onClose={() => handleClose()}
                                  isLoading={isLoading}
                                />
                              );
                            }}
                          </BXModal>
                        )}
                      </Fragment>
                    );
                  } else if (isDownload) {
                    return (
                      <Tooltip key={action?.id} placement='bottom' title={action?.label}>
                        <IconButton
                          className={classes.btnActionProfile}
                          aria-label={action?.label}
                          data-id={id}
                          onClick={async () => {
                            const data =
                              replaceItemPlaceholders(
                                action.source,
                                item,
                                selectedRowFromOtherTables,
                                userInputsValues,
                                isUserInput,
                                undefined,
                                __data,
                                null,
                                undefined,
                                undefined,
                                currentApp?.env
                              ) || "";

                            const token = localStorage.getItem(currentApp?.id + `-${currentProfileId}-accessToken`);

                            let _data = "";
                            if (action?.downloadType === "api") {
                              const { data } = await axios.get(
                                replaceItemPlaceholders(
                                  action?.downloadEndpoint,
                                  item,
                                  selectedRowFromOtherTables,
                                  userInputsValues,
                                  isUserInput,
                                  undefined,
                                  __data,
                                  undefined,
                                  undefined,
                                  undefined,
                                  currentApp?.env
                                ),
                                {
                                  headers: {
                                    ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
                                  },
                                }
                              );
                              _data = data?.[action?.downloadKey];
                            }

                            const url = !action.source ? (item?.dataString ? item?.dataString : "") : data;

                            fetch(_data || url)
                              .then(response => response.blob())
                              .then(blob => {
                                const url = URL.createObjectURL(blob);
                                if (action.openImage) {
                                  return window.open(url, "_blank");
                                }
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download", blob.type);
                                document.body.appendChild(link);
                                link.click();
                              });
                          }}
                        >
                          <BXIcon icon={action?.icon} width={20} height={20} color={"currentColor"} />
                        </IconButton>
                      </Tooltip>
                    );
                  } else if (isStack) {
                    return (
                      <Tooltip key={action?.id} title={action?.label}>
                        <IconButton
                          onClick={e => {
                            e.stopPropagation();
                            onSelectRow?.(item, true);
                            setViewStacks(
                              prev =>
                                [
                                  ...prev,
                                  <BXEngine
                                    auth={{}}
                                    page={{ views, layout: selectedView } as any}
                                    layout={[{ ...(selectedView as any), type: actionSourceType?.type }]}
                                    isVisible
                                    selectedViewId={item.id}
                                    __data={{
                                      ...__data,
                                      [(getLastKeyFromObject(__data) || "") + "#"]: item,
                                    }}
                                    index={cardIndex}
                                    closeModal={closeModal}
                                    parentIds={[...parentIds, gridId]}
                                    prevVideoCardId={prevId}
                                  />,
                                ] as UIElement[]
                            );
                          }}
                          aria-label={action?.label}
                          data-id={id}
                          className={classNames(classes.btnActionProfile)}
                        >
                          <BXIcon icon={action?.icon} width={20} height={20} color={"currentColor"} />
                        </IconButton>
                      </Tooltip>
                    );
                  } else {
                    return (
                      <Fragment key={action?.id}>
                        <ViewerModal
                          icon={<BXIcon icon={action?.icon} width={20} height={20} color={"currentColor"} />}
                          action={action}
                          actionSourceType={actionSourceType}
                          item={item}
                          index={cardIndex}
                          showHeader={false}
                          selectedView={selectedView}
                          onSelectRow={onSelectRow}
                          views={views}
                          buttonProps={{
                            variant: "",
                            className: classes.btnActionProfile,
                            "aria-label": action?.label,
                          }}
                          withoutLabel
                          modalSize={modalSize}
                          __data={__data}
                          id={gridId}
                          prevVideoCardId={prevId}
                          parentIds={parentIds}
                        />
                      </Fragment>
                    );
                  }
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
