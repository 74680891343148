import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import _ from "lodash";
import { FC, useEffect } from "react";
import { FieldValues, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { BXInput } from "src/components/BXUI/FormControls";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import { v4 as uuid } from "uuid";
import * as yup from "yup";
import CreateDynamicFields from "../AppBuilder/forms/CreateDynamicFields";

type CreateEnvFormProps = {
  onSave: SubmitHandler<FieldValues>;
  onCancel?: Function;
  row?: any;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateEnvForm: FC<CreateEnvFormProps> = ({ onCancel = _.noop, onSave = _.noop, row, setIsDirty }) => {
  const schema = yup
    .object({
      name: yup.string().required(),
    })
    .required();

  const defaultValues = {
    name: row?.name,
    config: {
      variables: _.map(row?.config?.variables, (value, key) => {
        return { id: uuid(), key, value };
      }),
    },
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<FieldValues>({
    defaultValues,
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: `config.variables`,
  });

  useCallbackPrompt(isDirty);

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);

  return (
    <Box flex={1}>
      <Grid container spacing={3}>
        <Grid item xs={6} my={2}>
          <BXInput name={"name"} control={control} label='Name' variant='outlined' error={errors.name} />
        </Grid>

        <Grid item xs={12}>
          <Typography>Variables:</Typography>
        </Grid>

        <CreateDynamicFields fields={fields} remove={remove} append={append} control={control} handleSubmit={handleSubmit(onSave)} />
      </Grid>
    </Box>
  );
};
