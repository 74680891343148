import { appColor } from "./themes/colors";

const PageNotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100%",
        backgroundColor: appColor.background,
        alignItems: "center",
        flexDirection: "column",
        paddingTop: 100,
      }}
    >
      <span style={{ fontSize: 42, color: "white" }}>Page not found</span>
      <span style={{ fontSize: 30, color: "white" }}>404</span>
    </div>
  );
};

export default PageNotFound;
