import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Container, DialogActions, FormControl, Grid } from "@mui/material";
import _ from "lodash";
import { FC, useEffect } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { BXInput, BXSelect } from "src/components/BXUI/FormControls";
import { BXIconPicker } from "src/components/BXUI/FormControls/IconPicker";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import { BXPageType, PageLayout } from "src/types/BXPageType";
import * as yup from "yup";

type CreatePageFormProps = {
  onSave: SubmitHandler<FieldValues>;
  onCancel: Function;
  page?: BXPageType;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
};

const schema = yup
  .object({
    title: yup.string().required(),
    slug: yup.string().required(),
    name: yup.string().required(),
    icon: yup.string(),
    inProfileMenu: yup.boolean(),
    layout: yup.string().required(),
  })
  .required();

const layoutOptions: { value: PageLayout; label: string }[] = [
  { value: "stack", label: "Single" },
  { value: "switch", label: "Switch" },
  { value: "default", label: "Default" },
];

export const CreatePageForm: FC<CreatePageFormProps> = ({ onCancel = _.noop, onSave = _.noop, page, setIsDirty }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
  } = useForm<FieldValues>({
    defaultValues: { ...(page || { layout: "default" }) },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  useCallbackPrompt(isDirty);

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);

  return (
    <Box component='form' noValidate autoComplete='off'>
      <Container maxWidth={"sm"}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <BXSelect
                options={layoutOptions}
                label={"Layout"}
                name={"layout"}
                id={"bx-page-create-layout-input"}
                error={errors.layout as any}
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <BXInput name={"title"} control={control} label='Page Title' variant='outlined' id={"bx-page-create-title-input"} />
          </Grid>
          <Grid item xs={12}>
            <BXInput
              name={"name"}
              control={control}
              label='Name'
              variant='outlined'
              id={"bx-page-create-name-input"}
              onChange={e => {
                setValue("slug", `/${e.target.value.toLowerCase().replaceAll(/  */g, "-")}`);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <BXInput
              name={"slug"}
              helperText={"/app/collection/page"}
              control={control}
              label='Slug'
              variant='outlined'
              id={"bx-view-create-slug-input"}
            />
          </Grid>

          <Grid item xs={12}>
            <BXIconPicker label={"Icon"} control={control} name={"icon"} id={"bx-page-create-icon-input"} />
          </Grid>
        </Grid>
      </Container>
      <Grid item xs={12}>
        <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
          <Button onClick={handleSubmit(onSave)} variant={"contained"} aria-label={"save"}>
            Save
          </Button>
        </DialogActions>
      </Grid>
    </Box>
  );
};
