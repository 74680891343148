import { PixelCrop } from "react-image-crop";

export const getLastKeyFromObject = (obj = {} as any) => {
  const keys = Object.keys(obj);
  return keys[keys?.length - 1];
};

export const reverseDataOfObjectValues = (obj = {} as any) => {
  const values = Object.values(obj).reverse();
  const result = {} as any;
  Object.keys(obj).forEach((key, index) => {
    result[key] = values[index];
  });

  return result;
};

export const getHashesFromString = (string = "") => {
  return string
    .split("")
    .filter((key: any) => key == "#")
    .join("");
};

export function dereferenceOAS(oas: any) {
  // Create a map to store the dereferenced definitions
  const definitions = new Map();

  // Recursively dereference the definitions
  function dereference(obj: any): any {
    // If the object is a reference, look up the definition and return it
    if (obj.$ref) {
      const definition = definitions.get(obj.$ref);
      return definition ? dereference(definition) : obj;
    }

    // If the object is an array, dereference each element in the array
    if (Array.isArray(obj)) {
      return obj.map(dereference);
    }

    // If the object is an object, dereference each property in the object
    if (typeof obj === "object") {
      const dereferencedObj: any = {};
      for (const key of Object.keys(obj)) {
        dereferencedObj[key] = dereference(obj[key]);
      }
      return dereferencedObj;
    }

    // If the object is not a reference or an object, return it as is
    return obj;
  }

  // Determine which property to use for the definitions, depending on the OAS version
  let definitionsProperty;
  if (oas.swagger === "2.0") {
    definitionsProperty = "definitions";
  } else if (oas.openapi) {
    definitionsProperty = "components.schemas";
  } else {
    throw new Error("Unrecognized OAS version");
  }

  // Populate the definitions map with the dereferenced definitions from the OAS object
  if (oas[definitionsProperty]) {
    for (const definitionName of Object.keys(oas[definitionsProperty])) {
      definitions.set(`#/${definitionsProperty}/${definitionName}`, oas[definitionsProperty][definitionName]);
    }
  }

  // Dereference the OAS object and return it
  return dereference(oas);
}

export const convertToProperCase = (text: string) => {
  // Split the text into an array of words
  const words = text.split(/(?=[A-Z])/);

  // Map the array of words to an array of capitalized words
  const capitalizedWords = words.map(word => {
    // Get the first letter of the word and convert it to uppercase
    const firstLetter = word[0].toUpperCase();

    // Get the rest of the word and convert it to lowercase
    const rest = word.slice(1).toLowerCase();

    // Concatenate the first letter and the rest of the word
    return firstLetter + rest;
  });

  // Join the array of capitalized words with spaces and return the result
  return capitalizedWords.join(" ");
};

export async function getCroppedImageFile(image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop, callback?: any) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);

  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);
  canvas.toBlob(function (blob) {
    var file = new File([blob!], "coppedImage", { type: blob!.type });

    callback?.(file);
  });
  ctx.restore();
}

export const getAuthorizationHeader = (auth: any, token?: any) => {
  const authorization: any = {
    Authorization: undefined,
  };
  const authType = auth?.type;
  if (authType === "Buildx") {
    authorization.Authorization = !auth?.authApi ? "" : "Bearer " + token;
  } else if (authType === "API Key") {
    authorization[auth?.keyValue!] = auth?.apiValue;
  } else if (authType === "Basic Auth") {
    authorization.Authorization = "Basic " + btoa(auth?.basicAuthUsername + ":" + auth?.basicAuthPassword);
  } else if (authType === "No Auth") {
  } else {
    authorization.Authorization = !auth?.authApi && authType ? "" : "Bearer " + token || localStorage.getItem("accessToken");
  }
  return authorization;
};
