import React, { createElement, useRef } from "react";

const ResizeElement = ({
  width: initialWidth,
  height: initialHeight,
  stepper = 10,
  children,
  onResize,
}: {
  width: number;
  height: number;
  stepper?: number;
  onResize?: any;
  children: React.ReactNode;
}) => {
  // const [width, setWidth] = useState(initialWidth);
  // const [height, setHeight] = useState(initialHeight);
  const ref = useRef<HTMLDivElement>(null);

  const handleMouseDown = (axis: "x" | "y" | "both") => (e: React.MouseEvent<HTMLDivElement>) => {
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = ref.current?.clientWidth || 0;
    const startHeight = ref.current?.clientHeight || 0;

    const handleMouseMove = (e: MouseEvent) => {
      const dx = e.clientX - startX;
      const dy = e.clientY - startY;
      let newWidth = startWidth;
      let newHeight = startHeight;

      if (axis === "x" || axis === "both") {
        newWidth = startWidth + Math.round(dx / stepper) * stepper;
      }

      if (axis === "y" || axis === "both") {
        newHeight = startHeight + Math.round(dy / stepper) * stepper;
      }
      onResize?.({ width: newWidth, height: newHeight });
      // setWidth(newWidth);
      // setHeight(newHeight);
    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove as any);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const _children = React.Children?.map(children, child => child);
  const firstChild = _children?.[0] as React.ReactElement;

  return (
    <>
      {createElement(
        firstChild?.type || "div",
        { ...firstChild?.props, ref },
        <>
          {firstChild?.props?.children}
          <div
            style={{
              position: "absolute",
              bottom: -5,
              right: -5,
              width: "10px",
              height: "10px",
              border: "1px solid black",
              borderRadius: "50%",
              backgroundColor: "white",
              cursor: "nwse-resize",
            }}
            onMouseDown={handleMouseDown("both")}
          />
        </>
      )}
    </>
  );
};

export { ResizeElement };
