import { Divider } from "@mui/material";
import React, { createElement, Fragment, FunctionComponent } from "react";
import { BXAPi } from "src/components/BXUI/API";
import { BXAutoComplete } from "src/components/BXUI/autoComplete";
import { BXCardList } from "src/components/BXUI/CardList";
import { BXDataTable } from "src/components/BXUI/DataTable";
import { Form } from "src/components/BXUI/Form";
import { FormBuilder } from "src/components/BXUI/FormBuilder";
import { BXFormTable } from "src/components/BXUI/FormTable";
import { BXKeyValue } from "src/components/BXUI/KeyValue";
import BXPage from "src/components/BXUI/Page";
import { Upload } from "src/components/BXUI/Upload";
import { BXPageType } from "src/types/BXPageType";
import { UIElement } from "src/types/UIElement";

export const KeysToComponentMap: { [id: string]: any } = {
  "data-table": BXDataTable,
  "card-list": BXCardList,
  "image-grid": BXCardList,
  formTable: BXFormTable,
  form: Form,
  upload: Upload,
  api: BXAPi,
  page: BXPage,
  autoComplete: BXAutoComplete,
  divider: Divider,
  keyValue: BXKeyValue,
  "form-builder": FormBuilder,
};

export const isNativeComponent = (tagName: string) => {
  return ["div", "p", "span", "h1", "h2", "h3", "h4", "h5", "h6", "figure", "img", "section", "header", "strong", "article"].includes(
    tagName
  );
};

type EngineProps = {
  layout?: UIElement[];
  auth: any;
  page?: BXPageType;
  isVisible?: boolean;
  [key: string]: any;
};

export const BXEngine: FunctionComponent<EngineProps> = ({ layout = [], page, isVisible, ...rest }) => {
  const createElementRecursively = (elements: Array<UIElement | string | number>): React.ReactElement | undefined | string => {
    const computeElement = (type: string) => {
      if (isNativeComponent(type)) {
        return type;
      }
      return KeysToComponentMap[type] || React.Fragment;
    };

    return (
      <Fragment>
        {/* @ts-ignore */}
        {elements.map((element: UIElement | string | number) => {
          // check for text nodes
          if (["string", "number"].includes(typeof element)) {
            return element;
          }
          element = element as UIElement;

          if (element.info?.visibility == "Hidden" && !isVisible) return <></>;

          return createElement(
            computeElement(element.type),
            {
              ...element,
              views: isNativeComponent(element.type) ? undefined : page?.views,
              layout: isNativeComponent(element.type) ? undefined : page?.layout,
              className: [element.className].join(" "),
              disabled: element.disabled,
              info: isNativeComponent(element.type) ? undefined : element.info,
              id: element.id,
              key: element.id,
              ...element.config,
              dataSource: isNativeComponent(element.type) ? undefined : element.dataSource,
              columns: isNativeComponent(element.type) ? undefined : element.config?.columns,
              ...(isNativeComponent(element.type) ? {} : rest),
            },
            element.children && element.children.length > 0 ? <Fragment>{createElementRecursively(element.children)}</Fragment> : null
          );
        })}
      </Fragment>
    );
  };

  return <>{createElementRecursively(layout)}</>;
};
