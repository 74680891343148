import _ from "lodash";
import React, { createContext, Dispatch, FC, useContext, useState } from "react";
import { BXApp, BXAppCollection } from "src/types/BXAppType";
import { BXPageType } from "src/types/BXPageType";
import { UIElement } from "src/types/UIElement";
import axiosServices from "src/utils/axios";
import { useBXContext } from "./BXContext";

type BXContextProps = {
  setExpandedPage: Dispatch<any>;
  expandedPage?: BXPageType | null;
  apps: BXApp[];
  setApps: React.Dispatch<React.SetStateAction<BXApp[]>>;
  addApp: (app: BXApp) => void;
  deleteApp: (app: BXApp) => void;
  editApp: (id: string, app: BXApp) => void;
  editApps: (app: BXApp[]) => void;
  addCollection: (appId: string, collection: BXAppCollection, onSuccess?: () => void) => void;
  editCollection: (appId: string, collectionId: string, collection: BXAppCollection) => void;
  deleteCollection: (appId: string, collectionId: string, onSuccess?: () => void) => void;
  addPage: (appId: string, collectionId: string, page: BXPageType, onSuccess?: () => void) => void;
  editPage: (appId: string, collectionId: string, pageID: string, page: BXPageType) => void;
  deletePage: (appId: string, collectionId: string, pageID: string, onSuccess?: () => void) => void;
  addView: (appId: string, collectionId: string, pageId: string, view: UIElement, onSuccess?: () => void) => void;
  addViews: (appId: string, collectionId: string, pageId: string, view: UIElement[], onSuccess?: () => void) => void;
  editView: (appId: string, collectionId: string, pageId: string, viewId: string, view: UIElement, onSuccess?: () => void) => void;
  deleteView: (appId: string, collectionId: string, pageId: string, viewId: string, onSuccess?: () => void) => void;
  deleteViews: (appId: string, collectionId: string, pageId: string, viewIds: string[], onSuccess?: () => void) => void;
};
export const BXBuilderContext = createContext<BXContextProps>({
  setExpandedPage: _.noop,
  expandedPage: null,
  setApps: () => {},
  apps: [],
  addApp: () => {},
  editApp: () => {},
  editApps: () => {},
  addCollection: () => {},
  editCollection: () => {},
  deleteCollection: () => {},
  addPage: () => {},
  editPage: () => {},
  deletePage: () => {},
  addView: () => {},
  addViews: () => {},
  editView: () => {},
  deleteView: () => {},
  deleteViews: () => {},
  deleteApp: (app: BXApp) => {},
});

/**
 *
 * @param children
 * @constructor
 */
export const BXBuilderContextProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [expandedPage, setExpandedPage] = useState<BXPageType | null>(null);
  const { appDescriptor: apps, setAppDescriptor: setApps, setCurrentApp, currentApp } = useBXContext();

  const addApp = (app: BXApp) => {
    return axiosServices.post("/admin/application", app).then(value => {
      setApps((old: BXApp[]) => {
        if (!old?.length) {
          setCurrentApp(value.data);
        }
        return [...old, value.data];
      });
    });
  };

  const deleteApp = (app: BXApp) => {
    return axiosServices.delete(`/admin/application/${app.id}`).then(value => {
      setApps((old: BXApp[]) => old.filter(oldApp => oldApp.id !== app.id));
      if (app.id == currentApp?.id) {
        setCurrentApp(apps?.[0]);
      }
    });
  };

  const editApp = (id: string, app: BXApp, onSuccess?: () => void) => {
    return axiosServices.put(`/admin/application/${id}`, app).then(value => {
      setApps((apps: BXApp[]) => {
        const updatedApps = apps.map(oldApp => (oldApp.id === id ? app : oldApp));
        if (app.id == currentApp?.id) {
          setCurrentApp(app);
        }

        return _.cloneDeep(updatedApps);
      });
      setTimeout(() => {
        onSuccess?.();
      }, 0);
    });
  };

  const editApps = (apps: BXApp[]) => {
    const promises = apps.map(app => axiosServices.put(`/admin/application/${app.id}`, app));

    Promise.all(promises).then(values => {
      setApps(values.map(value => value.data));
    });
  };

  const addCollection = (appId: string, collection: BXAppCollection, onSuccess?: () => void) => {
    try {
      const appToEdit = apps.find(app => app.id === appId)!;
      let data = appToEdit;

      if (Array.isArray(appToEdit?.templateConfig?.collections)) {
        data = {
          ...appToEdit,
          templateConfig: {
            ...appToEdit.templateConfig,
            collections: appToEdit!.templateConfig!.collections.concat([collection]),
          },
        };
      } else {
        _.set(data, "templateConfig.collections", [collection]);
      }

      editApp(appId, data, onSuccess);
    } catch (e) {}
  };

  const editCollection = (appId: string, collectionId: string, collection: BXAppCollection, onSuccess?: () => void) => {
    setApps((apps: BXApp[]) => {
      const appToEdit = apps.find(app => app.id === appId);
      if (appToEdit) {
        // add to the collection
        if (Array.isArray(appToEdit?.templateConfig?.collections)) {
          const data: BXApp = {
            ...appToEdit,
            templateConfig: {
              ...appToEdit.templateConfig,
              collections: appToEdit.templateConfig!.collections.map(coll => (coll.id === collectionId ? collection : coll)),
            },
          };
          editApp(appId, data, onSuccess);
        }
      }
      return apps;
    });
  };

  const deleteCollection = (appId: string, collectionId: string, onSuccess?: () => void) => {
    const appToEdit = apps.find(app => app.id === appId);
    if (appToEdit) {
      // add to the collection
      if (Array.isArray(appToEdit?.templateConfig?.collections)) {
        const data: BXApp = {
          ...appToEdit,
          templateConfig: {
            ...appToEdit.templateConfig,
            collections: appToEdit.templateConfig!.collections.filter(coll => coll.id !== collectionId),
          },
        };
        editApp(appId, data, onSuccess);
      }
    }
  };

  const addPage = (appId: string, collectionId: string, page: BXPageType, onSuccess?: () => void) => {
    let collectionToEdit = apps.find(app => app.id === appId)?.templateConfig?.collections?.find(coll => coll.id === collectionId) || null;
    if (collectionToEdit) {
      editCollection(appId, collectionId, { ...collectionToEdit, pages: [page, ...collectionToEdit.pages] }, onSuccess);
    }
  };

  const editPage = (appId: string, collectionId: string, pageId: string, page: BXPageType, onSuccess?: () => void) => {
    setApps((apps: BXApp[]) => {
      let collectionToEdit =
        apps.find(app => app.id === appId)?.templateConfig?.collections?.find(coll => coll.id === collectionId) || null;
      if (collectionToEdit) {
        editCollection(
          appId,
          collectionId,
          {
            ...collectionToEdit,
            pages: collectionToEdit.pages.map((p: BXPageType) => (p.id === pageId ? page : p)),
          },
          onSuccess
        );
      }
      return apps;
    });
  };

  const deletePage = (appId: string, collectionId: string, pageId: string, onSuccess?: () => void) => {
    let collectionToEdit = apps.find(app => app.id === appId)?.templateConfig?.collections?.find(coll => coll.id === collectionId) || null;
    if (collectionToEdit) {
      editCollection(
        appId,
        collectionId,
        {
          ...collectionToEdit,
          pages: collectionToEdit.pages.filter((p: BXPageType) => p.id !== pageId),
        },
        onSuccess
      );
    }
  };

  const addView = (appId: string, collectionId: string, pageId: string, view: UIElement, onSuccess?: () => void) => {
    let pageToEdit =
      apps
        .find(app => app.id === appId)
        ?.templateConfig?.collections?.find(coll => coll.id === collectionId)
        ?.pages?.find(page => page.id === pageId) || null;
    if (pageToEdit) {
      editPage(appId, collectionId, pageId, { ...pageToEdit, views: [...(pageToEdit?.views || []), view] }, onSuccess);
    }
  };

  const addViews = (appId: string, collectionId: string, pageId: string, views: UIElement[], onSuccess?: () => void) => {
    let pageToEdit =
      apps
        .find(app => app.id === appId)
        ?.templateConfig?.collections?.find(coll => coll.id === collectionId)
        ?.pages?.find(page => page.id === pageId) || null;
    if (pageToEdit) {
      editPage(appId, collectionId, pageId, { ...pageToEdit, views: [...(pageToEdit?.views || []), ...views] }, onSuccess);
    }
  };

  const editView = (appId: string, collectionId: string, pageId: string, viewId: string, view: UIElement, onSuccess?: () => void) => {
    setApps((apps: BXApp[]) => {
      let pageToEdit =
        apps
          .find(app => app.id === appId)
          ?.templateConfig?.collections?.find(coll => coll.id === collectionId)
          ?.pages?.find(page => page.id === pageId) || null;
      if (pageToEdit) {
        const data = {
          ...pageToEdit,
          views: pageToEdit?.views.map(v => (v.id === viewId ? view : v)),
        };
        editPage(appId, collectionId, pageId, data, onSuccess);
      }
      return apps;
    });
  };

  const deleteView = (appId: string, collectionId: string, pageId: string, viewId: string, onSuccess?: () => void) => {
    let pageToEdit =
      apps
        .find(app => app.id === appId)
        ?.templateConfig?.collections?.find(coll => coll.id === collectionId)
        ?.pages?.find(page => page.id === pageId) || null;
    if (pageToEdit) {
      const data = {
        ...pageToEdit,
        views: pageToEdit.views.filter(v => v.id !== viewId),
      };
      editPage(appId, collectionId, pageId, data, onSuccess);
    }
  };

  const deleteViews = (appId: string, collectionId: string, pageId: string, viewIds: string[], onSuccess?: () => void) => {
    let pageToEdit =
      apps
        .find(app => app.id === appId)
        ?.templateConfig?.collections?.find(coll => coll.id === collectionId)
        ?.pages?.find(page => page.id === pageId) || null;
    if (pageToEdit) {
      const data = {
        ...pageToEdit,
        views: pageToEdit.views.filter(v => !viewIds?.includes(v.id)),
      };
      editPage(appId, collectionId, pageId, data, onSuccess);
    }
  };

  return (
    <BXBuilderContext.Provider
      value={{
        expandedPage,
        setExpandedPage,
        apps,
        setApps,
        addApp,
        editApp,
        editApps,
        deleteApp,
        addCollection,
        editCollection,
        deleteCollection,
        addPage,
        editPage,
        deletePage,
        addView,
        addViews,
        editView,
        deleteView,
        deleteViews,
      }}
    >
      {children}
    </BXBuilderContext.Provider>
  );
};

/**
 * consumer of BX Context
 */
export const useBXBuilderContext = () => useContext(BXBuilderContext);
