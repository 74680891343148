import { TablerIconProps } from "@tabler/icons";
import { FC } from "react";
type BXIconProps = {
  fallBack?: string;
  icon?: string;
} & TablerIconProps;

export const BXIcon: FC<BXIconProps> = ({ icon, fallBack, height = 30, width, ...rest }) => {
  const icons = require("@tabler/icons");
  if (icon?.toLowerCase().match(/http[s]*:\/\//)) {
    return <img src={icon} height={height} width={width} />;
  }
  if (icons.hasOwnProperty(`Icon${icon}`)) {
    return icons?.[`Icon${icon}`]?.({ size: height || 24, ...rest });
  }
  if (!fallBack) return null;
  return icons?.[`Icon${fallBack}`]?.({ size: height || 24, ...rest }) || null;
};
