import { Button, Grid, IconButton, Typography } from "@mui/material";
import { IconTrashX } from "@tabler/icons";
import { useFieldArray } from "react-hook-form";
import { BXInput } from "src/components/BXUI/FormControls";
import { v4 as uuid } from "uuid";

const StatusMessages = (props: any) => {
  const { actionIndex, control, namePrefix } = props;

  const {
    fields: statusMessages,
    append: appendStatusMessage,
    remove: removeStatusMessage,
  } = useFieldArray({
    control,
    name: `${namePrefix}.statusMessages`,
  });

  return (
    <Grid item xs={12}>
      <Typography mb={2}>Status Messages:</Typography>
      {statusMessages?.map((item: any, index: number) => {
        return (
          <Grid container spacing={2} mb={2} key={item.id}>
            <Grid item xs>
              <BXInput size='small' fullWidth label={"Status"} name={`${namePrefix}.statusMessages[${index}].key`} control={control} />
            </Grid>
            <Grid item xs>
              <BXInput size='small' fullWidth label={"Message"} name={`${namePrefix}.statusMessages[${index}].value`} control={control} />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={() => {
                  removeStatusMessage(index);
                }}
              >
                <IconTrashX color='red' />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
      <Button
        variant='outlined'
        onClick={() => {
          appendStatusMessage({ id: uuid(), key: "", value: "" });
        }}
      >
        Add row
      </Button>
    </Grid>
  );
};

export default StatusMessages;
