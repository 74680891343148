import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, DialogActions, Grid, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { FC, useEffect } from "react";
import { FieldValues, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { BXInput } from "src/components/BXUI/FormControls";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import * as yup from "yup";
import CreateDynamicFields from "../AppBuilder/forms/CreateDynamicFields";

type CreateTableFormProps = {
  onSave: SubmitHandler<FieldValues>;
  onCancel?: Function;
  editing?: boolean;
  row?: any;
  height?: string | number;
  withoutPassword?: boolean;
  onlyPassword?: boolean;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateTableForm: FC<CreateTableFormProps> = ({ onCancel = _.noop, onSave = _.noop, height, setIsDirty, row = {} }) => {
  const { palette } = useTheme();
  const schema = yup
    .object({
      name: yup.string().required(),
      config: yup.object().shape({}),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    watch,
  } = useForm<FieldValues>({
    defaultValues: {
      ...row,
      config: {
        ...row?.config,
      },
    },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "config.columns",
  });

  useCallbackPrompt(isDirty);

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);

  return (
    <Box component='form' noValidate autoComplete='off' flex={1} height={height}>
      <Grid container spacing={3} px={2} py={1}>
        <Grid item xs={6}>
          <BXInput name={"name"} control={control} label='Name' variant='outlined' error={errors?.name} />
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <Typography mb={1}>Column</Typography>
            <CreateDynamicFields
              fields={fields}
              remove={remove}
              append={append}
              control={control}
              name='config.columns'
              keyLabel={"Column Key"}
              valueLabel='Column Label'
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
            <Button onClick={handleSubmit(onSave)} variant={"contained"} aria-label={"save"}>
              Save
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Box>
  );
};
