import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import loaderReducer from "./loaderReducer";
import snackbarReducer from "./snackbarReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  snackbar: snackbarReducer,
  loader: loaderReducer,
});

export default reducer;
