import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  DialogActions,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Popover,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useBXContext } from "src/BXEngine/BXContext";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { BXIconPicker } from "src/components/BXUI/FormControls/IconPicker";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import { BXApp } from "src/types/BXAppType";
import axiosServices from "src/utils/axios";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import * as yup from "yup";

type CreateAppFormProps = {
  onSave: SubmitHandler<FieldValues>;
  onCancel: Function;
  app?: BXApp;
  withAppInfo?: boolean;
  withAuthConfig?: boolean;
  appId?: string;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
};

const schema = yup
  .object({
    slug: yup.string().required(),
    name: yup.string().required(),
    icon: yup.string(),
    authDisabled: yup.boolean(),
    baseUrl: yup.string().when("appConfig.isSingleSignOn", {
      is: true,
      then: yup.string().required("Field is required"),
    }),
    fqdn: yup.string().when("appConfig.isSingleSignOn", {
      is: true,
      then: yup.string().required("Field is required"),
    }),
    userInfoEndpoint: yup.string().when("appConfig.isSingleSignOn", {
      is: true,
      then: yup.string().required("Field is required"),
    }),
    appConfig: yup.object().shape({
      theme: yup.object().shape({
        background: yup.string().test("background", "Invalid color", (value: any) => !value || !!CSS.supports("color", value)),
        paper: yup.string().test("paper", "Invalid color", (value: any) => !value || !!CSS.supports("color", value)),
        textPrimary: yup.string().test("textPrimary", "Invalid color", (value: any) => !value || !!CSS.supports("color", value)),
        primaryMain: yup.string().test("primaryMain", "Invalid color", (value: any) => !value || !!CSS.supports("color", value)),
        primaryLight: yup.string().test("primaryLight", "Invalid color", (value: any) => !value || !!CSS.supports("color", value)),
      }),
      loginCustomizations: yup.object().shape({
        backgroundColor: yup.string().test("backgroundColor", "Invalid color", (value: any) => !value || !!CSS.supports("color", value)),
        cardColor: yup.string().test("cardColor", "Invalid color", (value: any) => !value || !!CSS.supports("color", value)),
      }),
      enableBuildxSignIn: yup.boolean().test("enableBuildxSignIn", "Field is required", (value: any, context: any) => {
        if (!value && !context?.parent?.enableGoogleSignIn && context?.parent?.isSingleSignOn) return false;
        return true;
      }),
      enableGoogleSignIn: yup.boolean().test("enableGoogleSignIn", "Field is required", (value: any, context: any) => {
        if (!value && !context?.parent?.enableBuildxSignIn && context?.parent?.isSingleSignOn) return false;
        return true;
      }),
      googleInfo: yup.object().when("enableGoogleSignIn", {
        is: true,
        then: yup.object().shape({
          clientId: yup.string().required("Field is required"),
          authEndpoint: yup.string().required("Field is required"),
        }),
      }),
    }),
  })
  .required();

const useStyles = makeStyles((theme: Theme) => ({
  pickerRoot: {
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
}));

export const CreateAppForm: FC<CreateAppFormProps> = ({
  onCancel = _.noop,
  onSave = _.noop,
  app = {},
  withAppInfo = true,
  withAuthConfig = true,
  setIsDirty,
  appId,
}) => {
  const { envs } = useBXContext();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<FieldValues>({
    defaultValues: {
      ...app,
      appConfig: { ...app?.appConfig, auth: { ...app?.appConfig?.auth, type: app?.appConfig?.auth?.type || "Buildx" } },
    },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(false);
  const [isAppIconLoading, setIsAppIconLoading] = useState(false);
  const [tabValue, setTabValue] = useState(1);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const classes = useStyles();
  const alignmentValues = [
    { key: "left", value: "Left" },
    { key: "center", value: "Center" },
    { key: "right", value: "Right" },
  ];

  const modalTabs = [
    {
      name: "slug,name,baseUrl",
      label: "Info",
      condition: true,
      value: 1,
    },
    {
      label: "Auth",
      condition: true,
      value: 2,
    },
    {
      name: "userInfoEndpoint,enableBuildxSignIn",
      label: "Buildx",
      condition: !!watch("appConfig.isSingleSignOn"),
      value: 3,
    },
    {
      name: "appConfig.theme",
      label: "Theme",
      condition: !!watch("appConfig.isSingleSignOn"),
      value: 4,
    },
    {
      name: "appConfig.loginCustomizations",
      label: "Login Customizations",
      condition: !!watch("appConfig.isSingleSignOn"),
      value: 5,
    },
  ];

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>, onChange?: any, setLoading?: any) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setLoading(true);

    let key = null;

    try {
      const { data } = await axiosServices.get("/uploads/s3-url");

      const { key: responseKey, url } = data;
      key = responseKey;
      const extn = file.type.split("/")[1];

      await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      const { data: _data } = await axiosServices.post(`/admin/application/${appId}/resources`, undefined, {
        params: {
          key,
          extn,
        },
      });

      onChange(_data?.msg);

      enqueueSnackbarRef?.("Uploaded Successfully", {
        variant: "success",
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
    } catch (e) {
      enqueueSnackbarRef?.("Something went wrong", {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
      event.target.value = "";
    } finally {
      setLoading(false);
    }
  };

  useCallbackPrompt(isDirty);

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box component='form' noValidate autoComplete='off'>
      <Popover
        open={!!anchorEl?.target}
        anchorEl={anchorEl?.target}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <SketchPicker
          onChangeComplete={e => {
            setValue(anchorEl?.path, e.hex);
          }}
          color={watch(anchorEl?.path)}
          className={classes.pickerRoot}
          styles={{
            default: {
              color: {
                backgroundColor: "red !important",
              },
              picker: {
                backgroundColor: "red !important",
              },
            },
          }}
        />
      </Popover>
      {!withAuthConfig && (
        <Box sx={{ bgcolor: "background.paper" }} mb={2}>
          <Tabs value={tabValue} onChange={handleTabChange} textColor='primary' indicatorColor='primary' style={{ textTransform: "none" }}>
            {modalTabs.map(
              ({ value, label, condition, name }) =>
                condition && (
                  <Tab
                    label={
                      <>
                        {!!name?.split(",")?.find(e => !!errors?.[e] || !!(errors as any)?.appConfig?.[e]) && (
                          <Badge color='error' variant='dot' style={{ position: "absolute", top: 10, right: 10 }} />
                        )}
                        <Typography fontWeight={"bold"}>{label}</Typography>
                      </>
                    }
                    sx={{ textTransform: "none" }}
                    value={value}
                  />
                )
            )}
          </Tabs>
        </Box>
      )}

      <Container maxWidth={"md"}>
        <Grid container spacing={3}>
          {withAppInfo && (
            <>
              {tabValue == 1 && (
                <>
                  <Grid item xs={12}>
                    <BXInput
                      variant='outlined'
                      required
                      label={"App name"}
                      id={"bx-app-create-name-input"}
                      control={control}
                      name={"name"}
                      error={errors?.name}
                      onChange={e => {
                        setValue("slug", `/${e.target.value.toLowerCase().replaceAll(/  */g, "-")}`);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BXInput
                      variant='outlined'
                      required
                      label={"App slug"}
                      id={"bx-app-create-slug-input"}
                      control={control}
                      name={"slug"}
                      error={errors?.slug}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BXInput variant='outlined' required label={"Base URL"} control={control} name={"baseUrl"} error={errors?.baseUrl} />
                  </Grid>
                  <Grid item xs={12}>
                    <BXInput
                      required
                      variant='outlined'
                      name={"env.id"}
                      error={(errors as any)?.env?.id}
                      control={control}
                      select
                      label={"Environment"}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <Divider />
                      {envs?.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </BXInput>
                  </Grid>
                  <Grid item xs={12}>
                    <BXIconPicker label={"App Icon/Logo"} control={control} name={"icon"} />
                  </Grid>
                  <Grid item xs={12}>
                    <BXSwitch label='Admins only' control={control} name='adminLoginOnly' defaultChecked defaultValue={"on"} />
                  </Grid>
                  <Grid item xs={12}>
                    <BXSwitch
                      name={"appConfig.isSingleSignOn"}
                      control={control}
                      label={"Single Sign On"}
                      error={(errors as any)?.appConfig?.isSingleSignOn}
                    />
                  </Grid>

                  <>
                    <Grid item xs={12} ml={2}>
                      <BXInput
                        variant='outlined'
                        required
                        label={"FQDN"}
                        control={control}
                        name={"fqdn"}
                        error={(errors as any)?.fqdn}
                        disabled={!watch("appConfig.isSingleSignOn")}
                      />
                    </Grid>
                    <Grid item xs={12} ml={2}>
                      <BXSwitch
                        label='Profile Management'
                        control={control}
                        name='appConfig.withProfiles'
                        disabled={!watch("appConfig.isSingleSignOn")}
                      />
                    </Grid>
                  </>
                </>
              )}
              {tabValue == 2 && (
                <>
                  <Grid item xs={6}>
                    <BXInput
                      variant='outlined'
                      required
                      label={"Type"}
                      control={control}
                      select
                      name={"appConfig.auth.type"}
                      error={(errors as any)?.auth?.type}
                    >
                      {["Buildx", "No Auth", "API Key", "Basic Auth"]?.map(item => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </BXInput>
                  </Grid>
                  {watch("appConfig.auth.type") == "API Key" && (
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <BXInput
                          variant='outlined'
                          required
                          label={"Key"}
                          control={control}
                          name={"appConfig.auth.keyValue"}
                          error={(errors as any)?.auth?.keyValue}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <BXInput
                          variant='outlined'
                          required
                          label={"Value"}
                          control={control}
                          name={"appConfig.auth.apiValue"}
                          error={(errors as any)?.auth?.apiValue}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {watch("appConfig.auth.type") == "Basic Auth" && (
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <BXInput
                          variant='outlined'
                          required
                          label={"Username"}
                          control={control}
                          name={"appConfig.auth.basicAuthUsername"}
                          error={(errors as any)?.auth?.basicAuthUsername}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <BXInput
                          variant='outlined'
                          required
                          label={"Password"}
                          control={control}
                          name={"appConfig.auth.basicAuthPassword"}
                          error={(errors as any)?.auth?.basicAuthPassword}
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {watch("appConfig.isSingleSignOn") && (
                <>
                  {tabValue == 3 && (
                    <>
                      <Grid item xs={12}>
                        <BXInput
                          variant='outlined'
                          required
                          label={"User Info Url"}
                          control={control}
                          name={"userInfoEndpoint"}
                          error={(errors as any)?.userInfoEndpoint}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <BXSwitch
                          name={"appConfig.enableBuildxSignIn"}
                          control={control}
                          label={"Email login"}
                          error={(errors as any)?.appConfig?.enableBuildxSignIn}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <BXSwitch
                          name={"appConfig.enableGoogleSignIn"}
                          control={control}
                          label={"Google Login"}
                          error={(errors as any)?.appConfig?.enableGoogleSignIn}
                        />
                      </Grid>

                      <Grid item xs={12} ml={2}>
                        <BXInput
                          variant='outlined'
                          required
                          label={"Client ID"}
                          control={control}
                          name={"appConfig.googleInfo.clientId"}
                          error={(errors as any)?.appConfig?.googleInfo?.clientId}
                          disabled={!watch("appConfig.enableGoogleSignIn")}
                        />
                      </Grid>
                      <Grid item xs={12} ml={2}>
                        <BXInput
                          variant='outlined'
                          required
                          label={"Auth Endpoint"}
                          control={control}
                          name={"appConfig.googleInfo.authEndpoint"}
                          error={(errors as any)?.appConfig?.googleInfo?.authEndpoint}
                          disabled={!watch("appConfig.enableGoogleSignIn")}
                        />
                      </Grid>
                    </>
                  )}
                  {tabValue == 4 && (
                    <>
                      <Grid item xs={4} display={"flex"} alignItems='center'>
                        <Box
                          onClick={event => {
                            setAnchorEl({
                              target: event.currentTarget,
                              path: "appConfig.theme.background",
                            });
                          }}
                          sx={{
                            backgroundColor: watch("appConfig.theme.background"),
                            width: 20,
                            height: 20,
                            border: "1px solid white",
                            marginRight: 1,
                          }}
                        />
                        <BXInput
                          variant='outlined'
                          required
                          label={"Background Color"}
                          control={control}
                          name={"appConfig.theme.background"}
                          error={(errors as any)?.appConfig?.theme?.background}
                        />
                      </Grid>
                      <Grid item xs={4} display={"flex"} alignItems='center'>
                        <Box
                          onClick={event => {
                            setAnchorEl({
                              target: event.currentTarget,
                              path: "appConfig.theme.paper",
                            });
                          }}
                          sx={{
                            backgroundColor: watch("appConfig.theme.paper"),
                            width: 20,
                            height: 20,
                            border: "1px solid white",
                            marginRight: 1,
                          }}
                        />
                        <BXInput
                          variant='outlined'
                          required
                          label={"Paper Color"}
                          control={control}
                          name={"appConfig.theme.paper"}
                          error={(errors as any)?.appConfig?.theme?.paper}
                        />
                      </Grid>
                      <Grid item xs={4} display={"flex"} alignItems='center'>
                        <Box
                          onClick={event => {
                            setAnchorEl({
                              target: event.currentTarget,
                              path: "appConfig.theme.textPrimary",
                            });
                          }}
                          sx={{
                            backgroundColor: watch("appConfig.theme.textPrimary"),
                            width: 20,
                            height: 20,
                            border: "1px solid white",
                            marginRight: 1,
                          }}
                        />
                        <BXInput
                          variant='outlined'
                          required
                          label={"Primary Text Color"}
                          control={control}
                          name={"appConfig.theme.textPrimary"}
                          error={(errors as any)?.appConfig?.theme?.textPrimary}
                        />
                      </Grid>
                      <Grid item xs={4} display={"flex"} alignItems='center'>
                        <Box
                          onClick={event => {
                            setAnchorEl({
                              target: event.currentTarget,
                              path: "appConfig.theme.primaryMain",
                            });
                          }}
                          sx={{
                            backgroundColor: watch("appConfig.theme.primaryMain"),
                            width: 20,
                            height: 20,
                            border: "1px solid white",
                            marginRight: 1,
                          }}
                        />
                        <BXInput
                          variant='outlined'
                          required
                          label={"Main Primary Color"}
                          control={control}
                          name={"appConfig.theme.primaryMain"}
                          error={(errors as any)?.appConfig?.theme?.primaryMain}
                        />
                      </Grid>
                      <Grid item xs={4} display={"flex"} alignItems='center'>
                        <Box
                          onClick={event => {
                            setAnchorEl({
                              target: event.currentTarget,
                              path: "appConfig.theme.primaryLight",
                            });
                          }}
                          sx={{
                            backgroundColor: watch("appConfig.theme.primaryLight"),
                            width: 20,
                            height: 20,
                            border: "1px solid white",
                            marginRight: 1,
                          }}
                        />
                        <BXInput
                          variant='outlined'
                          required
                          label={"Light Primary Color"}
                          control={control}
                          name={"appConfig.theme.primaryLight"}
                          error={(errors as any)?.appConfig?.theme?.primaryLight}
                        />
                      </Grid>
                    </>
                  )}
                  {tabValue == 5 && (
                    <>
                      <Grid item xs={6}>
                        <BXInput
                          variant='outlined'
                          required
                          label={"Title"}
                          control={control}
                          name={"appConfig.loginCustomizations.title"}
                          error={(errors as any)?.appConfig?.loginCustomizations?.title}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <BXInput
                          variant='outlined'
                          required
                          label={"Description"}
                          control={control}
                          name={"appConfig.loginCustomizations.description"}
                          error={(errors as any)?.appConfig?.loginCustomizations?.description}
                        />
                      </Grid>
                      <Grid item xs={6} display={"flex"} alignItems='center'>
                        <Box
                          onClick={event => {
                            setAnchorEl({
                              target: event.currentTarget,
                              path: "appConfig.loginCustomizations.backgroundColor",
                            });
                          }}
                          sx={{
                            backgroundColor: watch("appConfig.loginCustomizations.backgroundColor"),
                            width: 20,
                            height: 20,
                            border: "1px solid white",
                            marginRight: 1,
                          }}
                        />
                        <BXInput
                          variant='outlined'
                          required
                          label={"Background Color"}
                          control={control}
                          name={"appConfig.loginCustomizations.backgroundColor"}
                          error={(errors as any)?.appConfig?.loginCustomizations?.backgroundColor}
                        />
                      </Grid>
                      <Grid item xs={6} display={"flex"} alignItems='center'>
                        <Box
                          onClick={event => {
                            setAnchorEl({
                              target: event.currentTarget,
                              path: "appConfig.loginCustomizations.cardColor",
                            });
                          }}
                          sx={{
                            backgroundColor: watch("appConfig.loginCustomizations.cardColor"),
                            width: 20,
                            height: 20,
                            border: "1px solid white",
                            marginRight: 1,
                          }}
                        />
                        <BXInput
                          variant='outlined'
                          required
                          label={"Card Background Color"}
                          control={control}
                          name={"appConfig.loginCustomizations.cardColor"}
                          error={(errors as any)?.appConfig?.loginCustomizations?.cardColor}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <BXInput
                          variant='outlined'
                          required
                          label={"Background Image Size"}
                          control={control}
                          name={"appConfig.loginCustomizations.backgroundImageSize"}
                          error={(errors as any)?.appConfig?.loginCustomizations?.backgroundImageSize}
                        />
                      </Grid>
                      {/* <Grid item xs={6}>
                        <BXInput
                          required
                          variant='outlined'
                          name={`appConfig.loginCustomizations.titleAlignment`}
                          error={(errors as any)?.appConfig?.loginCustomizations?.titleAlignment}
                          control={control}
                          select
                          label={"Title Alignment"}
                          value={watch("appConfig.loginCustomizations.titleAlignment") || alignmentValues[1].key}
                        >
                          {alignmentValues?.map(item => (
                            <MenuItem key={item.key} value={item.key}>
                              {item.value}
                            </MenuItem>
                          ))}
                        </BXInput>
                      </Grid> */}
                      <Grid xs={12} item container spacing={3}>
                        <Grid item xs={6}>
                          <Controller
                            control={control}
                            name={"appConfig.loginCustomizations.backgroundImage"}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth>
                                <TextField
                                  fullWidth
                                  inputProps={{
                                    accept: "image/*",
                                  }}
                                  type='file'
                                  label={"Background Image"}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  disabled={!!isBackgroundImageLoading}
                                  onChange={(e: any) => {
                                    handleChange(e, onChange, setIsBackgroundImageLoading);
                                  }}
                                  size={"medium"}
                                />
                              </FormControl>
                            )}
                          />
                          {watch("appConfig.loginCustomizations.backgroundImage") && (
                            <img
                              src={watch("appConfig.loginCustomizations.backgroundImage")}
                              style={{
                                maxHeight: 150,
                                width: "100%",
                                objectFit: "contain",
                                marginTop: 12,
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            control={control}
                            name={"appConfig.loginCustomizations.loginIcon"}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth>
                                <TextField
                                  fullWidth
                                  inputProps={{
                                    accept: "image/png,image/jpeg,image/jpg",
                                  }}
                                  type='file'
                                  label={"Login Icon"}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  disabled={!!isAppIconLoading}
                                  onChange={(e: any) => {
                                    handleChange(e, onChange, setIsAppIconLoading);
                                  }}
                                  size={"medium"}
                                />
                              </FormControl>
                            )}
                          />
                          {watch("appConfig.loginCustomizations.loginIcon") && (
                            <img
                              src={watch("appConfig.loginCustomizations.loginIcon")}
                              style={{
                                maxHeight: 150,
                                width: "100%",
                                objectFit: "contain",
                                marginTop: 12,
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {withAuthConfig && (
            <Grid item xs={12}>
              <Card variant={"outlined"}>
                <CardHeader title={"Auth Config"} style={{ paddingBottom: 0 }} />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <BXInput
                        variant='outlined'
                        required
                        label={"Device Registration API"}
                        id={"bx-app-create-device-register-api-input"}
                        control={control}
                        name={"appConfig.auth.deviceApi"}
                        error={errors?.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <BXInput
                        variant='outlined'
                        required
                        label={"Auth API"}
                        id={"bx-app-create-auth-api-input"}
                        control={control}
                        name={"appConfig.auth.authApi"}
                        error={errors?.name}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
      <Grid item xs={12}>
        <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
          <Button onClick={handleSubmit(onSave)} variant='contained' aria-label={"save"}>
            Save
          </Button>
        </DialogActions>
      </Grid>
    </Box>
  );
};
