import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { FC } from "react";
import { Control, Controller } from "react-hook-form";

type BXSwitchProps = {
  label: string;
  control: Control<any>;
  name: string;
  defaultValue?: any;
  id?: string;
  error?: any;
  onValueChange?: any;
} & SwitchProps;

export const BXSwitch: FC<BXSwitchProps> = ({ name, control, label, id, defaultValue, error, onValueChange, ...rest }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Switch
                checked={value}
                onChange={e => {
                  onValueChange?.(e.target.checked);
                  onChange(e.target.checked);
                }}
                {...rest}
              />
            }
            label={label}
          />
          {error && <FormHelperText error>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
