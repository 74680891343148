import { useRef } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { BXApp, BXAppCollection } from "src/types/BXAppType";
import axiosServices from "src/utils/axios";

// routes
import { useBXContext } from "../BXEngine/BXContext";
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { isLoggedIn } = useAuth();
  const { appDescriptor, currentApp, loadingApps, setCurrentApp, setAppDescriptor } = useBXContext();
  const loadingRef = useRef(false);
  const navigate = useNavigate();

  if (
    !loadingApps &&
    !currentApp &&
    window.location.pathname != "/" &&
    window.location.pathname != "/buildx/app" &&
    window.location.pathname != "/buildx/users" &&
    window.location.pathname != "/buildx/component" &&
    window.location.pathname != "/buildx/oas" &&
    window.location.pathname != "/buildx/env" &&
    !window.location.pathname.startsWith("/buildx/form-builder") &&
    isLoggedIn
  ) {
    navigate("/");
  }

  if (
    !loadingApps &&
    currentApp &&
    window.location.pathname == "/" &&
    isLoggedIn &&
    !!currentApp?.templateConfig?.collections?.[0]?.pages?.length
  ) {
    const firstApp = currentApp;
    const firstCollectionToHavePages = firstApp.templateConfig?.collections?.find(
      (collection: BXAppCollection) => collection.pages.length > 0
    );
    const collectionSlug = firstCollectionToHavePages?.slug;
    const firstPageSlug = firstCollectionToHavePages?.pages?.[0]?.slug;
    if (firstPageSlug) navigate({ pathname: `${firstApp.slug}/${collectionSlug}/${firstPageSlug}`.replaceAll(/\/+/g, "/") });
    else navigate({ pathname: `${firstApp.slug}`.replaceAll(/\/+/g, "/") });
  }

  if (currentApp && currentApp?.templateConfig == null && !loadingApps && !loadingRef.current && isLoggedIn) {
    loadingRef.current = true;
    axiosServices
      .get("/application/" + currentApp?.id)
      .then(({ data }) => {
        setAppDescriptor((prev: BXApp[]) => prev.map(app => (app.id == data?.id ? data : app)));
        setCurrentApp(data);
        const firstApp = data;
        const firstCollectionToHavePages = firstApp.templateConfig?.collections?.find(
          (collection: BXAppCollection) => collection.pages.length > 0
        );
        if (window.location.pathname == data.slug) {
          const collectionSlug = firstCollectionToHavePages?.slug;
          const firstPageSlug = firstCollectionToHavePages?.pages?.[0]?.slug;
          if (firstPageSlug) navigate({ pathname: `${firstApp.slug}/${collectionSlug}/${firstPageSlug}`.replaceAll(/\/+/g, "/") });
          else navigate({ pathname: `${firstApp.slug}`.replaceAll(/\/+/g, "/") });
        }
      })
      .finally(() => {
        loadingRef.current = false;
      });
  }

  return useRoutes([...LoginRoutes, ...MainRoutes(appDescriptor, currentApp, isLoggedIn, loadingApps)]);
}
