import { ProviderContext, useSnackbar } from "notistack";

export let enqueueSnackbarRef: ProviderContext["enqueueSnackbar"];
export let closeSnackbarRef: ProviderContext["closeSnackbar"];

const SnackbarUtilsConfigure = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    enqueueSnackbarRef = enqueueSnackbar;
    closeSnackbarRef = closeSnackbar;
    return <></>;
};

export default SnackbarUtilsConfigure;
