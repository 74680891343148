import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { IconRefresh } from "@tabler/icons";

import _ from "lodash";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import ReactJson from "react-json-view";
import { BXEngine } from "src/BXEngine";
import Sound1 from "src/assets/sounds/notification-sound-1.mp3";
import Sound2 from "src/assets/sounds/notification-sound-2.mp3";
import Sound3 from "src/assets/sounds/notification-sound-3.mp3";
import { PowerPackDetailsCard } from "src/components/GridItem";
import { Pagination } from "src/components/pagination";
import { ElementBaseProps } from "src/types/UIElement";
import { getLastKeyFromObject } from "src/utils/generalUtils";
import { queryClient, useBXContext, useValue } from "../../../BXEngine/BXContext";
import { ActionButton, replaceItemPlaceholders } from "../DataTable/ActionButton";
import { UserInput } from "../UserInput";

export const BXCardList: FC<ElementBaseProps> = ({
  id,
  dataSource,
  info,
  actions,
  views,
  selectedViewId,
  prevVideoCardId,
  __data = {},
  closeModal,
  type,
  parentIds = [],
  index,
}) => {
  const { selectedTableRows, setSelectedTableRows, currentApp } = useBXContext();
  // const selectedRow = selectedTableRows?.find(item => item?.viewId == id);
  const selectedRowFromOtherTables = selectedTableRows?.find(item => item?.viewId == dataSource?.table?.id || item?.id == selectedViewId);

  const [userInputsValues, setUserInputValues] = useState({});
  const [isApiMode, setIsApiMode] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [cursors, setCursors] = useState([prevVideoCardId]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { palette } = useTheme();

  const soundRef1 = useRef(null);
  const soundRef2 = useRef(null);
  const soundRef3 = useRef(null);

  const soundMap: any = {
    sound1: soundRef1,
    sound2: soundRef2,
    sound3: soundRef3,
  };

  const withInfiniteScroll = (!info?.paginationMode || info?.paginationMode == "Infinite Scroll") && !dataSource?.splitViewConfig?.enabled;

  const { data, isFetching, hasNextPage, fetchNextPage, refetch, isError } = useValue(
    id,
    dataSource?.splitViewConfig?.enabled ? 1 : dataSource?.limit,
    dataSource?.sourceType,
    dataSource?.sourceType == "USER INPUT" ? userInputsValues : selectedRowFromOtherTables,
    selectedRowFromOtherTables,
    userInputsValues,
    dataSource?.sourceType == "USER INPUT",
    withInfiniteScroll,
    {
      refetchInterval: info?.autoRefresh && (info?.timer || 60) * 1000,
      refetchIntervalInBackground: !!info?.autoRefresh,
      onSuccess: (data: any) => {
        if (dataSource?.splitViewConfig?.enabled && _.get(data, dataSource?.dataEntry as any)?.[0]) {
          handleSelectRow(_.get(data, dataSource?.dataEntry as any)?.[0], true);
        }
      },
    },
    cursors?.[currentPage - 1],
    __data,
    dataSource?.dataEntry,
    soundMap[info?.notificationSound as any],
    info?.notificationMessage,
    undefined,
    index
  );

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: hasNextPage || false,
    onLoadMore: () => {
      fetchNextPage();
    },
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: isError,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    return () => {
      if (dataSource?.sourceType == "USER INPUT") {
        queryClient.setQueryData([id], () => ({
          pages: [],
        }));
      }
    };
  }, []);

  const filterSelectedChildren = (items: any, _id: any) => {
    items.forEach((item: any) => {
      if (item?.dataSource?.table?.id == _id) {
        items = items.filter((_item: any) => _item?.dataSource?.table?.id != _id);
        items = filterSelectedChildren(items, item?.viewId);
      }
    });
    return items;
  };

  const handleSelectRow = (itemData: any, isReplace?: boolean) => {
    const item = { ...itemData, viewId: id, dataSource };
    setSelectedTableRows((prev: any[]) => {
      let newData = [...prev];

      // find if is there row selected in the table
      const checkTableRowIsExist = newData.find((_item: any) => item?.id != _item?.id && _item?.viewId == id);

      if (checkTableRowIsExist) {
        newData = newData.filter(prevData => prevData.id != checkTableRowIsExist?.id || prevData?.viewId != id);
      }

      // find if the item is exist in the array with same table
      const checkItemIsExist = newData.find((_item: any) => item?.id === _item?.id && _item?.viewId == id);

      if (!checkItemIsExist) {
        newData.push(item);
      } else {
        if (!isReplace) {
          newData = filterSelectedChildren(newData, id);
          newData = newData.filter(prevData => prevData.id != checkItemIsExist?.id || prevData?.viewId != id);
        }
      }

      return newData;
    });
  };

  let entries: any[];
  // @ts-ignore
  if (!withInfiniteScroll) {
    entries = (_.isNil(dataSource?.dataEntry) ? data : _.get(data, dataSource?.dataEntry as any))?.filter?.(Boolean);
  } else {
    entries = (
      _.isNil(dataSource?.dataEntry)
        ? _.flatten(data?.pages)
        : _.flatten(data?.pages?.map((p: any) => _.get(p, dataSource?.dataEntry as any)))
    )?.filter?.(Boolean);
  }

  const selectedView = views?.find(view => view?.id == dataSource?.splitViewConfig?.viewId);
  const WrapperGrid = dataSource?.splitViewConfig?.enabled ? Grid : Fragment;
  const WrapperGridProps = dataSource?.splitViewConfig?.enabled ? { item: true, xs: 4 } : {};

  return (
    <Box marginY={1} paddingX={1}>
      <audio ref={soundRef1} src={Sound1} />
      <audio ref={soundRef2} src={Sound2} />
      <audio ref={soundRef3} src={Sound3} />
      <Card variant={"outlined"}>
        <Grid container paddingX={2} mt={2} mb={2}>
          <Grid container xs={12}>
            <Typography flex={1} fontSize={"16px"} lineHeight={2}>
              {replaceItemPlaceholders(
                info?.name,
                undefined,
                selectedRowFromOtherTables,
                undefined,
                undefined,
                undefined,
                __data,
                undefined,
                undefined,
                undefined,
                currentApp?.env
              )}
            </Typography>
            <Tooltip title='Refresh'>
              <Box mr={1} alignSelf='center'>
                <IconButton
                  onClick={() => {
                    setIsRefreshing(true);
                    queryClient.refetchQueries([id]).then(() => {
                      setIsRefreshing(false);

                      // enqueueSnackbarRef?.("Refreshed Successfully", {
                      //   variant: "success",
                      //   anchorOrigin: {
                      //     horizontal: "right",
                      //     vertical: "bottom",
                      //   },
                      // });
                    });
                  }}
                  disabled={isFetching}
                  style={{ backgroundColor: palette.grey[100] }}
                >
                  {isRefreshing ? <CircularProgress size='16px' /> : <IconRefresh color={palette.text.primary} size={16} />}
                </IconButton>
              </Box>
            </Tooltip>
            {_.isArray(actions) && !_.isEmpty(actions.filter(action => action.isGlobal)) && (
              <>
                {actions
                  ?.filter(action => action.isGlobal)
                  .map(action => (
                    <Box key={action.id} ml={1} alignSelf='center'>
                      <ActionButton
                        item={{}}
                        tableId={id}
                        action={action}
                        selectedRowFromOtherTables={selectedRowFromOtherTables}
                        userInputsValues={userInputsValues}
                        views={views}
                        closeModal={closeModal}
                        __data={__data}
                        parentIds={parentIds}
                        iconButton={!action?.showButtonLabel}
                      />
                    </Box>
                  ))}
              </>
            )}
            {!withInfiniteScroll && !dataSource?.splitViewConfig?.enabled && !!entries?.length && (
              <Pagination
                currentPage={currentPage}
                totalPageCount={cursors?.length}
                hasNextPage={data?.hasMore && !isFetching}
                isFetching={isFetching}
                onPageChange={(page: number) => {
                  setCurrentPage(page);
                  if (page > currentPage) {
                    setCursors(prev => [...prev, data.cursor]);
                  } else {
                    setCursors(prev => prev.slice(0, page));
                  }
                }}
              />
            )}

            {info?.showApiMode != "Hidden" && !dataSource?.splitViewConfig?.enabled && (
              <Box display='flex' alignItems='center' border={"1px solid white"} ml={2} paddingX={1.5} borderRadius={10}>
                <Typography>API Mode</Typography>
                <Switch onChange={(_, value) => setIsApiMode(value)} />
              </Box>
            )}
          </Grid>
          {dataSource?.sourceType == "USER INPUT" && (
            <Card variant={"outlined"} style={{ width: "100%", marginTop: 8, padding: "24px 24px 24px 18px" }}>
              <Grid xs={12} item container spacing={2}>
                {dataSource?.userInputs?.map(input => (
                  <UserInput input={input} setUserInputValues={setUserInputValues} />
                ))}
                {dataSource?.userInputs?.length == 1 && (
                  <Grid item>
                    <Button
                      variant={"contained"}
                      onClick={() => {
                        refetch({ queryKey: [userInputsValues] });
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                )}
              </Grid>
              {dataSource?.userInputs?.length != 1 && (
                <Grid container item mt={3} alignItems='center' justifyContent='center'>
                  <Button
                    variant={"contained"}
                    onClick={() => {
                      refetch({ queryKey: [userInputsValues] });
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              )}
            </Card>
          )}
          <Grid container paddingY={2.5} spacing={"10px"}>
            {isApiMode ? (
              <ReactJson src={entries as any} theme={"paraiso"} style={{ width: "100%" }} />
            ) : (
              <Grid item xs={12} container spacing={"10px"}>
                <WrapperGrid {...WrapperGridProps}>
                  {entries?.map?.((item: any, index: number) => {
                    return (
                      <Grid
                        item
                        key={`gi-${item?.id || index}` || index}
                        xs={12}
                        {...(!dataSource?.splitViewConfig?.enabled && { sm: 6, md: 4, lg: 3, xl: 2 })}
                        onClick={() => handleSelectRow(item)}
                      >
                        <PowerPackDetailsCard
                          key={item?.id}
                          {...(typeof item === "object" ? item : { dataString: item })}
                          coreData={item}
                          prevId={index === 0 ? undefined : entries?.[index - 1]?.id}
                          index={(currentPage - 1) * (dataSource?.limit ?? 20) + index}
                          views={views}
                          viewType={type}
                          gridId={id}
                          actions={actions}
                          gridKey={dataSource?.gridKey}
                          dataSource={dataSource}
                          playOnHover={dataSource?.playOnHover}
                          selectedRowFromOtherTables={selectedRowFromOtherTables}
                          userInputsValues={userInputsValues}
                          isUserInput={dataSource?.sourceType == "USER INPUT"}
                          onSelectRow={handleSelectRow}
                          __data={__data}
                          closeModal={closeModal}
                          parentIds={parentIds}
                        />
                      </Grid>
                    );
                  })}

                  {((_.isEmpty(entries) && !isFetching) || isError) && (
                    <Grid item xs={12}>
                      <Typography component='div' align='center'>
                        {dataSource?.sourceType != "TABLE" || selectedRowFromOtherTables
                          ? "No Records Available"
                          : `Select Data from ${dataSource?.table?.info?.name} Table`}
                      </Typography>
                    </Grid>
                  )}
                  {(isFetching || hasNextPage) && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ref={sentryRef}>
                      <CircularProgress />
                    </Grid>
                  )}
                </WrapperGrid>

                {dataSource?.splitViewConfig?.enabled && !isFetching && (
                  <Grid item xs>
                    <BXEngine
                      auth={{}}
                      showModalHeader
                      page={{ views, layout: selectedView } as any}
                      layout={[{ ...selectedView, type: selectedView?.type }]}
                      isVisible
                      selectedViewId={entries?.[0]?.id}
                      __data={{
                        ...__data,
                        [(getLastKeyFromObject(__data) || "") + "#"]: entries?.[0],
                      }}
                      closeModal={closeModal}
                      parentIds={[...parentIds, id]}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
