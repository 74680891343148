import { createTheme } from "@mui/material/styles";

// project imports
// import componentStyleOverrides from "./compStyleOverride";
import componentStyleOverrides from "src/themes/compStyleOverride";
import { appColor as color } from "./colors";
import themePalette from "./palette";
import themeTypography from "./typography";

export const theme = () => {
  const themeOption = {
    colors: color,
    paper: color.paper,
    backgroundDefault: color.background,
    textPrimary: color.textPrimary,
    textDark: color.textPrimary,
    divider: color.textPrimary,
    lightGrey: color.lightGrey,
  };

  return createTheme({
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: themeTypography(themeOption),
    // @ts-ignore
    components: componentStyleOverrides(themeOption),
  });
};

export default theme;
