import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FC } from "react";
import { Control, Controller } from "react-hook-form";

type BXIconPickerProps = {
  label: string;
  control: Control<any>;
  name: string;
  defaultValue?: any;
  id?: string;
  error?: any;
} & TextFieldProps;

export const BXDatePicker: FC<BXIconPickerProps> = ({ name, control, label, id, defaultValue, error, required, ...rest }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required,
      }}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          <DatePicker
            label={label}
            value={value}
            onChange={e => {
              onChange(e);
            }}
            renderInput={params => <TextField required={required} {...rest} {...params} error={Boolean(error)} size='medium' />}
          />
          {error && <FormHelperText error>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
