import CardMedia from "@mui/material/CardMedia";
import Switch from "@mui/material/Switch";
import { IconVideo } from "@tabler/icons";

import { FC, memo } from "react";

type Props = {
  type: any;
  value: any;
};

const CellValue: FC<Props> = props => {
  const { type, value } = props;

  return type === "Boolean" ? (
    <Switch checked={!!value} disabled />
  ) : type === "Image" ? (
    value?.split(".")?.[value?.split(".")?.length - 1] === "mp4" ? (
      <IconVideo size={32} />
    ) : (
      <img src={value} style={{ width: 32, height: 32, borderRadius: 4 }} />
    )
  ) : type == "Video" || type == "Audio" ? (
    <CardMedia
      sx={{ border: `0px`, maxHeight: 320, width: "100%" }}
      // className={classes.videoMedia}
      component={type == "Video" ? "video" : "audio"}
      src={value}
      controls
    />
  ) : (
    value
  );
};

export default memo(CellValue);
