// theme constant
export const gridSpacing = 3;
export const drawerWidth = 275;
export const appDrawerWidth = 320;

export const defaultUserListLimit = 20;
export const defaultContestListLimit = 20;

export const defaultAvatarPhotoName = "preview.png";

export const API = {
  base: {
    asset: "/admin/asset",
    athlete: "/admin/athlete",
    burn: "/admin/burn",
    mint: "/admin/mint",
    quiz: "/admin/quiz",
    release: "/admin/release",
    role: "/admin/role",
    user: "/admin/user",
    recent: "zoomie/list/recent",
  },
  sub: {
    allReleases: "all",
    create: "create",
    currentRelease: "set-current",
    generate: "generate",
    reponses: "responses",
    reveal: "reveal",
    toggleEnabled: "enabled",
    toggleFeatured: "featured",
    toggleFlagged: "flagged",
    update: "update",
    openRelease: "open",
    closeRelease: "close",
  },
};

export const GenderList = [
  {
    value: "MALE",
    label: "MALE",
  },
  {
    value: "FEMALE",
    label: "FEMALE",
  },
  {
    value: "OTHER",
    label: "OTHER",
  },
  {
    value: "NOT_SPECIFIED",
    label: "NOT SPECIFIED",
  },
];

export const UserRoleList = [
  {
    value: "BX-ADMIN",
    label: "BX-Admin",
  },
  {
    value: "ADMIN",
    label: "Admin",
  },
  // {
  //     value: 'ATHLETE',
  //     label: 'Athlete'
  // },
  {
    value: "USER",
    label: "User",
  },
];

export const AthleteRoleValue = "ATHLETE";

export const AssetClassList = [
  {
    value: "ROOKIE",
    label: "ROOKIE",
  },
  {
    value: "BRONZE",
    label: "BRONZE",
  },
  {
    value: "SILVER",
    label: "SILVER",
  },
  {
    value: "GOLD",
    label: "GOLD",
  },
  {
    value: "EPIC",
    label: "EPIC",
  },
  {
    value: "LEGENDARY",
    label: "LEGENDARY",
  },
];

export const ArtStyleList = [
  {
    value: "GLASS",
    label: "GLASS",
  },
  {
    value: "GLOWING STEEL",
    label: "GLOWING STEEL",
  },
  {
    value: "GLOWING RECORD",
    label: "GLOWING RECORD",
  },
  {
    value: "ICE",
    label: "ICE",
  },
  {
    value: "FIRE",
    label: "FIRE",
  },
  {
    value: "WATER",
    label: "WATER",
  },
  {
    value: "LEGENDARY",
    label: "LEGENDARY",
  },
];

export const PackAssetClassList = [
  {
    value: "STARTER",
    label: "STARTER",
  },
  {
    value: "BOOSTER",
    label: "BOOSTER",
  },
  {
    value: "RARE",
    label: "RARE",
  },
];
export const AssetMediaType = {
  video: "VIDEO",
  image: "IMAGE",
};
export const Strings = {
  powercard: "POWERCARD",
  powerpack: "POWERPACK",
  exclusivepack: "EXCLUSIVE",
};

export const Mode = {
  edit: "edit",
  add: "add",
};
export const ApiStrings = {
  powercard: "POWERCARD",
  powerpack: "POWERPACK",
  exclusivepack: "EXCLUSIVE",
  pblbasepack: "PBLBASEPACK",
  pblwinnerpack: "PBLWINNERPACK",
  pblteampack: "PBLTEAMPACK",
};

export const ReleaseStatus = {
  pending: "PENDING",
  open: "OPEN",
  closed: "CLOSED",
};

export const AlertModes = {
  error: "error",
  success: "success",
  warning: "warning",
};

export const Messages = {
  exclPackCreateSuccess: "The exclusive pack has been created successfully",
  exclPackUpdSuccess: "The exclusive pack has been updated successfully",
  powerCardCreateSuccess: "The power card has been created successfully",
  powerCardUpdSuccess: "The power card has been updated successfully",
  powerPackCreateSuccess: "The power pack has been created successfully",
  powerPackUpdSuccess: "The power pack has been updated successfully",
  userCreateSuccess: "The user has been created successfully",
  userUpdateSuccess: "The user has been updated successfully",
  userRoleUpdateSuccess: "The user roles have been updated successfully",
  userRevealSuccess: "The user has been revealed successfully",
  releaseCreateSuccess: "The release has been created successfully",
  releaseUpdateSuccess: "The release has been updated successfully",
  releaseActiveSuccess: "The release has been activated successfully",
  quizCreateSuccess: "The quiz has been created successfully",
  quizUpdSuccess: "The quiz has been updated successfully",
  commonSuccess: "The action was performed successfully",
  zoomieUpdSuccess: "The zoomie has been updated successfully",
};

export const ViewsIcons: any = {
  "data-table": { title: "Table", icon: "Table" },
  "card-list": { title: "Grid", icon: "LayoutGrid" },
  "image-grid": { title: "Grid", icon: "LayoutGrid" },
  form: { title: "Form", icon: "Forms" },
  api: { title: "Api", icon: "Api" },
  upload: { title: "Upload", icon: "Upload" },
  "form-builder": { title: "View Builder", icon: "Building" },
};

export const viewIcon = (type: string): string => {
  return ViewsIcons[type]?.icon;
};

export const viewTitle = (type: string): string => {
  return ViewsIcons[type]?.title || type;
};
