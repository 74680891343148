import { Box } from "@mui/material";
import _ from "lodash";
import { useEffect } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { ResizeElement } from "src/components/ResizeElement";
import { BOX_HEIGHT } from "./FormBuilderEditor";
import { getItemClosestProp, stepperCount } from "./utils";

export const DragElement = (props: any) => {
  const {
    item,
    boxPosition,
    children,
    setView,
    activeComponent,
    setActiveComponent,
    resizable = true,
    hideSource = true,
    layoutBreak,
  } = props;
  const { type, leftPercentage, left, config, top } = item || {};

  const { widthPx, heightPx, heightPercentage, widthPercentage, fixedWidth, fixedHeight, disableResizeHeight } = config || {};

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: item,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const onResize = (size: any) => {
    const dx = Number(getItemClosestProp(left, layoutBreak)) < boxPosition?.width - size.width;
    const dy = Number(getItemClosestProp(top, layoutBreak)) < BOX_HEIGHT - size.height;

    const width = dx
      ? size.width
      : boxPosition?.width - (Number((getItemClosestProp(leftPercentage, layoutBreak) || "")?.replace("%", "")) / 100) * boxPosition?.width;
    const height = dy ? size.height : BOX_HEIGHT - getItemClosestProp(top, layoutBreak);

    const PWidth = (width / boxPosition?.width) * 100;
    const percentageWidth = PWidth > 100 ? "100%" : PWidth + "%";

    const PHeight = (height / BOX_HEIGHT) * 100;
    const percentageHeight = PHeight > 100 ? "100%" : PHeight + "%";
    const hasCustom = item?.config?.hasCustom;

    setView((prev: any) => {
      let elements = [];
      elements = prev?.dataSource?.formBuilder?.map((element: any) => {
        if (element?.id === item?.id) {
          const _element = _.cloneDeep(element);
          _element.config.hasCustom = layoutBreak === "xs";
          _element.config.widthPx = {
            ..._element.config.widthPx,
            xs: hasCustom ? _element.config.widthPx.xs : width,
            [layoutBreak]: width,
          };
          _element.config.widthPercentage = {
            ..._element.config.widthPercentage,
            xs: hasCustom ? _element?.config?.percentageWidth?.xs : percentageWidth,
            [layoutBreak]: percentageWidth,
          };
          if (!_element?.config?.disableResizeHeight) {
            _element.config.heightPx = {
              ..._element.config.height,
              xs: hasCustom ? _element.config.widthPx.xs : height,
              [layoutBreak]: height,
            };
            _element.config.heightPercentage = {
              ..._element.config.heightPercentage,
              xs: hasCustom ? _element.config.heightPercentage.xs : percentageHeight,
              [layoutBreak]: percentageHeight,
            };
          }
          return _element;
        } else {
          return element;
        }
      });
      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilder: elements,
        },
      };
    });
  };

  const getDimensions = {
    position: !resizable ? "relative" : "absolute",
    top: getItemClosestProp(top, layoutBreak),
    left: getItemClosestProp(leftPercentage, layoutBreak),
    ...(resizable && {
      width: !fixedWidth ? getItemClosestProp(widthPercentage, layoutBreak) : getItemClosestProp(widthPx, layoutBreak),
      height: !fixedHeight ? getItemClosestProp(heightPercentage, layoutBreak) : getItemClosestProp(heightPx, layoutBreak),
      border: resizable && item?.id === activeComponent ? "1px dashed rgba(255, 255, 255, .1)" : "none",
    }),
    opacity: isDragging && hideSource ? 0 : 1,
    zIndex: 1,
    backgroundColor: "transparent",
  };
  const ParentComponent = resizable && item?.id === activeComponent ? ResizeElement : Box;
  const parentComponentProps: any =
    resizable && item?.id === activeComponent ? { height: heightPx, width: widthPx, onResize, stepper: stepperCount } : {};
  return (
    <ParentComponent {...parentComponentProps}>
      <Box onClick={() => setActiveComponent?.(item)} sx={getDimensions} id={item?.id}>
        <Box height={disableResizeHeight ? item?.config?.defaultHeight : "100%"} width='100%' ref={drag}>
          {children}
        </Box>
      </Box>
    </ParentComponent>
  );
};
