import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

interface useDnDProp {
  id: any;
  index: number;
  name: string;
  moveCard: (dragIndex: any, hoverIndex: number) => void;
  updateCard: (dragIndex: any, hoverIndex: number) => void;
}

export const useDnD = ({ id, index, moveCard, updateCard, name }: useDnDProp) => {
  const ref = useRef(null) as any;
  const [{ handlerId }, drop] = useDrop({
    accept: name,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset() as any;
      // Get pixels to the top
      const hoverClientY = clientOffset?.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop(item: any, monitor) {
      const dragIndex = item.index;
      const hoverIndex = index;

      updateCard(dragIndex, hoverIndex);
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: name,
    item: () => {
      return { id, index };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return { ref, opacity, handlerId };
};
