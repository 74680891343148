import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { FC } from "react";
import { Control, Controller } from "react-hook-form";

type BXInputProps = TextFieldProps & {
  label: string;
  control: Control<any>;
  name: string;
  defaultValue?: any;
  id?: string;
  error?: any;
};

export const BXInput: FC<BXInputProps> = ({
  name,
  control,
  label,
  id,
  defaultValue,
  error,
  onChange: onSideChange,
  variant = "outlined",
  required = false,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required,
      }}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          <TextField
            error={Boolean(error)}
            aria-errormessage={"error message"}
            onChange={e => {
              onChange(e);
              onSideChange?.(e);
            }}
            size={"medium"}
            id={id}
            label={label}
            variant={variant}
            fullWidth
            value={value || ""}
            {...rest}
          />
          {error && <FormHelperText error>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
