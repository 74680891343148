import { Box } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { IconCopy } from "@tabler/icons";
import _ from "lodash";
import moment from "moment";
import React, { FC, useCallback, useState } from "react";
import { ItemActions } from "src/components/BXUI/DataTable/ItemActions";
import { BXDataTableColumn } from "src/components/BXUI/DataTable/index";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { getHashesFromString, reverseDataOfObjectValues } from "src/utils/generalUtils";
import DraggableRow from "src/views/pages/BuildX/AppBuilder/forms/DraggableRow";
import CellValue from "./CellValue";

const useStyles = makeStyles((theme: Theme) => ({
  videoMedia: {
    height: "120px",
    border: `0.5px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.common.black}`,
    [theme.breakpoints.down("lg")]: {
      height: "80px",
    },
  },
  root: {
    "&:hover": {
      "& $copyContainer": { display: "block" },
    },
  },
  copyContainer: {
    display: "none",
  },
}));

export const TableItem: FC<{
  item: any;
  auth: any;
  columns: BXDataTableColumn[];
  actions?: any[];
  isSelected?: boolean;
  selectedRowFromOtherTables?: any;
  userInputsValues?: { [inputName: string]: any };
  isUserInput?: boolean;
  views: any;
  tableId: any;
  onSelectRow?: (object: any) => void;
  handleDragHover?: any;
  __data?: any;
  closeModal?: any;
  parentIds?: any;
  index?: any;
  order?: any;
  handleUpdateElement?: any;
}> = ({
  item,
  columns,
  actions,
  auth,
  onSelectRow,
  isSelected,
  selectedRowFromOtherTables,
  userInputsValues,
  isUserInput,
  views,
  tableId,
  __data,
  closeModal,
  parentIds,
  index,
  order,
  handleDragHover,
  handleUpdateElement,
}) => {
  const classes = useStyles();

  const [state, setState] = useState("normal");
  const isDisabled = () => state === "deleted";
  const isLoading = () => state === "loading";

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getCellValue = useCallback(
    (item: any, col: any) => {
      const { source, type } = col || {};
      const isDate = type === "Date";
      const isDateTime = type === "Date Time";
      if (!source) return "";

      if (
        !(source?.[0] === "{" && source?.[source?.length - 1] === "}") &&
        !(source?.startsWith("{#.") && source?.[source?.length - 1] === "}")
      )
        return source;

      let text = "";

      if (source?.startsWith("{#")) {
        const index = source.split("").findIndex((key: string) => key == ".");
        text = source.substring(index + 1, source.length - 1);
      } else {
        text = source.substring(1, source.length - 1);
      }

      const result = _.get(
        source?.startsWith("{##")
          ? reverseDataOfObjectValues(__data)[getHashesFromString(source)]
          : source?.startsWith("{#.")
          ? isUserInput
            ? userInputsValues
            : selectedRowFromOtherTables
          : item,
        text
      );

      return _.isObject(result)
        ? JSON.stringify(result)
        : isDate && result
        ? moment(result).format("YYYY/MM/DD")
        : isDateTime && result
        ? moment(result).format("YYYY/MM/DD HH:mm A")
        : result;
    },
    [isUserInput, selectedRowFromOtherTables, userInputsValues]
  );

  const CheckTooltip = useCallback(
    (props: any) => {
      const { children, col } = props;
      if (
        !(
          col?.type != "Boolean" &&
          !col?.allowWrap &&
          col?.type != "Image" &&
          col?.type != "Video" &&
          col?.type != "Audio" &&
          getCellValue(item, col)
        )
      )
        return <>{children}</>;
      return <Tooltip title={getCellValue(item, col)}>{children}</Tooltip>;
    },
    [getCellValue, item]
  );

  const ActiveComponent = order?.enabled ? DraggableRow : TableRow;
  const ActiveComponentProps = order?.enabled
    ? {
        componentProps: {
          onClick: (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            onSelectRow?.(item);
          },
          selected: isSelected,
          hover: true,
        },
      }
    : {
        onClick: (e: any) => {
          e.preventDefault();
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          onSelectRow?.(item);
        },
        selected: isSelected,
        hover: true,
      };

  return (
    <ActiveComponent
      component={TableRow}
      key={item?.id}
      id={item.id}
      index={index}
      name='table-columns'
      moveElement={handleDragHover}
      updateElement={handleUpdateElement}
      {...ActiveComponentProps}
    >
      {columns?.map((col, index) => (
        <React.Fragment key={col?.name + index}>
          <CheckTooltip col={col}>
            <TableCell
              align={col?.cellType === "numeric" ? "right" : "left"}
              width={col?.type == "Video" || col?.type == "Audio" ? 360 : undefined}
              sx={{
                position: "relative",
                ...(!col?.allowWrap && { textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }),
                maxWidth: 250,
                cursor: "pointer",
                minWidth: col?.type == "Video" || col?.type == "Audio" ? 300 : undefined,
                color: col?.type == "HyperLink" ? "#6060ff" : "inherit",
              }}
              className={classes.root}
              onClick={() => {
                if (col?.type == "HyperLink") {
                  window.open(getCellValue(item, col));
                }
              }}
            >
              {col?.type != "Boolean" &&
                col?.type != "Image" &&
                col?.type != "Video" &&
                col?.type != "Audio" &&
                col?.type != "HyperLink" &&
                !!getCellValue(item, col) && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "35%",
                      left: 0,
                    }}
                    onClick={() => {
                      enqueueSnackbarRef?.("Copied!", {
                        variant: "success",
                        anchorOrigin: {
                          horizontal: "right",
                          vertical: "bottom",
                        },
                      });
                      navigator.clipboard.writeText(getCellValue(item, col));
                    }}
                    className={classes.copyContainer}
                  >
                    <IconCopy />
                  </Box>
                )}
              <CellValue type={col?.type} value={getCellValue(item, col)} />
            </TableCell>
          </CheckTooltip>
        </React.Fragment>
      ))}
      {_.isArray(actions) && !_.isEmpty(actions.filter(action => action.isItem)) && (
        <TableCell>
          <DialogActions style={{ width: "100%", padding: 0 }}>
            <ItemActions
              auth={auth}
              item={item}
              onSelectRow={onSelectRow}
              disabled={isDisabled() || isLoading()}
              onStateChange={newState => setState(newState)}
              actions={actions?.filter?.(action => action.isItem)}
              selectedRowFromOtherTables={selectedRowFromOtherTables}
              userInputsValues={userInputsValues}
              isUserInput={isUserInput}
              views={views}
              tableId={tableId}
              __data={__data}
              closeModal={closeModal}
              parentIds={parentIds}
            />
          </DialogActions>
        </TableCell>
      )}
    </ActiveComponent>
  );
};
