import { CircularProgress, FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import axios from "axios";

import _ from "lodash";
import { FC, useEffect } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { BXEngine, KeysToComponentMap } from "src/BXEngine";
import { useBXContext, useValue } from "src/BXEngine/BXContext";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import { ElementBaseProps } from "src/types/UIElement";
import { getLastKeyFromObject } from "src/utils/generalUtils";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { BOX_HEIGHT, MUI_COMPONENTS } from "src/views/pages/BuildX/FormBuilder";
import { ActionButton, replaceItemPlaceholders } from "../DataTable/ActionButton";

type FormBuilderProps = {
  actions?: any[];
  auth: any;
  layout: string;
  info?: {
    name?: string;
    showApiMode?: string;
  };
  views: any;
  __data?: any;
} & ElementBaseProps;

const FormBuilder: FC<FormBuilderProps> = props => {
  const {
    id = "",
    dataSource,
    selectedViewId,
    info,
    actions,
    views,
    __data = {},
    closeModal,
    parentIds = [],
    showModalHeader,
    setIsDirty,
  } = props;

  const localViews = views?.filter((view: any) => view.id != id);

  const { selectedTableRows, currentApp } = useBXContext();
  const selectedRowFromOtherTables = selectedTableRows?.find(item => item?.viewId == dataSource?.table?.id || item?.id == selectedViewId);
  const { data: apiData, isLoading: isFetching } = useValue(
    id,
    dataSource?.limit,
    dataSource?.sourceType,
    selectedRowFromOtherTables,
    selectedRowFromOtherTables,
    {},
    dataSource?.sourceType == "USER INPUT",
    false,
    {
      onSuccess: (data: any) => {
        if (dataSource?.sourceType == "API") {
          reset(replaceFormData(dataSource?.formBuilder, data));
        }
      },
      enabled: dataSource?.sourceType == "API",
    },
    undefined,
    __data,
    undefined,
    undefined,
    undefined,
    [selectedViewId]
  );

  const replaceFormData = (data: any, apiData: any) => {
    const elements = data?.filter((item: any) => item?.config?.controlledComponent && item?.props?.key);

    const formData: any = {};
    elements?.forEach((element: any) => {
      const value = replaceItemPlaceholders(
        element?.props?.defaultValue,
        dataSource?.dataEntry ? _.get(apiData, dataSource?.dataEntry as any) : apiData,
        selectedRowFromOtherTables,
        undefined,
        undefined,
        undefined,
        __data,
        undefined,
        undefined,
        undefined,
        currentApp?.env
      );

      formData[element?.props?.key || ""] = value || (element?.type == "formTable" ? [] : element?.type === "keyValue" ? {} : "");
    });
    return formData;
  };

  let code = {};
  try {
    code = JSON.parse(dataSource?.template);
  } catch (e) {}

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isDirty },
    control,
    setValue,
    getValues,
    reset,
  } = useForm<FieldValues>({
    defaultValues: replaceFormData(dataSource?.formBuilder, apiData),
  });

  useCallbackPrompt(isDirty);

  // useEffect(() => {
  //   setIsDirty?.(isDirty);
  // }, [isDirty]);

  const {
    data: componentData,
    isFetching: componentDataFetching,
    isLoading,
  } = useQuery(
    [["components", id]],
    async ({ pageParam }) => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}/api/component?ids=${dataSource?.templateComponentIds?.join(",")}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      const ids: any = {};
      data?.items?.forEach((item: any) => {
        ids[item?.name] = item;
      });
      return ids;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!dataSource?.templateComponentIds?.length,
    }
  );

  useEffect(() => {
    if (!isValid && isSubmitting && !_.isEmpty(errors)) {
      enqueueSnackbarRef?.("Validation Error", {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
    }
  }, [!isValid && isSubmitting]);

  useEffect(() => {
    reset(replaceFormData(dataSource?.formBuilder, apiData));
  }, [isFetching, componentDataFetching, selectedRowFromOtherTables]);

  useEffect(() => {
    if (dataSource?.sourceType == "TABLE") {
      const getPathsOfSelectors = (data: any, path = "", values = {} as any) => {
        if (_.isObject(data) as any) {
          _.forOwn(data, (value, key) => {
            if (_.isArray(value)) {
              value?.forEach((item, index) => {
                if (_.isObject(item) as any) {
                  getPathsOfSelectors(data[key][index], `${path ? path + `[${key}][${index}]` : `${key}[${index}]`}`, values);
                }
              });
            } else if (_.isObject(value)) {
              getPathsOfSelectors(data[key], `${path ? path + `[${key}]` : key}`, values);
            } else {
              if (value?.[0] === "{" && value?.[value?.length - 1] === "}") {
                const newValue = value.substring(1, value.length - 1);
                const options = newValue.split(",");
                if (options[3]) {
                  const inputValue = options[3];
                  const index = inputValue.split("").findIndex((key: string) => key == ".");

                  const text = inputValue.substring(index + 1, inputValue.length - 1);
                  if (inputValue?.startsWith("{#")) {
                    values[text] = `${path}[${key}]`;
                  }
                }
              }
            }
          });
          return values;
        }
      };

      const selectorPaths = getPathsOfSelectors(code);
      _.forOwn(selectorPaths, (value: any, key: any) => {
        setValue(value, _.get(selectedRowFromOtherTables, key));
      });
    }
  }, [selectedRowFromOtherTables]);

  if (isLoading) return <CircularProgress />;

  return (
    <Box marginY={1} paddingX={1}>
      <Card>
        <Box sx={{ position: "relative", minHeight: BOX_HEIGHT }}>
          {!!dataSource?.formBuilder?.length &&
            dataSource?.formBuilder?.map((element: any, index: number) => {
              const Component = MUI_COMPONENTS[element?.type];
              const CustomComponent = KeysToComponentMap[element?.type];
              if (!Component && !CustomComponent) return <></>;

              if (CustomComponent) {
                const view = localViews.find((view: any) => view?.info?.name == element?.props?.name) || {};

                return (
                  <Box
                    key={element?.id + "-" + index}
                    sx={{
                      position: "absolute",
                      left: element?.leftPercentage,
                      top: element?.top,
                      width: element?.config?.widthPercentage,
                      height: element?.config?.heightPx,
                    }}
                  >
                    <BXEngine
                      auth={{}}
                      page={{ views, layout: view } as any}
                      control={control}
                      inputValidationName={element?.props?.key || ""}
                      getValues={getValues}
                      error={
                        _.get(errors, element?.props?.key || "")
                          ? { ..._.get(errors, element?.props?.key || ""), message: "The Field is required" }
                          : null
                      }
                      componentName={element?.props?.name}
                      componentData={element?.props}
                      layout={[{ id: element?.id, type: element?.type } as any]}
                      isVisible
                      __data={{
                        ...__data,
                        [(getLastKeyFromObject(__data) || "") + "#"]: _.get(apiData, dataSource?.dataEntry as any) || {},
                      }}
                      parentIds={[...parentIds, id]}
                      closeModal={closeModal}
                    />
                  </Box>
                );
              }

              const componentElement = element?.config?.controlledComponent ? (
                <Controller
                  control={control}
                  name={element?.props?.key || "" || "null"}
                  key={element?.id + "-" + index}
                  // rules={{
                  //   required,
                  // }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Box
                        sx={{
                          position: "absolute",
                          left: element?.leftPercentage,
                          top: element?.top,
                          width: element?.config?.widthPercentage,
                          height: element?.config?.heightPx,
                        }}
                      >
                        <Component
                          {...element?.props}
                          onChange={onChange}
                          value={value}
                          {...(element?.props?.label
                            ? {
                                label: replaceItemPlaceholders(
                                  element?.props?.label,
                                  dataSource?.dataEntry ? _.get(apiData, dataSource?.dataEntry as any) : apiData,
                                  selectedRowFromOtherTables,
                                  undefined,
                                  undefined,
                                  undefined,
                                  __data,
                                  undefined,
                                  undefined,
                                  undefined,
                                  currentApp?.env
                                ),
                              }
                            : null)}
                          {...(element?.props?.placeholder
                            ? {
                                placeholder: replaceItemPlaceholders(
                                  element?.props?.placeholder,
                                  dataSource?.dataEntry ? _.get(apiData, dataSource?.dataEntry as any) : apiData,
                                  selectedRowFromOtherTables,
                                  undefined,
                                  undefined,
                                  undefined,
                                  __data,
                                  undefined,
                                  undefined,
                                  undefined,
                                  currentApp?.env
                                ),
                              }
                            : null)}
                        />
                      </Box>
                      {error && <FormHelperText error>{error?.message}</FormHelperText>}
                    </>
                  )}
                />
              ) : (
                <Box
                  key={element?.id + "-" + index}
                  sx={{
                    position: "absolute",
                    left: element?.leftPercentage,
                    top: element?.top,
                    width: element?.config?.widthPercentage,
                    height: element?.config?.heightPx,
                  }}
                >
                  <Component
                    {...element?.props}
                    {...(element?.props?.children
                      ? {
                          children: replaceItemPlaceholders(
                            element?.props?.children,
                            dataSource?.dataEntry ? _.get(apiData, dataSource?.dataEntry as any) : apiData,
                            selectedRowFromOtherTables,
                            undefined,
                            undefined,
                            undefined,
                            __data,
                            undefined,
                            undefined,
                            undefined,
                            currentApp?.env
                          ),
                        }
                      : null)}
                  />
                </Box>
              );

              const error: any = false;

              if (element?.config?.isAction) {
                return (
                  <ActionButton
                    key={element?.id + "-" + index}
                    item={_.get(apiData, dataSource?.dataEntry as any) || {}}
                    handleSubmit={handleSubmit}
                    tableId={id}
                    action={element?.actionConfig}
                    selectedRowFromOtherTables={selectedRowFromOtherTables}
                    views={views}
                    withoutLabel={false}
                    iconButton={false}
                    formData={getValues}
                    __data={__data}
                    closeModal={closeModal}
                    parentIds={parentIds}
                    buttonComponent={componentElement}
                  />
                );
              } else {
                return componentElement;
              }
            })}
        </Box>

        {/* {!_.isEmpty(actions) && (
          <Grid container spacing={2} padding={1} mb={2} justifyContent='center' alignItems='center'>
            {actions?.map(action => (
              <Grid item key={action?.id}>
                <ActionButton
                  item={_.get(apiData, dataSource?.dataEntry as any) || {}}
                  handleSubmit={handleSubmit}
                  key={action.id}
                  tableId={id}
                  action={action}
                  selectedRowFromOtherTables={selectedRowFromOtherTables}
                  views={views}
                  withoutLabel={false}
                  iconButton={false}
                  formData={getValues}
                  __data={__data}
                  closeModal={closeModal}
                  parentIds={parentIds}
                />
              </Grid>
            ))}
          </Grid>
        )} */}
      </Card>
    </Box>
  );
};

export default FormBuilder;
