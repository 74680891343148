import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FC } from "react";
import { layoutBreakPoints } from "../../utils";

interface LayoutToggleButtonsProps {
  activeLayout: string;
  onLayoutChange: (layout: string | null) => void;
}

export const LayoutToggleButtons: FC<LayoutToggleButtonsProps> = ({ activeLayout, onLayoutChange }) => {
  const handleChangeLayout = (event: React.MouseEvent<HTMLElement>, value: string | null) => {
    value !== null && onLayoutChange?.(value);
  };
  return (
    <ToggleButtonGroup value={activeLayout} exclusive onChange={handleChangeLayout}>
      {layoutBreakPoints.map(breakPoint => (
        <ToggleButton key={breakPoint?.id} value={breakPoint?.id} aria-label='centered'>
          {breakPoint?.title}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
