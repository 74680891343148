import { FC } from "react";
import { useDnD } from "src/hooks/useDnD";

type Props = {
  id: any;
  index: any;
  name: string;
  moveElement: any;
  updateElement?: any;
  component?: any;
  componentProps?: any;
};

const DraggableRow: FC<Props> = props => {
  const { id, index, name, moveElement, updateElement, component, componentProps = {} } = props;
  const { ref, opacity, handlerId } = useDnD({
    id,
    index,
    name,
    moveCard: moveElement,
    updateCard: updateElement,
  });

  const Component = component || <div />;

  return (
    <Component ref={ref} style={{ opacity }} data-handler-id={handlerId} {...componentProps}>
      {props.children}
    </Component>
  );
};

export default DraggableRow;
