import { Box, Divider, Theme } from "@mui/material";
import { FC, useRef } from "react";
import { BOX_HEIGHT } from "../../FormBuilderEditor";

export const ContainerGrid: FC<{ canvasEnabled: boolean }> = ({ canvasEnabled }) => {
  const boxRef = useRef<any>();
  const boxPosition = boxRef.current?.getBoundingClientRect();
  const numberOfHorizontalBorders = BOX_HEIGHT / 10;
  const numberOfVerticalBorders = boxPosition?.width / 10;

  return (
    <Box
      ref={boxRef}
      sx={{
        position: "absolute",
        borderRadius: "10px",
        overflow: "hidden",
        top: 0,
        left: 0,
        background: "transparent",
        zIndex: -1,
        height: BOX_HEIGHT,
        width: "100%",
        backgroundColor: (theme: Theme) => theme.palette.background.paper,
      }}
    >
      {canvasEnabled && (
        <>
          <Box sx={{ position: "absolute", top: 0, left: 0, height: BOX_HEIGHT, width: "100%", paddingLeft: "-1px", paddingTop: "-1px" }}>
            {Array.from({ length: numberOfHorizontalBorders }, (_, i) => (
              <Divider key={i} sx={{ width: "100%", marginBottom: "9px", borderStyle: "dotted" }} />
            ))}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", position: "absolute", top: 0, left: 0, height: BOX_HEIGHT, width: "100%" }}>
            {Array.from({ length: numberOfVerticalBorders }, (_, i) => (
              <Divider key={i} orientation='vertical' sx={{ width: "10px", height: BOX_HEIGHT, borderStyle: "dotted" }} />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};
