// action - state management
import { initialLoginContextProps } from "src/types";
import { ACCOUNT_INITIALIZE, CLEAR_ERRORS, LOGIN, LOGIN_FAILURE, LOGOUT } from "./actions";

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState: initialLoginContextProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  error: null,
};

export interface AccountReducerActionProps {
  type: string;
  payload?: initialLoginContextProps;
}

const accountReducer = (state = initialState, action: AccountReducerActionProps) => {
  switch (action.type) {
    case ACCOUNT_INITIALIZE: {
      const { isLoggedIn, user } = action.payload!;
      return {
        ...state,
        isLoggedIn,
        isInitialized: true,
        user,
      };
    }
    case LOGIN: {
      const { user } = action.payload!;
      return {
        ...state,
        isLoggedIn: true,
        user,
      };
    }
    case LOGIN_FAILURE: {
      const { error } = action.payload!;
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        error,
      };
    }
    case CLEAR_ERRORS: {
      const { error } = action.payload!;
      return {
        ...state,
        error,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
