import { useEffect, useState } from "react";

// third-party
import { useSelector } from "react-redux";

// material-ui
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";

// project imports
import { DefaultRootStateProps } from "src/types";

// style constant
const useStyles = makeStyles((theme: any) => ({
  root: {
    position: "fixed",
    top: "100px",
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1401,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

// ==============================|| Circle Loader ||============================== //

const CircleLoader = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const loaderInitial = useSelector((state: DefaultRootStateProps) => state.loader);

  useEffect(() => {
    setOpen(loaderInitial.isLoading);
  }, [loaderInitial.isLoading]);

  return (
    <>
      {open ? (
        <div className={classes.root}>
          <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={open}>
            <CircularProgress color='primary' />
          </Backdrop>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

// const LoaderCircle = () => (
//     <Grid container justifyContent="center" alignItems="center" spacing={2}>
//         <Grid item>
//             <CircularProgress />
//         </Grid>
//     </Grid>
// );

export default CircleLoader;
