import { IconButton, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { IconApps, IconEditCircle, IconFileImport, IconPlus, IconTrashX, IconUsers } from "@tabler/icons";
import update from "immutability-helper";
import _, { debounce } from "lodash";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useBXContext } from "src/BXEngine/BXContext";
import { BXConfirmationDialog } from "src/components/BXUI/AlertDialog/ConfirmationDialog";
import BXModal from "src/components/BXUI/Modal";
import useAuth from "src/hooks/useAuth";
import { BXApp } from "src/types/BXAppType";
import axiosServices from "src/utils/axios";
import { v4 as uuid } from "uuid";
import { useBXBuilderContext } from "../../../../BXEngine/BXBuilderContext";
import BuilderColumn from "./components/BuilderColumn";
import CollectionColumn from "./components/CollectionColumn";
import PageColumn from "./components/PageColumn";
import ViewColumn from "./components/ViewColumn";
import { ConnectAppUsers } from "./forms/ConnectAppUsers";
import { CreateAppForm } from "./forms/CreateAppForm";

export const changeIdsOfObject = (data: any, oldIds: any = {}) => {
  if (_.isObject(data) as any) {
    if (data?.id && data?.id != -1) {
      if (oldIds[data?.id]) {
        data.id = oldIds[data?.id];
      } else {
        const generatedId = uuid();
        oldIds[data?.id] = generatedId;
        data.id = generatedId;
      }
    }
    _.forOwn(data, (value, key) => {
      if (_.isArray(value)) {
        value?.forEach((item, index) => {
          if (_.isObject(item) as any) {
            if (item?.actionSourceType?.id) {
            }
            changeIdsOfObject(data[key][index], oldIds);
          }
        });
      } else if (_.isObject(value)) {
        changeIdsOfObject(data[key], oldIds);
      }
    });
    return data;
  }
};

type AppBuilderProps = {
  children?: React.ReactNode;
};

let isUpdateApps = true;

export const AppBuilder: FC<AppBuilderProps> = ({ children }) => {
  const { apps, addApp, editApps, deleteApp, editApp } = useBXBuilderContext();
  const { currentApp, loadingApps, logoutOfApp, setAppDescriptor, setEnvs } = useBXContext();

  const { isSuperAdmin } = useAuth();

  const [localApps, setLocalApps] = useState(apps.sort((a, b) => a?.order! - b?.order!));
  const [description, setDescription] = useState<any>();
  const [isUser, setIsUser] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isTrash, setIsTrash] = useState(false);
  const [isConfig, setIsConfig] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const [selectedAppId, setSelectedAppId] = useState<any>();
  const [selectedCollectionId, setSelectedCollectionId] = useState<any>();
  const [selectedPageId, setSelectedPageId] = useState<any>();
  const [withAppInfo, setWithAppInfo] = useState<any>();
  const [withAuthConfig, setWithAuthConfig] = useState<any>();
  const { palette } = useTheme();

  const selectDefaultApp = (app?: BXApp) => {
    const defaultApp = app;
    const defaultCollection = defaultApp?.templateConfig?.collections?.[0];
    const defaultPage = defaultCollection?.pages?.[0];
    setSelectedAppId(defaultApp?.id);
    setSelectedCollectionId(defaultCollection?.id);
    setSelectedPageId(defaultPage?.id);
  };

  useEffect(() => {
    axiosServices.get("/admin/env").then(envs => {
      setEnvs(envs.data?.items);
    });
  }, []);

  useEffect(() => {
    if (!isUpdateApps) isUpdateApps = true;
    else setLocalApps(apps.sort((a, b) => a?.order! - b?.order!));

    if (!selectedAppId && !loadingApps) {
      selectDefaultApp(currentApp);
    }
  }, [currentApp, loadingApps, apps]);

  const moveCard = (dragIndex: any, hoverIndex: number) => {
    return setLocalApps(prev => {
      const apps = update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev[dragIndex]],
        ],
      });

      apps[hoverIndex] = { ...apps[hoverIndex], order: hoverIndex };
      apps[dragIndex] = { ...apps[dragIndex], order: dragIndex };
      return apps;
    });
  };

  const updateCardDebounce = useCallback(
    debounce(localApps => {
      isUpdateApps = false;
      editApps(localApps);
    }, 1000),
    []
  );

  const updateCard = () => {
    updateCardDebounce(localApps);
  };

  const handleConfigClick = (value: any) => {
    setIsConfig(true);
    setDescription(value);
    setWithAppInfo(false);
    setWithAuthConfig(true);
  };
  const handleEditClick = (value: any) => {
    setIsEdit(true);
    setDescription(value);
    setWithAppInfo(true);
    setWithAuthConfig(false);
  };
  const handleTrashClick = (value: any) => {
    setIsTrash(true);
    setDescription(value);
  };
  const handleUserClick = (value: any) => {
    setIsUser(true);
    setDescription(value);
  };

  const handleDuplicateClick = (value: any) => {
    axiosServices.get("/application/" + value?.id).then(({ data }) => {
      setAppDescriptor((prev: BXApp[]) => prev.map(app => (app.id == data?.id ? data : app)));
      const app = { ..._.cloneDeep(data), name: data.name + " copy", slug: data.slug + "-copy", title: data.title + " copy" };
      delete app.id;
      delete app.fqdn;

      const changedTemplateConfigIds = changeIdsOfObject(_.cloneDeep(app?.templateConfig));

      addApp({ ...app, templateConfig: changedTemplateConfigIds } as any);
    });
  };

  const handleFileSelect = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const reader = new FileReader();
      reader.onload = event => {
        const jsonString = event.target?.result?.toString();
        const parsedJson = JSON.parse(jsonString || "");
        addApp(parsedJson as any);
      };
      reader.readAsText(selectedFile);
    }
  };

  const handleSelectClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".json";
    fileInput.addEventListener("change", handleFileSelect);
    fileInput.click();
  };

  const handleExportClick = (item: any) => {
    axiosServices.get("/application/" + item?.id).then(({ data }) => {
      setAppDescriptor((prev: BXApp[]) => prev.map(app => (app.id == data?.id ? data : app)));

      const app = { ...data, name: data.name, slug: data.slug, title: data.title };
      delete app.id;
      delete app.fqdn;
      const changedTemplateConfigIds = changeIdsOfObject(_.cloneDeep(app?.templateConfig));
      app.templateConfig = changedTemplateConfigIds;

      const filename = app.name;
      const jsonStr = JSON.stringify(app, null, 2);
      const blob = new Blob([jsonStr], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    });
  };

  const handleAppSelect = (item: any) => {
    setSelectedAppId(item?.id);
    if (item?.templateConfig == null) {
      axiosServices.get("/application/" + item?.id).then(({ data }) => {
        setAppDescriptor((prev: BXApp[]) => prev.map(app => (app.id == data?.id ? data : app)));
      });
    }
  };

  const data = localApps.map((item: any) => ({
    ...item,
    title: item?.name,
    subTitle: item?.slug,
    image: item?.icon,
    fallBack: "Apps",
  }));

  const app = apps?.find(app => app?.id == selectedAppId);
  const collections = app?.templateConfig?.collections;
  const collection = collections?.find(collection => collection?.id == selectedCollectionId);
  const page = collection?.pages?.find(page => page?.id == selectedPageId);

  if (!isSuperAdmin()) return <></>;

  return (
    <>
      {description && (
        <>
          <BXConfirmationDialog
            open={!!isTrash}
            title={"Are you sure you want to delete this app?"}
            iconButton
            buttonProps={{ color: "error", children: <IconTrashX /> }}
            onConfirm={() => {
              deleteApp(description);
              setIsTrash(false);
            }}
            onCancel={() => {
              setIsTrash(false);
            }}
          />
          <BXModal
            open={!!isUser}
            label={"Users"}
            icon={<IconUsers />}
            buttonProps={{ variant: "text", color: "secondary", startIcon: <IconUsers /> }}
            title={"Manage connected users"}
            onClose={() => setIsUser(false)}
          >
            {(handleClose: Function) => {
              return (
                <ConnectAppUsers
                  app={description}
                  onSave={() => {}}
                  onCancel={() => {
                    setIsUser(false);
                    handleClose();
                  }}
                />
              );
            }}
          </BXModal>
          <BXModal
            open={!!isEdit || !!isConfig}
            label={"Edit"}
            icon={<IconEditCircle />}
            buttonProps={{ variant: "text", color: "secondary", startIcon: <IconEditCircle /> }}
            title={`Edit App : ${app?.name}`}
            onClose={() => {
              setIsEdit(false);
              setIsConfig(false);
            }}
            isDirty={isDirty}
          >
            {(handleClose: Function) => {
              return (
                <CreateAppForm
                  withAppInfo={withAppInfo}
                  withAuthConfig={withAuthConfig}
                  appId={selectedAppId}
                  app={description}
                  onSave={data => {
                    const { authApi, deviceApi } = app?.appConfig?.auth || {};
                    const { authApi: _authApi, deviceApi: _deviceApi } = data?.appConfig?.auth || {};
                    if (authApi != _authApi || app?.baseUrl != data?.baseUrl || deviceApi != _deviceApi) {
                      logoutOfApp?.(data?.id);
                    }

                    editApp(description.id, data as BXApp);
                    handleClose?.(true);
                  }}
                  onCancel={() => {
                    handleClose(false, () => {
                      setIsEdit(false);
                    });
                  }}
                  setIsDirty={setIsDirty}
                />
              );
            }}
          </BXModal>
        </>
      )}

      <Box marginX={1}>
        <Typography fontSize={"20px"} fontWeight={600} marginBottom={1} lineHeight={2} color='textPrimary'>
          Manage Apps
        </Typography>
        <Card style={{ height: "85.5vh", paddingBottom: 20, overflowX: "auto" }}>
          <Grid xs container height='100%' padding={3} spacing={2} minWidth='1005px'>
            <BuilderColumn
              headerName='Apps'
              rows={data}
              selectedData={app}
              moveApp={moveCard}
              updateApp={updateCard}
              onSelect={handleAppSelect}
              onConfigClick={handleConfigClick}
              onEditClick={handleEditClick}
              onTrashClick={handleTrashClick}
              onUserClick={handleUserClick}
              onDuplicateClick={handleDuplicateClick}
              onExportClick={handleExportClick}
              modal={
                <Box display='flex'>
                  <IconButton onClick={handleSelectClick} sx={{ mr: 1 }}>
                    <IconFileImport size={18} />
                  </IconButton>
                  <BXModal
                    icon={<IconApps />}
                    label={"App"}
                    buttonProps={{
                      variant: "contained",
                      style: { backgroundColor: palette.primary.main, borderRadius: 24 },
                      startIcon: <IconPlus />,
                      fullWidth: true,
                    }}
                    title={"New Application"}
                  >
                    {(handleClose?: Function) => {
                      return (
                        <CreateAppForm
                          withAuthConfig={false}
                          appId={selectedAppId}
                          onSave={data => {
                            const app: BXApp = {
                              id: uuid(),
                              slug: data.slug,
                              name: data.name,
                              icon: data.icon,
                              baseUrl: data.baseUrl,
                              templateConfig: {
                                collections: [],
                              },
                              appConfig: data?.appConfig,
                              authDisabled: data.authDisabled,
                            };

                            addApp(app);
                            handleClose?.();
                          }}
                          onCancel={() => {
                            handleClose?.();
                          }}
                        />
                      );
                    }}
                  </BXModal>
                </Box>
              }
            />
            <CollectionColumn
              app={app}
              collections={collections}
              selectedData={collection}
              onSelect={item => setSelectedCollectionId(item?.id)}
            />
            <PageColumn app={app} collection={collection} selectedData={page} onSelect={item => setSelectedPageId(item?.id)} />
            <ViewColumn app={app} collection={collection} page={page} />
          </Grid>

          {/* <PerfectScrollbar>
              {localApps.map((app, index) => (
                <AppAccordion index={index} key={app.id} description={app} moveApp={moveCard} updateApp={updateCard} />
              ))}
            </PerfectScrollbar> */}
        </Card>
      </Box>
    </>
  );
};
